<nav mat-tab-nav-bar color="primary">
  <div *ngFor="let tab of tabs">
    <a mat-tab-link
       *ngIf="tab.show"
       [routerLink]="tab.path"
       routerLinkActive
       #rla="routerLinkActive"
       [routerLinkActiveOptions]="{ exact: true }"
       [active]="rla.isActive"
       class="header-menu-item"
    >{{tab.label}} </a>
  </div>
</nav>

