import { FormContext } from '../../tpt-forms/base/form.context';
import { AbstractControl, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { AddTaskFormModel } from '../models/add-task-form.model';
import { ProjectDetailedTaskModel } from '../models/project-detailed-task.model';

export class AddTaskForm extends FormContext<AddTaskFormModel> {

  static createForm(task: ProjectDetailedTaskModel, isFixed: boolean): AddTaskForm {
    const form = new UntypedFormGroup({
      id: new UntypedFormControl(task.id),
      taskTitle: new UntypedFormControl(task.taskTitle, [Validators.required, Validators.maxLength(64)]),
      description: new UntypedFormControl(task.description, [Validators.maxLength(6000)]),
      fileIds: new UntypedFormControl(task.files || []),
      status: new UntypedFormControl(task.status),
      deadline: new UntypedFormControl(task.deadline),
      projectId: new UntypedFormControl(task.projectId),
      priority: new UntypedFormControl(task.priority || 'MEDIUM'),
      startDate: new UntypedFormControl(task.startDate),
      hasStartDate: new UntypedFormControl(!!task?.startDate),
    });

    const deadlineValidators = isFixed ? [Validators.required] : null;
    form.get('deadline').setValidators(deadlineValidators);

    return new AddTaskForm(form, task);
  }

  public get id(): AbstractControl {
    return this.getControl('id');
  }

  public get taskTitle(): AbstractControl {
    return this.getControl('taskTitle');
  }

  public get description(): AbstractControl {
    return this.getControl('description');
  }

  public get fileIds(): AbstractControl {
    return this.getControl('fileIds');
  }

  public get status(): AbstractControl {
    return this.getControl('status');
  }

  public get deadline(): AbstractControl {
    return this.getControl('deadline');
  }

  public get startDate(): AbstractControl {
    return this.getControl('startDate');
  }

  public get hasStartDate(): AbstractControl {
    return this.getControl('hasStartDate');
  }

  public get projectId(): AbstractControl {
    return this.getControl('projectId');
  }

  public get priority(): AbstractControl {
    return this.getControl('priority');
  }

  public get isEditForm(): boolean {
    return Boolean(this.data && this.data.id);
  }

  constructor(
    public form: UntypedFormGroup,
    public data: any,
  ) {
    super(form, data);
  }

  public getFormData(): AddTaskFormModel {
    return this.form.value;
  }

}
