
export enum ErrorCodeEnum {

  // TODO: TBD after some time talk about some validation on server side
  serverError = 'serverError',

  required = 'required',

  email = 'email',

  minLength = 'minlength',

  maxlength = 'maxlength',

  min = 'min',

  max = 'max',

  matDatepickerMin = 'matDatepickerMin',

  matDatepickerMax = 'matDatepickerMax',

  matDatepickerFilter = 'matDatepickerFilter',

  passwordConfirmation = 'passwordConfirmation',

  pattern = 'pattern',

  validatePattern = 'validatePattern',

  exactLengthValidation = 'exactLengthValidation',

  passwordLettersDigits = 'passwordLettersDigits',

  passwordValidator = 'passwordValidator',

  safePassword = 'safePassword',

  sameEmail = 'sameEmail',

  validatePhoneNumber = 'validatePhoneNumber',

  wrongInn = 'wrongInn',

  wrongBIK = 'wrongBIK',

  wrongAcc = 'wrongAcc',

  wrongCorrAcc = 'wrongCorrAcc',

  dateRangeInvalid = 'dateRangeInvalid',

}
