<tpt-dialog>
  <div class="content">
    <div class="fs-22 lh-24 fw-700 text-black mb-8">Вложенные файлы</div>
    <div class="mb-32 text-gray-600 fs-14 lh-20 fw-500">Здесь отображаются файлы, которые были переданны в сообщениях</div>
    <div *ngIf="files.length; else empty">
      <div class="files-container">
        <emp-attached-file-item
          *ngFor="let file of files"
          [file]="file"
        ></emp-attached-file-item>
      </div>

      <tc-button size="x-large" type="tertiary" class="full-width mt-32" (onClick)="close()">Закрыть</tc-button>
    </div>

    <ng-template #empty>
      <div class="no-files-wrapper">
        <img alt="no-files" src="assets/images/empty-executors.png"/>
        <div class="fw-600 text-black mb-8 mt-32">Здесь пока ничего нет</div>
        <div class="text-gray-600 fs-14 fw-500 mb-48">Все отправленные в чате файлы будут ображаться здесь</div>
        <tc-button size="x-large" type="tertiary" class="full-width mt-a" (onClick)="close()">Закрыть</tc-button>
      </div>
    </ng-template>
  </div>
</tpt-dialog>
