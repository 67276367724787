import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { TranslateModule } from '@ngx-translate/core';
import { RouterModule } from '@angular/router';
import { MatCardModule } from '@angular/material/card';

import { JobCardProgressComponent } from './job-card-progress/job-card-progress.component';
import { JobCardDaysRemainingComponent } from './job-card-days-remaining/job-card-days-remaining.component';
import { OwnMessageComponent } from './task-comments-messages/own-message/own-message.component';
import { CompanionMessageComponent } from './task-comments-messages/companion-message/companion-message.component';
import { TptUiModule } from '../tpt-ui/tpt-ui.module';
import { ProjectNoteComponent } from './project-note/project-note.component';
import { CommonDialogsModule } from '../common-dialogs/common-dialogs.module';
import { CommonPipesModule } from '../common-pipes/common-pipes.module';
import { TptFormsModule } from '../tpt-forms/tpt-forms.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AttachedFilesDialogComponent } from './attached-files-dialog/attached-files-dialog.component';
import { AttachedFileItemComponent } from './attached-file-item/attached-file-item.component';
import { ProjectDetailsCardComponent } from './project-details-card/project-details-card.component';
import { AvatarModule } from 'ngx-avatars';
import { AssigneePreviewComponent } from './assignee-preview/assignee-preview.component';
import { DeadlineStatusComponent } from './deadline-status/deadline-status.component';
import { TaskStateComponent } from './task-state/task-state.component';
import { HeaderLightComponent } from './header-light/header-light.component';
import { FooterLightComponent } from './footer-light/footer-light.component';
import { ReviewDaysLeftComponent } from './review-days-left/review-days-left.component';
import { ProjectInfoComponent } from './project-info/project-info.component';
import { FeedbackCardComponent } from './feedback-card/feedback-card.component';
import { AuthPagesFooterComponent } from './auth-pages-footer/auth-pages-footer.component';
import { MobileSidebarComponent } from './mobile-sidebar/mobile-sidebar.component';
import { MatBadgeModule } from '@angular/material/badge';
import {
  ChatOwnMessageComponent
} from '@/modules/common-components/messages/chat-own-message/chat-own-message.component';
import {
  ChatCompanionMessageComponent
} from '@/modules/common-components/messages/chat-companion-message/chat-companion-message.component';
import {
  ContractsNotSelectedBlockComponent
} from '@/modules/common-components/contracts-not-selected-block/contracts-not-selected-block.component';
import { PasswordStrengthComponent } from './password-strength/password-strength.component';
import { HeaderMenuComponent } from './header-menu/header-menu.component';
import { MatRippleModule } from '@angular/material/core';
import { NotificationComponent } from '@/modules/common-components/notification/notification.component';
import { NotificationContentComponent } from '@/modules/common-components/notification/notification-content.component';
import { BindingStepperComponent } from '@/modules/common-components/binding-stepper/binding-stepper.component';
import { MatRadioModule } from '@angular/material/radio';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { SelfEmployedStatusComponent } from '@/modules/common-components/self-employed-status/self-employed-status.component';
import { MobileMenuButtonComponent } from './mobile-menu-button/mobile-menu-button.component';
import { StatusBadgeComponent } from './status-badge/status-badge.component';
import { JobInfoCardComponent } from './job-info-card/job-info-card.component';
import { JobTasksListComponent } from './job-tasks-list/job-tasks-list.component';
import { JobsExtendedSidebarComponent } from './jobs-extended-sidebar/jobs-extended-sidebar.component';
import { NgxMatIntlTelInputComponent } from 'ngx-mat-intl-tel-input';
import { PasswordStrengthTooltipComponent } from './password-strength-tooltip/password-strength-tooltip.component';

@NgModule({
  imports: [
    CommonModule,
    MatProgressBarModule,
    MatIconModule,
    TranslateModule,
    TptUiModule,
    CommonDialogsModule,
    CommonPipesModule,
    TptFormsModule,
    ReactiveFormsModule,
    RouterModule,
    AvatarModule,
    MatDividerModule,
    MatSlideToggleModule,
    FormsModule,
    MatCardModule,
    MatBadgeModule,
    MatRippleModule,
    MatRadioModule,
    NgxSkeletonLoaderModule,
    NgxMatIntlTelInputComponent,
  ],
  declarations: [
    JobCardProgressComponent,
    JobCardDaysRemainingComponent,
    OwnMessageComponent,
    CompanionMessageComponent,
    ProjectNoteComponent,
    AttachedFilesDialogComponent,
    AttachedFileItemComponent,
    ProjectDetailsCardComponent,
    AssigneePreviewComponent,
    DeadlineStatusComponent,
    TaskStateComponent,
    HeaderLightComponent,
    FooterLightComponent,
    ReviewDaysLeftComponent,
    ProjectInfoComponent,
    FeedbackCardComponent,
    AuthPagesFooterComponent,
    MobileSidebarComponent,
    ChatOwnMessageComponent,
    ChatCompanionMessageComponent,
    ContractsNotSelectedBlockComponent,
    PasswordStrengthComponent,
    HeaderMenuComponent,
    NotificationComponent,
    NotificationContentComponent,
    BindingStepperComponent,
    SelfEmployedStatusComponent,
    MobileMenuButtonComponent,
    StatusBadgeComponent,
    JobInfoCardComponent,
    JobTasksListComponent,
    JobsExtendedSidebarComponent,
    PasswordStrengthTooltipComponent,
  ],
  exports: [
    JobCardProgressComponent,
    JobCardDaysRemainingComponent,
    OwnMessageComponent,
    CompanionMessageComponent,
    ProjectNoteComponent,
    AttachedFilesDialogComponent,
    ProjectDetailsCardComponent,
    AssigneePreviewComponent,
    DeadlineStatusComponent,
    TaskStateComponent,
    HeaderLightComponent,
    FooterLightComponent,
    ReviewDaysLeftComponent,
    ProjectInfoComponent,
    FeedbackCardComponent,
    AuthPagesFooterComponent,
    MobileSidebarComponent,
    ChatOwnMessageComponent,
    ChatCompanionMessageComponent,
    ContractsNotSelectedBlockComponent,
    PasswordStrengthComponent,
    HeaderMenuComponent,
    NotificationComponent,
    NotificationContentComponent,
    BindingStepperComponent,
    SelfEmployedStatusComponent,
    MobileMenuButtonComponent,
    JobInfoCardComponent,
    JobTasksListComponent,
    JobsExtendedSidebarComponent,
    PasswordStrengthTooltipComponent,
  ],
})
export class CommonComponentsModule {
}
