import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'tpt-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss']
})
export class ButtonComponent {
  @Input() size = 'small';

  @Input() type: 'secondary' | 'accent' | 'primary' | 'info' | 'warn' | 'info-warn' | 'ghost';

  @Input() disabled: boolean;

  @Output() onClick = new EventEmitter<any>();

  onClickButton(event) {
    this.onClick.emit(event);
  }

}
