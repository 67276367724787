<div class="wrapper">
  <div class="d-flex gg-12 search-row">
    <tc-input [control]="searchControl"
              (keyup.enter)="searchUsers()"
              [icon]="svgIconsEnum.Search"
    >
      <ng-container label>{{'SEARCH.USER_SEARCH_PLACEHOLDER' | translate}}</ng-container>
    </tc-input>
    <tc-button type="secondary" class="mobile" size="x-large" (onClick)="openFiltersDialog()">
      <mat-icon [svgIcon]="svgIconsEnum.FilterList"></mat-icon>
    </tc-button>
  </div>

  <div class="fs-14 fw-500 text-gray-600 mb-16 count-label" *ngIf="!showSkeleton">
    {{'SEARCH.FREELANCER_PLURAL' | translate:{count: totalElements} }}</div>

  <ng-container *ngIf="!showSkeleton; else freelancersLoader">
    <ng-container *ngIf="freelancers.length; else notFound">
      <div *ngFor="let freelancer of freelancers; let i = index" class="mb-16">
        <tpt-freelancer-search-card [freelancer]="freelancer"
                                    [skillsFilter]="skillsFilter">
        </tpt-freelancer-search-card>
      </div>
    </ng-container>

    <ng-template #notFound>
      <div class="not-found">
        <img src="assets/images/empty-list.png"/>
        <div class="text-black fw-600 mb-8">Ничего не найдено</div>
        <div class="fs-14 fw-500 text-gray-600 mb-28">Пожалуйста, попробуйте искать по другим ключевым словам или изменить настройки фильтров</div>
      </div>
    </ng-template>
  </ng-container>


  <ng-template #freelancersLoader>
    <ngx-skeleton-loader
      count="5"
      animation="progress"
      [theme]="{
          'border-radius': '4px',
          height: '264px',
          background: '#eff1f6',
          'box-shadow': '0 4px 4px rgba(0, 0, 0, 0.1)'
        }"
    ></ngx-skeleton-loader>
  </ng-template>
</div>

