<div class="search-page-root">
  <aside cdkScrollable class="aside-container">
    <tc-search-filters [filter]="filter" [type]="'PROJECT'"></tc-search-filters>
  </aside>
  <div class="search-page-layout" (scroll)="onScroll($event)">
    <div #top></div>
    <tpt-project-card (emitSortChange)="updateSortOrder($event)"
                      (searchEvent)="updateProjects($event)"
                      (openFilterDialog)="filterSearchDialog.open(filter, 'PROJECT')"
                      [showSkeleton]="showSkeleton"
                      [projects]="projects"
                      [totalElements]="totalElements"
                      [skillsFilter]="filter.get('skills')"></tpt-project-card>
    <div class="searching-wrap" *ngIf="loading">
      <mat-icon [svgIcon]="svgIconsEnum.HeaderLogo"></mat-icon>
      <div class="searching">{{'SEARCH.LOADING_MORE' | translate}}</div>
    </div>
  </div>
</div>
<tc-filter-search-dialog></tc-filter-search-dialog>
