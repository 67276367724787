import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormControl, Validators } from '@angular/forms';

import { AlertService } from '@/services/alert.service';
import { ProfileManagementService } from '@/services/profile-management.service';
import { TimezoneService } from '@/services/timezone.service';
import { SnackbarNotificationsService } from '@/services/snackbar-notifications.service';
import { ProfileService } from '@/services/profile.service';
import { SvgIconsEnum } from '@/types/svg-icons.enum';
import { RegistrationForm } from '@/forms/registration.form';
import { isMobileDevice } from '@/helpers/get-mobile.helper';
import { Role } from '@/classes/enums';
import { SpinnerService } from '@/modules/tpt-ui/services/spinner.service';
import {
  SignupErrorDialogComponent
} from '@/modules/common-dialogs/components/signup-error-dialog/signup-error-dialog.component';
import { Constants } from '@/classes/constants';
import { Subject } from 'rxjs';

const TOKEN_KEY = 'auth-token';

@Component({
  selector: 'tpt-register',
  templateUrl: 'register.component.html',
  styleUrls: ['./register.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RegisterComponent implements OnInit, OnDestroy {

  @ViewChild(SignupErrorDialogComponent) signupErrorDialogComponent: SignupErrorDialogComponent;
  public registerForm: RegistrationForm;
  public Role = Role;

  public registerSuccess = false;
  public svgIconsEnum = SvgIconsEnum;
  public isMobile = isMobileDevice();
  public minPasswordLength = Constants.PASSWORD_MIN_LENGTH;

  public roleControl = new FormControl(null, Validators.required);
  public showTooltip = false;
  public showPass = false;
  public inviteCode = '';
  private readonly destroy$ = new Subject<void>();

  constructor(
    private router: Router,
    private profileManagementService: ProfileManagementService,
    private profileService: ProfileService,
    private cd: ChangeDetectorRef,
    private alertService: AlertService,
    private timezoneService: TimezoneService,
    private snack: SnackbarNotificationsService,
    private spinner: SpinnerService,
    public activatedRoute: ActivatedRoute,
  ) {
    this.inviteCode = activatedRoute.snapshot.queryParams?.inviteCode;
  }

  public ngOnInit(): void {
    this.registerForm = RegistrationForm.createForm();
    if (this.inviteCode) {
      this.registerForm.role.setValue('FREELANCER');
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public register(): void {
    this.registerForm.validate();
    if (this.registerForm.invalid) {
      return;
    }
    const code = this.activatedRoute.snapshot.queryParams?.inviteCode;

    const formData = this.registerForm.getFormData();
    formData.email = formData.email?.trim()?.toLowerCase();
    formData.password = formData.password?.trim();

    const profile = {
      ...formData,
      timeZone: this.timezoneService.currentZone,
      interfaceLanguage: 'ru',
      inviteCode: formData.role === 'FREELANCER' && code ? code : null,
    };

    this.spinner.startSpinner();

    this.profileManagementService.createProfile(profile, 'TALENT_CLOUD')
      .then((data) => {
        this.spinner.stopSpinner();
        this.registerSuccess = true;

        if (formData.role === 'FREELANCER' && code) {
          const user = formData.email;
          localStorage.setItem('invitedUser', user);
        }

        this.cd.markForCheck();
      }).catch((error) => {
      if (error.errorCode.message === 'SERVER_ERRORS.PROFILE_WITH_THE_EMAIL_ALREADY_EXISTS') {
        this.signupErrorDialogComponent.open();
      } else if (error.errorCode.message === 'SERVER_ERRORS.INVALID_EMAIL') {
        this.alertService.error(error.errorCode.message);
      } else {
        this.alertService.error('SERVER_ERRORS.SIGNUP_ERROR');
      }
      this.spinner.stopSpinner();
    });
  }

  public buttonDisabled(): boolean {
    return !this.registerForm.privacyPolicy.value || !this.registerForm.userAgreement.value;
  }
}
