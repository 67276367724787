
export enum SvgIconsEnum {

  SidebarHome = 'sidebar-home',

  SidebarFinances = 'sidebar-finances',

  SidebarPayments = 'sidebar-payments',

  SidebarMessages = 'sidebar-messages',

  SidebarTeam = 'sidebar-team',

  SidebarSearch = 'sidebar-search',

  SidebarSettings = 'sidebar-settings',

  SidebarFns = 'sidebar-fns',

  SidebarProjects = 'sidebar-projects',

  SidebarExit = 'sidebar-exit',

  Clock = 'clock',

  Trash = 'trash',

  Trash2 = 'trash2',

  CircleCheckmark = 'circle-checkmark',

  Info = 'info',

  Cross = 'cross',

  Attachment = 'attachment',

  Comment = 'comments',

  AssigneePlaceholder = 'assignee-placeholder',

  AttachedFile = 'attached-file',

  ArrowLeft = 'arrow-left',

  DatepickerCalendar = 'datepicker-calendar',

  DialogFailureIcon = 'dialog-failure-icon',

  Location = 'location',

  LocationOutline = 'location-outline',

  Check = 'check-icon',

  Notification = 'notification',

  Language = 'language',

  ArrowDown = 'arrow-down',

  ArrowRight = 'arrow-right',

  SidebarArrow = 'sidebar-arrow',

  SuccessIcon = 'success-icon',

  Budget = 'budget',

  Candidate = 'candidate',

  TaskList = 'task-list',

  List = 'list',

  ArrowBack = 'arrow-back',

  Edit = 'edit',

  Delete = 'delete-outline',

  InfoIcon = 'info-icon',

  InfoCircle = 'info-circle',

  Plus = 'plus',

  PlusFilter = 'plus-filter',

  Minus = 'minus',

  PlusIcon = 'plus-icon',

  Details = 'details',

  ExpandIcon = 'expand-icon',

  Search = 'search',

  HeaderLogo = 'green-badge',

  FooterLogo = 'green-logo',

  ArrowForward = 'arrow-forward',

  Linkedin = 'in',

  Medium = 'medium',

  Telegram = 'telegram',

  Message = 'message',

  Close = 'close',

  PageUp = 'page-up',

  ConfirmSuccess = 'confirm-success',

  Mail = 'mail',

  HighPrio = 'high-prio',

  MediumPrio = 'medium-prio',

  LowPrio = 'low-prio',

  NoPrio = 'no-prio',

  ExpandMore = 'expand-more',

  Stripe = 'stripe',

  Payoneer = 'payoneer',

  Fee = 'fee',

  Arbitrage = 'arbitrage',

  SmallArrowDown = 'small-arrow-down',

  Camera = 'camera',

  FilterList = 'filter-list',

  Mustache = 'mustache',

  Bubble = 'bubble',

  Cancel = 'cancel',

  Zzz = 'zzz',

  Ruble  = 'ruble',

  BubbleMessage = 'bubble-main',

  CreditCard = 'credit-card',

  Flag = 'flag',

  Keyboard = 'keyboard',

  HelpOutline = 'help_outline',

  Bell = 'notifications_none',

  ExpandLess = 'expand_less',

  Individual = 'individual',

  IndividualEntrepreneur = 'individual-entrepreneur',

  LegalEntity = 'legal-entity',

  Hourglass = 'hourglass',

  Download = 'download',

  DownloadIcon = 'download-icon',

  MoreHoriz = 'more-horiz',

  MoreVert = 'more-vert',

  Link = 'link',

  Sticky = 'sticky',

  Archived = 'archived',

  PurpleCheck = 'purpleCheck',

  DocUpload = 'doc-upload',

  Archive = 'archive-icon',

  Doc = 'doc',

  Bank = 'bank',

  Refresh = 'refresh',

  CheckSmall = 'check',

  WarningSmall = 'warning-small',

  LegalInfo = 'legal-info',

  Contact = 'contact',

  CheckSuccess = 'check-success',

  InfoWarn = 'info-warn',

  CopyLink = 'copy-link',

  StopWatch = 'stopwatch',

  Email = 'email',

  Phone = 'phone',

  Skype = 'skype',

  Web = 'web',

  Dollar = 'dollar',

  ThinCheck = 'thin-check',

  Staple = 'staple',

  UploadSuccess = 'upload-success',

  Replay = 'replay',

  EyeOpen = 'eye-open',

  EyeClose = 'eye-close',

  Deregistered = 'deregistered',

  SupportAvatar = 'support-avatar',

  Processing = 'processing',

  XMark = 'xmark',

  Time = 'time',

  Loader = 'loader',

  Clipboard = 'clipboard',

  CheckCircle = 'check-circle',

  Sbp = 'sbp',

  WarnIcon = 'warn-icon',

  ErrIcon = 'err-icon',

  ErrorState = 'wavy-warning',

  WarningState = 'warning-state',

  SuccessState = 'success-state',

  CheckThin = 'check-thin',

  ArrowRefresh = 'arrow-refresh',

  Media = 'media',

  BurgerMenu = 'burger-menu',

  Grid = 'grid',

  CoInfo = 'co-info',

  SidebarContracts = 'sidebar-contracts',

  Settings = 'settings',

  User = 'user',

  SwitchService = 'switch-service',

  Support = 'support',

  Upload = 'upload',

  Gender = 'gender',

  Expand = 'expand',

  Chain = 'chain',

  Digits = 'digits',

  World = 'world',

  ActiveStatus = 'active-status',

  DetachedStatus = 'detached-status',

  RestrictedStatus = 'restricted-status',

  RemovedFromRegisterStatus = 'unregistered-status',

  File = 'file',

  CategorySmall = 'category-small',

  CategoryBig = 'category-big',

  SubCategorySmall = 'subcategory-small',

  SubCategoryBig = 'subcategory-big',

  FileError = 'file-error',

  Save = 'save',

  PriceTag = 'price',

  Expert = 'expert',

  EmptyList = 'empty-list',

  UserIcon = 'user-icon',

  UserIconGray = 'user-icon-gray',

  Talent = 'user-avatar',

  PaperPlane = 'paper-plane',

  Chat = 'chat',

  Tree = 'tree',

  ArrowUp = 'arrow-up',

  Attention = 'attention',

  FilledFile = 'filled-file',

  FilledPlanet = 'filled-planet',

  DeleteFile = 'delete-file',

  WavyCheck = 'wavy-check',

  EditIcon = 'edit-icon',

  Active = 'active',

  DeregisteredBadge = 'deregistered-badge',

  Detached = 'detached',

  IE = 'ie',

  LE = 'le',

  AlertTriangle = 'alert-triangle',

  FileExport = 'file-export',

  SolidCross = 'solid-cross',

  SolidCheck = 'solid-check',

  Pin = 'pin',

  Folders = 'folders',

  Send = 'send',

  ActiveProject = 'active-project',

  Draft = 'draft',

  Ended = 'ended',

  Envelope = 'envelope',

  EnvelopeOutline = 'envelope-outline',

  TalentCloud = 'talent-cloud',

  TalentRole = 'talent',

  ClientRole = 'client',

}

export enum CoSvgIconsEnum {

  Image = 'image',

  Books = 'books',

  Quotes = 'quotes',

  Headphones = 'headphones',

  Photo = 'photo',

  Planet = 'planet',

  Puzzle = 'puzzle',

  Crop = 'crop',

  Text = 'text',

  Comics = 'comics',

  Code = 'code',

  Play = 'play',

  Seller = 'seller',

  Buyer = 'buyer',

  SmallLogo = 'co-small-logo',

  FullLogo = 'co-full-logo',

  Folder = 'folder',

  Study = 'study',

  PO = 'po',

  Design = 'brush',

  Book = 'book',

  Audio = 'audio',

  Music = 'music',

  Buy = 'buy',

  CoSwitcher = 'switcher-co-logo',

  TcSwitcher = 'switcher-tc-logo',

  Cart = 'cart',

  TopUp = 'top-up',

  PriceTag = 'price-tag',

  Withdraw = 'withdraw',

  Exit = 'exit',

  SidebarHome = 'co-home',

  SidebarProducts = 'box',

  SidebarMessages = 'co-messages',

  SidebarWallet = 'wallet',

  SidebarSettings = 'co-settings',

}
