import { ENTER } from '@angular/cdk/keycodes';
import {
  AfterContentInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  Input,
  OnChanges, OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { ControlValueAccessor, UntypedFormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatAutocompleteSelectedEvent, MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import { compareById } from '../../../../utils';
import { MatFormFieldAppearance } from '@angular/material/form-field';
import { debounceTime, startWith, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { SvgIconsEnum } from '@/types/svg-icons.enum';

@Component({
    selector: 'xln-chips-autocomplete',
    templateUrl: './chips-autocomplete.component.html',
    styleUrls: ['./chips-autocomplete.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => ChipsAutocompleteComponent),
            multi: true,
        },
    ],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChipsAutocompleteComponent implements OnInit, AfterContentInit, OnChanges, ControlValueAccessor, OnDestroy {
    public separatorKeysCodes: number[] = [ENTER];
    public itemCtrl = new UntypedFormControl();

    @Input() public allItems: any[] = [];
    @Input() public title = '';
    @Input() public viewField = '';
    @Input() public cssChipsClass = '';
    @Input() public appearance: MatFormFieldAppearance = 'legacy';
    @Input() public chipsBelow = false;
    @Input() public blue = false;
    @Input() public customSkills: string[];

    public svgIconsEnum = SvgIconsEnum;

    public selectedItems: any[] = [];
    public filteredItems: any[] = [];
    public showTip = false;
    @ViewChild('itemInput', { static: true }) public itemInput: ElementRef;
    @ViewChild(MatAutocompleteTrigger, { static: true }) autocomplete: MatAutocompleteTrigger;

    @Output() listUpdated: EventEmitter<any> = new EventEmitter<any>();
    @Output() searchSkills: EventEmitter<any> = new EventEmitter<any>();
    // @Input()
    // public addCustomItemHigher: (prefix: string) => any | Promise<any>;

    private prevValue = null;

    private readonly destroy$ = new Subject<void>();

    constructor() {}

    @Input() public addCustomItem: (value: string, selectedItems: any[], allItems: any[]) => any | Promise<any> = () =>
        null;

    public ngOnInit(): void {
        this.itemCtrl.valueChanges
          .pipe(startWith(null as string), debounceTime(300), takeUntil(this.destroy$))
          .subscribe((value) => {
            this.searchSkills.emit(value);
            this.showTip = value?.length > 0;
        });
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes.allItems) {
            this.filter(this.prevValue);
        }
    }

    ngAfterContentInit(): void {
      if (this.customSkills) {
        this.customSkills.forEach((item) => {
          this.handleItem(item, this.itemInput.nativeElement, false);
        });
      }
    }

    ngOnDestroy() {
      this.destroy$.next();
      this.destroy$.complete();
    }

  public addItem(event: MatChipInputEvent) {
        const input = event.input;
        let item: any;

        if (this.filteredItems.length === 1 && this.filteredItems[0][this.viewField] === input.value) {
            this.handleItem(this.filteredItems[0], input);
        } else {

          this.handleItem(input.value, input, false);
        }
    }

    public handleItem(item: any, input: HTMLInputElement, add: boolean = false) {
      if (Array.isArray(item)) {
        this.filteredItems = item;

        if (item.length) {
          this.allItems.push(item);
        }
        return;
      }
      if (item) {
        if (!this.selectedItems.includes(item)) {
          this.selectedItems.push(item);
        }
        if (input) {
          input.value = '';
        }
        this.itemCtrl.setValue(null);
        this.propagateChange(this.selectedItems);
        this.listUpdated.emit();
        if (add) {
          this.allItems.push(item);
        }
      }

      this.autocomplete.closePanel();
    }

    public removeItem(itemToRemove: any, index: number): void {
      this.selectedItems.splice(index, 1);
      this.propagateChange(this.selectedItems);
      this.listUpdated.emit();
      this.itemCtrl.setValue(this.itemCtrl.value);
    }

    public selected(event: MatAutocompleteSelectedEvent) {
        this.selectedItems.push(event.option.value);
        this.filter(event.option.value);
        this.propagateChange(this.selectedItems);
        this.itemInput.nativeElement.value = '';
        this.itemInput.nativeElement.blur();
        this.itemCtrl.setValue(null);
        this.listUpdated.emit();
        this.autocomplete.closePanel();
    }

    public writeValue(obj: any): void {
        if (obj != null) {
            this.selectedItems = obj;
            this.filter(this.prevValue);
        }
    }

    public registerOnChange(fn: any): void {
        this.propagateChange = fn;
    }

    /* tslint:disable:no-empty*/
    public registerOnTouched(fn: any): void {}

    private propagateChange = (_: any) => {};

    private filter(value) {
        this.prevValue = value;
        this.filteredItems =
            value !== null && typeof value === 'string'
                ? this.allItems.filter((item) => item[this.viewField].toLowerCase().indexOf(value.toLowerCase()) >= 0)
                : this.allItems;
        if (this.selectedItems) {
            this.filteredItems = this.filteredItems.filter(
                (item) => !this.selectedItems.find((i) => compareById(i, item)),
            );
        }
    }
}
