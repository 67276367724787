import { CommonModule, DecimalPipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatCardModule } from '@angular/material/card';
import { MatChipsModule } from '@angular/material/chips';
import { MatDividerModule } from '@angular/material/divider';
import { CommonShareModule } from '../common-share.module';
import { FreelancerSearchPageComponent } from './components/freelancer-search/freelancer-search-page/freelancer-search-page.component';
import { SearchRoutingModule } from './search-routing.module';
import { TptUiModule } from '../tpt-ui/tpt-ui.module';
import { NouisliderModule } from 'ng2-nouislider';
import { ChipsAutocompleteModule } from '../chips-autocomplete/chips-autocomplete.module';
import { SearchHeaderComponent } from './components/parts/search-header/search-header.component';
import { ProjectCardComponent } from './components/project-search/project-card/project-card.component';
import { CommonComponentsModule } from '../common-components/common-components.module';
import { ProjectSearchComponent } from './components/project-search/project-search.component';
import { FreelancerSearchComponent } from './components/freelancer-search/freelancer-search.component';
import { SearchComponent } from './search.component';
import { HireFreelancerDialogComponent } from './components/dialogs/hire-freelancer-dialog/hire-freelancer-dialog.component';
import { CommonDialogsModule } from '../common-dialogs/common-dialogs.module';
import { AvatarModule } from 'ngx-avatars';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { TptFormsModule } from '../tpt-forms/tpt-forms.module';
import { NgxMaskModule } from 'ngx-mask';
import { FreelancerSearchCardComponent } from './components/parts/freelancer-search-card/freelancer-search-card.component';
import { MatTabsModule } from '@angular/material/tabs';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { TalentFiltersComponent } from './components/parts/talent-filters/talent-filters.component';
import {
  FilterSearchDialogComponent
} from '@/modules/search/components/dialogs/filter-search-dialog/filter-search-dialog.component';

@NgModule({
  declarations: [
    FreelancerSearchPageComponent,
    ProjectSearchComponent,
    FreelancerSearchComponent,
    SearchHeaderComponent,
    SearchComponent,
    ProjectCardComponent,
    HireFreelancerDialogComponent,
    FreelancerSearchCardComponent,
    TalentFiltersComponent,
    FilterSearchDialogComponent,
  ],
  imports: [
    CommonModule,
    CommonShareModule,
    SearchRoutingModule,
    TptUiModule,
    MatChipsModule,
    MatCardModule,
    ChipsAutocompleteModule,
    NouisliderModule,
    CommonComponentsModule,
    MatDividerModule,
    CommonDialogsModule,
    AvatarModule,
    ScrollingModule,
    TptFormsModule,
    NgxMaskModule.forRoot(),
    MatTabsModule,
    NgxSkeletonLoaderModule,
  ],
  providers: [
    DecimalPipe
  ],
  exports: [
    HireFreelancerDialogComponent,
    TalentFiltersComponent,
  ]
})
export class SearchModule {
}
