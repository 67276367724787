<div class="page-wrapper">
  <div class="register-root">
    <div>
      <div class="register">
        <div class="register-column">
          <mat-icon [svgIcon]="svgIconsEnum.HeaderLogo" class="logo-full"></mat-icon>

          <h2 class="mb-8 text-black lh-32 fs-24 fw-600">{{ 'REGISTER_COMPONENT.REGISTER' | translate }}</h2>
          <div
            class="fs-14 text-black lh-20 mb-8 fw-600">{{'REGISTER_COMPONENT.SUCCESSFULLY_CONFIRMED' | translate}}</div>
          <div class="fs-14 lh-20 text-gray-600 fw-500" innerHTML="{{'REGISTER_COMPONENT.SUCCESSFULLY_CONFIRMED_TEXT' | translate}}"></div>
        </div>
      </div>
    </div>
  </div>
  <app-auth-pages-footer></app-auth-pages-footer>
</div>
