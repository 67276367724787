<div class="page-root" cdkScrollable>
  <div class="header-row">
    <app-mobile-menu-button></app-mobile-menu-button>

    <div class="page-header">
      <div class="page-title">
        Поиск
      </div>
    </div>
    <tc-tabs [tabs]="tabs"></tc-tabs>
  </div>

  <router-outlet></router-outlet>
</div>
