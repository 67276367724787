import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { Observable } from 'rxjs';
import { fromPromise } from 'rxjs/internal/observable/innerFrom';
import { HttpClient } from '@angular/common/http';
import { FinanceHistoryListItemModel } from '../modules/finance/models/finance-history-list-item.model';
import { FinanceHistoryListFilterModel } from '../modules/finance/models/finance-history-list-filter.model';
import { omitBy } from 'lodash';
import { FutureExpenseModel } from '../modules/finance/models/future-expense.model';
import { TaxModel } from '../modules/finance/models/tax.model';
import { InvoiceModel } from '@/models/financev2/invoice.model';
import { AccountModel } from '@/models/financev2/account.model';

@Injectable({
  providedIn: 'root'
})
export class FinancesApiService extends BaseService {

  constructor(
    http: HttpClient,
  ) {
    super(http);
  }

  public getHistoryOperations = (model?: FinanceHistoryListFilterModel): Observable<FinanceHistoryListItemModel[]> => {
    if (model) {
      return this.executeGet('v2/finances/history', { params: omitBy(model, (value: any) => !Boolean(value)) } );
    }
    return this.executeGet('v2/finances/history');
  }

  public getAwaitingPayments = (): Observable<FutureExpenseModel[]> => {
    return this.executeGet('v2/finances/awaitingPayment');
  }

  public getTax = (): Observable<TaxModel> => {
    return this.executeGet('v2/finances/tax');
  }

  public sbpBanks = (): Observable<any> => {
    return this.executeGet('account/sbpBanks');
  }

  public getIncomeInfoForThisYear = (): Observable<any> => {
    return this.executeGet('v2/finances/incomeInfoForThisYear');
  }

  public cancelReceiptReasons = (): Observable<any> => {
    return this.executeGet('v2/finances/cancelIncomeReasonList');
  }

  public cancelReceipt = (receiptId: string, reasonCode: string): Observable<any> => {
    return fromPromise(this.executePost('v2/finances/cancelReceipt', { receiptId, reasonCode }));
  }

  public getBalance = (): Observable<AccountModel> => {
    return this.executeGet(`v2/finances/account`);
  }

  public createInvoice = (amount): Promise<InvoiceModel> => {
    return this.executePost(`v2/finances/account/createInvoice`, {amount});
  }

  public getUnpaidInvoices = (): Observable<InvoiceModel[]> => {
    return this.executeGet(`v2/finances/account/invoice`);
  }

  public deleteInvoice = (id: string): Promise<any> => {
    return this.executeDelete(`v2/finances/account/invoice`, { params: { id } });
  }

  public withdraw = (amount: number): Promise<any> => {
    return this.executePost(`v2/finances/account/withdraw`, {amount});
  }
}
