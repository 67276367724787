import { Component } from '@angular/core';
import { SvgIconsEnum } from '@/types/svg-icons.enum';

@Component({
  selector: 'confirm-success',
  templateUrl: './confirm-success.component.html',
  styleUrls: ['./confirm-success.component.scss']
})
export class ConfirmSuccessComponent {
  public svgIconsEnum = SvgIconsEnum;
}
