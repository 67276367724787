import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { HttpClient } from '@angular/common/http';
import { Observable, of, share, Subject } from 'rxjs';
import { map } from 'lodash';
import { ContractEventModel } from '../modules/contracts/models/contract-event.model';
import { environment } from '../../environments/environment';
import { FileUpload } from '../models/fileUpload';
import { TextMessageModel } from '../modules/employer/models/task-comments/text-message.model';
import { SendMessageRequestModel } from '../modules/contracts/models/message.model';
import { fromPromise } from 'rxjs/internal/observable/innerFrom';
import { ChatListItemModel } from '../modules/contracts/models/chat-list-item.model';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ChatApiService extends BaseService {

  public updateCurrentChat = new Subject<any>();
  protected baseUrl = `${window.location.protocol}//${environment.MAIN_HOST}/api/`;

  constructor(
    http: HttpClient,
  ) {
    super(http);
  }

  public getAllChats = (searchQuery: string = ''): Observable<{ chats: ChatListItemModel[] }> => {
    return this.executeGet(`v2/chat/chats` + (searchQuery ? `?searchQuery=${searchQuery}` : ''));
  }

  public getAllProposals = (filters: string): Observable<{ proposals: ChatListItemModel[] }> => {
    return this.executeGet(`v2/chat/proposals?${filters}`);
  }

  public getChatEvents = (chatId: string): Observable<ContractEventModel[]> => {
    return this.executeGet('v2/chat/events', { params: { chatId }});
  }

  public sendMessageToChat = (chatId: string, text: string, files: Array<FileUpload> = []): Observable<any> => {
    const messageModel = new TextMessageModel(text, map(files, (_: FileUpload) => _.id));
    const model = new SendMessageRequestModel(chatId, 'm.chat.message', messageModel);

    return this.http.post(this.baseUrl + 'v2/chat', model);
  }

  public declineFreelancer = (chatId: string): Observable<void> => {
    return this.executePut('v2/jobConversation/declineProposal', { id: chatId });
  }

  public acceptFreelancer = (chatId: string): Observable<void> => {
    return this.executePut('v2/jobConversation/acceptProposal', { id: chatId });
  }

  public declineInvite = (chatId: string): Observable<void> => {
    return this.executePut('v2/jobConversation/declineInvite', { id: chatId });
  }

  public acceptInvite = (chatId: string): Observable<void> => {
    return this.executePut('v2/jobConversation/acceptInvite', { id: chatId });
  }

  public decline = (chatId: string): Observable<void> => {
    return this.executePut('v2/jobConversation/decline', { id: chatId });
  }

  public cancelPayrollPayment = (id: string): Promise<void> => {
    return this.executeDelete('v2/jobConversation/cancelPayrollPayment', { params: { id } });
  }

  public createProjectChat = (body: {projectId: string, name: string, participantIds: string[]}): Promise<any> => {
    return this.executePost('v2/chat/create',  body);
  }

  public editChat = (body): Observable<any> => {
    return this.executePut('v2/chat/edit', body);
  }

  public deleteMessage = (eventId: string, forAll: boolean = true): Observable<any> => {
    return fromPromise(this.executeDelete('v2/chat/event', {params: {eventId, forAll}}));
  }

  public clearChat = (id: string): Observable<any> => {
    return fromPromise(this.executeDelete('v2/chat/clear', {params: {id}}));
  }

  public deleteChat = (id: string): Observable<any> => {
    return fromPromise(this.executeDelete('v2/chat', {params: {id}}));
  }

  public deleteChatForMe = (id: string): Observable<any> => {
    return fromPromise(this.executeDelete('v2/chat/deleteMeFromProjectChat', {params: {id}}));
  }

  public leaveChat = (id: string): Observable<any> => {
    return fromPromise(this.executeDelete('v2/chat/leave', {params: {id}}));
  }

  public getUnreadCount = (): Observable<any> => {
    return this.executeGet('v2/chat/unreadCount').pipe(share());
  }

  public getSupportChat = (): Observable<ChatListItemModel> => {
    return this.executeGet<ChatListItemModel>(`v2/chat/support`).pipe(catchError(() => of(null)));
  }

  public createSupportChat = (): Observable<any> => {
    return fromPromise(this.executePost('v2/chat/support', null));
  }
}
