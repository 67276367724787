import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormControl, UntypedFormArray } from '@angular/forms';
import { Clipboard } from '@angular/cdk/clipboard';

import { lastValueFrom, Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';

import { ProjectSearchSorting } from 'src/app/classes/enums';
import { CategoryService } from 'src/app/services/category.service';
import { SearchService } from 'src/app/services/search.service';
import { IBaseFilters } from '@/classes/iBaseFilters';
import { SpinnerService } from '@/modules/tpt-ui/services/spinner.service';
import { ProjectSearchItemModel } from '@/models/search/projectSearchItem.model';
import { ProfileService } from '@/services/profile.service';
import { SvgIconsEnum } from '@/types/svg-icons.enum';
import { CurrencyEnum, CurrencySymbol } from '@/models/currency.enum';
import { ProfilesApiService } from '@/services/profiles.api.service';
import { FreelancerLegalStatusEnum } from '@/models/legalStatuses.enum';
import { RouteHelper } from '@/helpers/route.helper';
import { JobApplyErrorDialogComponent } from '@/modules/common-dialogs/components/job-apply-error-dialog/job-apply-error-dialog.component';
import { ProfileModel } from '@/models/profile.model';
import { WarningDialogComponent } from '@/modules/common-dialogs/components/warning-dialog/warning-dialog.component';
import { LanguageService } from '@/services/language.service';
import { Language } from '@/models/language';
import { showTooltip } from '@/helpers/tooltip.helper';

@Component({
  selector: 'tpt-project-card',
  templateUrl: './project-card.component.html',
  styleUrls: ['./project-card.component.scss']
})
export class ProjectCardComponent implements OnInit, OnDestroy {

  get isEmployer(): boolean {
    return this.profileService.currentProfile.isEmployer();
  }

  get restrictedAccess(): boolean {
    return !this.profileService.currentProfile.selfEmployedStatus ||
      this.profileService.currentProfile.selfEmployedStatus === 'NEW' || this.notValidData;
  }

  get disableButton(): boolean {
    return ['DETACHED', 'REMOVED_FROM_REGISTER', 'NOT_VERIFIED', 'NEW']
      .includes(this.profileService.currentProfile.selfEmployedStatus);
  }

  get notValidData(): boolean {
    return this.profileService.currentProfile.selfEmployedStatus === 'ACTIVE' &&
      (!this.profileService.currentProfile.permissions?.includes('LEGAL_INFO_VALIDATED')
        || !this.profileService.currentProfile.permissions?.includes('BANK_DETAILS_VALIDATED')
        || this.profileService.currentProfile.permissions?.includes('ANNUAL_PAYMENTS_LIMIT_EXCEEDED')
      );
  }

  @ViewChild(JobApplyErrorDialogComponent)
  public jobApplyErrorDialogComponent: JobApplyErrorDialogComponent;

  @ViewChild(WarningDialogComponent) warningDialogComponent: WarningDialogComponent;
  @Output() openFilterDialog = new EventEmitter<void>();
  @Output() emitSortChange = new EventEmitter();
  @Output() searchEvent = new EventEmitter();
  @Input() projects: ProjectSearchItemModel[];
  @Input() skillsFilter: UntypedFormArray;
  @Input() showSkeleton: boolean;
  @Input() totalElements: number;

  public filters: Partial<IBaseFilters> = {
    sort: ProjectSearchSorting.PUBLISH_DATE_DESC,
    skills: [],
    expertLevel: null,
    location: null,
    searchText: '',
    pageIndex: 0,
    pageSize: 20,
    hourlyRate: [0, 1000],
  };

  public profiles: ProfileModel[] = [];
  public categories = [];
  public svgIconsEnum = SvgIconsEnum;
  public languages: Language[] = [];

  public searchControl: FormControl<string>;
  public sortDirection: 'PUBLISH_DATE_DESC' | 'PUBLISH_DATE_ASC' = 'PUBLISH_DATE_DESC';
  public currencySymbol = CurrencySymbol;
  public currencyEnum = CurrencyEnum;
  public freelancerLegalEnum = FreelancerLegalStatusEnum;

  private readonly destroy = new Subject<void>();

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private routeHelper: RouteHelper,
    private categoryService: CategoryService,
    private languagesService: LanguageService,
    private searchService: SearchService,
    private spinner: SpinnerService,
    public profileService: ProfileService,
    private profileApiService: ProfilesApiService,
    private clipboard: Clipboard,
  ) {}

  async ngOnInit(): Promise<void> {
    await this.getCategories();
    this.languages = await lastValueFrom(this.languagesService.getLanguagesV2());

    this.searchControl = new FormControl('');
    this.searchControl.valueChanges.pipe(debounceTime(800), takeUntil(this.destroy)).subscribe((val) => {
      this.searchProjects();
    });
  }

  ngOnDestroy() {
    this.destroy.next();
    this.destroy.complete();
  }

  public openCardDetails(project: ProjectSearchItemModel): void {
    const visitedJobCards = JSON.parse(localStorage.getItem('visitedJobCards') || '{}');
    const threeDaysInMs = 3 * 24 * 60 * 60 * 1000;
    visitedJobCards[project.id] = Date.now() + threeDaysInMs;
    localStorage.setItem('visitedJobCards', JSON.stringify(visitedJobCards));
    project.visited = true;

    const url = this.router.serializeUrl(
      this.router.createUrlTree(this.routeHelper.projectPage(project.id))
    );

    window.open(url, '_blank');
  }

  public async getCategories(): Promise<void> {
    this.categories = await lastValueFrom(this.categoryService.getCategoriesV2());
  }

  public getProjectCategory(project): string {
    const category = this.categories.find(c => c.id === project.parentCategoryId);
    project.category = category;
    return this.getTranslation(category);
  }

  public getProjectSubCategory(project): string {
    const category = this.categories.find(c => c.id === project.parentCategoryId);
    const subCategory = category.children.find(c => c.id === project.subCategoryId);
    project.subCategory = subCategory;
    return this.getTranslation(subCategory);
  }

  public getTranslation(item): string {
    return item?.nameRu;
  }

  public copyJobLink(job, tooltip): void {
    let baseUrl = window.location.href;
    baseUrl = baseUrl.split('/').slice(0, 3).join('/');

    const userUrl = this.router.serializeUrl(
      this.router.createUrlTree(this.routeHelper.projectPage(job.id))
    );

    const fullUrl = `${baseUrl}${userUrl}`;

    this.clipboard.copy(fullUrl);
    showTooltip(tooltip);
  }

  public sendProposal(project: ProjectSearchItemModel) {
    if (this.restrictedAccess || this.disableButton) {
      this.jobApplyErrorDialogComponent.open();
      return;
    }

    this.spinner.startSpinner();
    this.searchService.sendProposal(project.id)
      .then(res => {
        if (res) {
          project.jobConversations.push({
            chatId: res.id,
            participationState: 'PROPOSAL_SUBMITTED'
          });
          this.spinner.stopSpinner();
        }
      })
      .catch((error) => {
        if (error.errorCode?.message === 'SERVER_ERRORS.PAYMENT_AMOUNT_MORE_THAN_LIMIT') {
          this.warningDialogComponent.open('Вы не можете подать заявку на этот проект',
            'Стоимость проекта превысит лимит вашего дохода в качестве самозанятого в этом календарном году',
            'Закрыть',
            SvgIconsEnum.ErrIcon);
        }
        if (error.errorCode?.message === 'SERVER_ERRORS.LIMIT_ALMOST_EXCEEDED') {
          this.warningDialogComponent.open('Вы не можете подать заявку на этот проект',
            `До превышения лимита вашего годового дохода осталось меньше 1 000 рублей.<br/><br/>
                  Вы сможете отправлять заявки на проекты с&nbsp;1&nbsp;января следующего года.`,
            'Закрыть',
            SvgIconsEnum.ErrIcon);
        }
        this.spinner.stopSpinner();
      });
  }

  public getLangByCode(lang) {
    const language = this.languages?.find(item => item.code === lang.code);
    return this.getTranslation(language);
  }

  public specSkills(project) {
    const skills = project.skills || [];
    const customSkills = project.customSkills || [];

    return [...skills, ...customSkills];
  }

  public openEmployerInfo(owner): void {
    this.router.navigate(this.routeHelper.userPage(owner.id));
  }

  public searchProjects(): void {
    const searchValue = this.searchControl.value.trim();
    this.searchEvent.emit(searchValue);
  }

  public openChat(chatId) {
    this.router.navigate(this.routeHelper.contractsProposalsUrl(chatId));
  }
}
