import { Injectable } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { each } from 'lodash/fp';
import { CoSvgIconsEnum, SvgIconsEnum } from '../types/svg-icons.enum';

@Injectable({
  providedIn: 'root'
})
export class IconsService {

  static svgIcons = [
    SvgIconsEnum.SidebarHome,
    SvgIconsEnum.SidebarFinances,
    SvgIconsEnum.SidebarPayments,
    SvgIconsEnum.SidebarMessages,
    SvgIconsEnum.SidebarSettings,
    SvgIconsEnum.SidebarFns,
    SvgIconsEnum.SidebarProjects,
    SvgIconsEnum.SidebarTeam,
    SvgIconsEnum.SidebarExit,
    SvgIconsEnum.Clock,
    SvgIconsEnum.Trash,
    SvgIconsEnum.Trash2,
    SvgIconsEnum.CircleCheckmark,
    SvgIconsEnum.Info,
    SvgIconsEnum.Cross,
    SvgIconsEnum.Attachment,
    SvgIconsEnum.Comment,
    SvgIconsEnum.AssigneePlaceholder,
    SvgIconsEnum.AttachedFile,
    SvgIconsEnum.Bell,
    SvgIconsEnum.ArrowLeft,
    SvgIconsEnum.ArrowRight,
    SvgIconsEnum.SidebarSearch,
    SvgIconsEnum.DialogFailureIcon,
    SvgIconsEnum.Location,
    SvgIconsEnum.LocationOutline,
    SvgIconsEnum.DatepickerCalendar,
    SvgIconsEnum.Check,
    SvgIconsEnum.Notification,
    SvgIconsEnum.Language,
    SvgIconsEnum.ArrowDown,
    SvgIconsEnum.SidebarArrow,
    SvgIconsEnum.SuccessIcon,
    SvgIconsEnum.Budget,
    SvgIconsEnum.Candidate,
    SvgIconsEnum.TaskList,
    SvgIconsEnum.List,
    SvgIconsEnum.ArrowBack,
    SvgIconsEnum.Edit,
    SvgIconsEnum.Delete,
    SvgIconsEnum.InfoIcon,
    SvgIconsEnum.InfoCircle,
    SvgIconsEnum.Plus,
    SvgIconsEnum.PlusFilter,
    SvgIconsEnum.Minus,
    SvgIconsEnum.PlusIcon,
    SvgIconsEnum.Details,
    SvgIconsEnum.Expand,
    SvgIconsEnum.ExpandIcon,
    SvgIconsEnum.Search,
    SvgIconsEnum.HeaderLogo,
    SvgIconsEnum.ArrowForward,
    SvgIconsEnum.FooterLogo,
    SvgIconsEnum.Linkedin,
    SvgIconsEnum.Telegram,
    SvgIconsEnum.Medium,
    SvgIconsEnum.Message,
    SvgIconsEnum.Close,
    SvgIconsEnum.PageUp,
    SvgIconsEnum.ConfirmSuccess,
    SvgIconsEnum.Mail,
    SvgIconsEnum.HighPrio,
    SvgIconsEnum.MediumPrio,
    SvgIconsEnum.LowPrio,
    SvgIconsEnum.NoPrio,
    SvgIconsEnum.ExpandMore,
    SvgIconsEnum.Stripe,
    SvgIconsEnum.Payoneer,
    SvgIconsEnum.Fee,
    SvgIconsEnum.Arbitrage,
    SvgIconsEnum.SmallArrowDown,
    SvgIconsEnum.Camera,
    SvgIconsEnum.FilterList,
    SvgIconsEnum.Bubble,
    SvgIconsEnum.Mustache,
    SvgIconsEnum.Zzz,
    SvgIconsEnum.Cancel,
    SvgIconsEnum.BubbleMessage,
    SvgIconsEnum.Flag,
    SvgIconsEnum.CreditCard,
    SvgIconsEnum.Keyboard,
    SvgIconsEnum.HelpOutline,
    SvgIconsEnum.ExpandLess,
    SvgIconsEnum.Individual,
    SvgIconsEnum.IndividualEntrepreneur,
    SvgIconsEnum.LegalEntity,
    SvgIconsEnum.Hourglass,
    SvgIconsEnum.Download,
    SvgIconsEnum.DownloadIcon,
    SvgIconsEnum.MoreHoriz,
    SvgIconsEnum.MoreVert,
    SvgIconsEnum.Link,
    SvgIconsEnum.Sticky,
    SvgIconsEnum.Archived,
    SvgIconsEnum.DocUpload,
    SvgIconsEnum.Doc,
    SvgIconsEnum.PurpleCheck,
    SvgIconsEnum.Bank,
    SvgIconsEnum.CheckSmall,
    SvgIconsEnum.Refresh,
    SvgIconsEnum.LegalInfo,
    SvgIconsEnum.Contact,
    SvgIconsEnum.CheckSuccess,
    SvgIconsEnum.InfoWarn,
    SvgIconsEnum.CopyLink,
    SvgIconsEnum.StopWatch,
    SvgIconsEnum.Email,
    SvgIconsEnum.Phone,
    SvgIconsEnum.Web,
    SvgIconsEnum.Skype,
    SvgIconsEnum.Dollar,
    SvgIconsEnum.ThinCheck,
    SvgIconsEnum.Staple,
    SvgIconsEnum.UploadSuccess,
    SvgIconsEnum.Replay,
    SvgIconsEnum.EyeOpen,
    SvgIconsEnum.EyeClose,
    SvgIconsEnum.Deregistered,
    SvgIconsEnum.SupportAvatar,
    SvgIconsEnum.Processing,
    SvgIconsEnum.XMark,
    SvgIconsEnum.CheckCircle,
    SvgIconsEnum.Time,
    SvgIconsEnum.Loader,
    SvgIconsEnum.Clipboard,
    SvgIconsEnum.Sbp,
    SvgIconsEnum.WarnIcon,
    SvgIconsEnum.ErrIcon,
    SvgIconsEnum.WarningState,
    SvgIconsEnum.ErrorState,
    SvgIconsEnum.SuccessState,
    SvgIconsEnum.CheckThin,
    SvgIconsEnum.ArrowRefresh,
    SvgIconsEnum.Media,
    SvgIconsEnum.BurgerMenu,
    SvgIconsEnum.Grid,
    SvgIconsEnum.CoInfo,
    SvgIconsEnum.SidebarContracts,
    SvgIconsEnum.WarningSmall,
    SvgIconsEnum.Settings,
    SvgIconsEnum.User,
    SvgIconsEnum.SwitchService,
    SvgIconsEnum.Support,
    SvgIconsEnum.Upload,
    SvgIconsEnum.Gender,
    SvgIconsEnum.Expand,
    SvgIconsEnum.Chain,
    SvgIconsEnum.Digits,
    SvgIconsEnum.World,
    SvgIconsEnum.ActiveStatus,
    SvgIconsEnum.RemovedFromRegisterStatus,
    SvgIconsEnum.DetachedStatus,
    SvgIconsEnum.RestrictedStatus,
    SvgIconsEnum.File,
    SvgIconsEnum.CategorySmall,
    SvgIconsEnum.CategoryBig,
    SvgIconsEnum.SubCategorySmall,
    SvgIconsEnum.SubCategoryBig,
    SvgIconsEnum.FileError,
    SvgIconsEnum.Save,
    SvgIconsEnum.PriceTag,
    SvgIconsEnum.Expert,
    SvgIconsEnum.EmptyList,
    SvgIconsEnum.UserIcon,
    SvgIconsEnum.UserIconGray,
    SvgIconsEnum.Talent,
    SvgIconsEnum.PaperPlane,
    SvgIconsEnum.Tree,
    SvgIconsEnum.Chat,
    SvgIconsEnum.ArrowUp,
    SvgIconsEnum.Attention,
    SvgIconsEnum.FilledFile,
    SvgIconsEnum.FilledPlanet,
    SvgIconsEnum.Archive,
    SvgIconsEnum.DeleteFile,
    SvgIconsEnum.WavyCheck,
    SvgIconsEnum.EditIcon,
    SvgIconsEnum.Active,
    SvgIconsEnum.DeregisteredBadge,
    SvgIconsEnum.Detached,
    SvgIconsEnum.IE,
    SvgIconsEnum.LE,
    SvgIconsEnum.AlertTriangle,
    SvgIconsEnum.FileExport,
    SvgIconsEnum.SolidCross,
    SvgIconsEnum.SolidCheck,
    SvgIconsEnum.Pin,
    SvgIconsEnum.Folders,
    SvgIconsEnum.Send,
    SvgIconsEnum.ActiveProject,
    SvgIconsEnum.Draft,
    SvgIconsEnum.Ended,
    SvgIconsEnum.Ruble,
    SvgIconsEnum.Envelope,
    SvgIconsEnum.EnvelopeOutline,
    SvgIconsEnum.TalentCloud,
    SvgIconsEnum.TalentRole,
    SvgIconsEnum.ClientRole,

    // Creative objects icons
    CoSvgIconsEnum.Image,
    CoSvgIconsEnum.Books,
    CoSvgIconsEnum.Quotes,
    CoSvgIconsEnum.Headphones,
    CoSvgIconsEnum.Photo,
    CoSvgIconsEnum.Planet,
    CoSvgIconsEnum.Puzzle,
    CoSvgIconsEnum.Crop,
    CoSvgIconsEnum.Text,
    CoSvgIconsEnum.Play,
    CoSvgIconsEnum.Code,
    CoSvgIconsEnum.Comics,
    CoSvgIconsEnum.Seller,
    CoSvgIconsEnum.Buyer,
    CoSvgIconsEnum.FullLogo,
    CoSvgIconsEnum.SmallLogo,
    CoSvgIconsEnum.SidebarWallet,
    CoSvgIconsEnum.SidebarProducts,
    CoSvgIconsEnum.SidebarHome,
    CoSvgIconsEnum.SidebarMessages,
    CoSvgIconsEnum.SidebarSettings,

    CoSvgIconsEnum.Folder,
    CoSvgIconsEnum.Study,
    CoSvgIconsEnum.PO,
    CoSvgIconsEnum.Design,
    CoSvgIconsEnum.Book,
    CoSvgIconsEnum.Audio,
    CoSvgIconsEnum.Music,
    CoSvgIconsEnum.Buy,
    CoSvgIconsEnum.CoSwitcher,
    CoSvgIconsEnum.TcSwitcher,
    CoSvgIconsEnum.Cart,
    CoSvgIconsEnum.TopUp,
    CoSvgIconsEnum.PriceTag,
    CoSvgIconsEnum.Withdraw,
    CoSvgIconsEnum.Exit,
  ];

  constructor(
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
  ) { }

  public registerIcons(): void {
    each(this.addSvgIcon, IconsService.svgIcons);
  }

  private addSvgIcon = (image: string): void => {
    this.matIconRegistry.addSvgIcon(
      image,
      this.domSanitizer.bypassSecurityTrustResourceUrl(`/assets/icons/${image}.svg`),
    );
  }

}
