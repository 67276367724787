import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialogConfig } from '@angular/material/dialog';
import { Clipboard } from '@angular/cdk/clipboard';
import { Router } from '@angular/router';
import * as moment from 'moment-timezone';
import { Observable } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

import { DialogComponent } from '@/modules/dialog/components/dialog/dialog.component';
import { ContractsService } from '@/modules/contracts/services/contracts.service';
import { Language } from '@/models/language';
import { ProfileModel } from '@/models/profile.model';
import { SvgIconsEnum } from '@/types/svg-icons.enum';
import { ProfilesApiService } from '@/services/profiles.api.service';
import { LanguageService } from '@/services/language.service';
import { SnackbarNotificationsService } from '@/services/snackbar-notifications.service';
import { RouteHelper } from '@/helpers/route.helper';

@Component({
  selector: 'tpt-freelancer-info-dialog',
  templateUrl: './freelancer-info-dialog.component.html',
  styleUrls: ['./freelancer-info-dialog.component.scss', './../common-info-dialog-styles.scss']
})
export class FreelancerInfoDialogComponent implements OnInit {

  @ViewChild(DialogComponent)
  public dialog: DialogComponent;

  public svgIconsEnum = SvgIconsEnum;

  public freelancer: ProfileModel;

  public reviews$: Observable<any>;

  public languagesArray: Array<Language> = [];

  private config: MatDialogConfig = {
    width: '520px',
    height: '620px'
  };

  private className = 'tpt-freelancer-info-dialog';

  constructor(private translate: TranslateService,
              private contractsService: ContractsService,
              private router: Router,
              private clipboard: Clipboard,
              private routeHelper: RouteHelper,
              private snack: SnackbarNotificationsService,
              private languageService: LanguageService,
              private profileApiService: ProfilesApiService) {
  }

  ngOnInit() {
    this.languageService.getLanguagesV2().subscribe((langs: Language[]) => this.languagesArray = langs);
  }

  public open(freelancer, offset): void {
    if (offset) {
      this.config.panelClass = 'chat-user-info-align';
    }
    this.dialog.config = this.config;
    this.dialog.open(this.className);
    this.freelancer = freelancer;

    // this.getReviews(freelancer.id);
  }

  public close(): void {
    this.dialog.close();
  }

  public getTime(timeZone): string {
    if (timeZone === 'Z') {
      return;
    }
    return moment().tz(timeZone).format('HH:mm');
  }

  get categoryName(): string {
    if (!this.contractsService.selectedChat) { return; }
    if (this.translate.currentLang === 'ru') {
      if (this.contractsService.selectedChat.jobCategoryInfo.subCategory &&
        this.contractsService.selectedChat.jobCategoryInfo.subCategory.id) {
        return this.contractsService.selectedChat.jobCategoryInfo.subCategory.nameRu;
      }
      return this.contractsService.selectedChat.jobCategoryInfo.parentCategory.nameRu;
    }
    if (this.contractsService.selectedChat.jobCategoryInfo.subCategory &&
      this.contractsService.selectedChat.jobCategoryInfo.subCategory.id) {
      return this.contractsService.selectedChat.jobCategoryInfo.subCategory.name;
    }
    return this.contractsService.selectedChat.jobCategoryInfo.parentCategory.name;
  }

  public get mainSpec() {
    return this.freelancer.specializations?.find(item => item.main);
  }

  public getTranslation(item): string {
    if (!item) { return; }
    if (this.translate.currentLang === 'ru') {
      return item.nameRu;
    }
    return item.name;
  }

  public getLink(fileId: string): string {
    return this.profileApiService.getReviewsLink(fileId);
  }

  public getAllSkills() {
    const concat = (...arrays) => [].concat(...arrays.filter(Array.isArray));
    return concat(this.mainSpec?.skills, this.mainSpec?.customSkills);
  }

  public getReviews(id): void {
    this.reviews$ = this.profileApiService.getUserReviews(id);
  }

  public openProfilePage(): void {
    const userUrl = this.router.serializeUrl(
      this.router.createUrlTree(this.routeHelper.userPage(this.freelancer.id))
    );

    window.open(userUrl, '_blank');
  }

  public getLangByCode(lang): string {
    const language = this.languagesArray?.find(item => item.code === lang.code);
    return this.getTranslation(language);
  }
}
