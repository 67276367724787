<tpt-dialog>
  <div class="" *ngIf="filter">
    <tc-search-filters [filter]="filter" [type]="type"></tc-search-filters>

    <tc-button type="primary" size="x-large" class="full-width mt-40" (onClick)="close()">
      <mat-icon [svgIcon]="svgIconsEnum.CheckSmall"></mat-icon>
      Применить
    </tc-button>
  </div>
</tpt-dialog>
