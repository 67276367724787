import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, filter, map, switchMap, take } from 'rxjs/operators';
import { AuthService, TokenData } from '@/services/auth.service';
import { ProfileService } from '@/services/profile.service';

export const InterceptorSkipHeader = 'X-Skip-Interceptor';

const TOKEN_KEY = 'auth-token';
const REFRESHTOKEN_KEY = 'auth-refreshtoken';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  private isRefreshing = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  constructor(private authService: AuthService,
              private profileService: ProfileService) {
  }

  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const accessToken = localStorage.getItem(TOKEN_KEY);
    if (!req.headers.has('Authorization') && accessToken && !req.headers.has(InterceptorSkipHeader)) {
      req = req.clone(
        {
          setHeaders: {
            Authorization: `Bearer ${ accessToken }`,
            'Accept-Language': 'ru'
          },
        }
      );

      const expiresAt = +localStorage.getItem('expires_at');
      if (new Date(expiresAt) <= new Date()) {
        if (localStorage.getItem('at')?.includes('social_g')) {
          this.profileService.logout();
          return throwError(() => new HttpErrorResponse({ error: 'no refresh token' }));
        }

        this.handle401Error(req, next).subscribe({
          next: () => {
            return next.handle(req);
          }
        });
      }
    }

    return next.handle(req).pipe(catchError(
      (err) => {
        if (err.status === 401 || err?.error.path?.includes('unreadCount')) {
          if (err?.error?.error_description?.includes('Invalid refresh token')) {
            this.profileService.logout();
            return throwError(err);
          }
          if (err.status === 401 && err?.error?.details?.hasOwnProperty('visibility') && err?.error?.path?.includes('v2/projects')) {
            return throwError(err);
          }
          return this.handle401Error(req, next);
        } else {
          return throwError(err);
        }
       }
    ));
  }

  private handle401Error(request: HttpRequest<any>, next: HttpHandler): Observable<any> {
    if (this.isRefreshing) {
      return this.refreshTokenSubject.pipe(
        filter(result => result !== null),
        take(1),
        switchMap((token) => next.handle(this.addTokenHeader(request, token))));
    } else {
      const refreshToken = localStorage.getItem(REFRESHTOKEN_KEY);

      if (refreshToken) {
        this.isRefreshing = true;
        this.refreshTokenSubject.next(null);

        return this.authService.refreshToken().pipe(
          switchMap((token: TokenData) => {
            this.isRefreshing = false;
            this.refreshTokenSubject.next(token.access_token);
            this.authService.updateToken(token);
            return next.handle(this.addTokenHeader(request, token.access_token));
          }),
          catchError((err: any) => {
            this.isRefreshing = false;
            this.profileService.logout();
            return throwError(err);
          }));
      } else {
        this.isRefreshing = false;
        this.profileService.logout();
        return throwError(() => new HttpErrorResponse({}));
      }
    }
  }

  private addTokenHeader(request, accessToken) {
    if (!accessToken) {
      return request;
    }

    return request.clone({
      setHeaders: {
        Authorization: `Bearer ${accessToken}`
      }
    });
  }
}
