<div class="header-container">
  <nav class="home-header">
    <mat-icon [svgIcon]="svgIconsEnum.FooterLogo" class="desktop-logo" [routerLink]="['/']"></mat-icon>
    <mat-icon [svgIcon]="svgIconsEnum.HeaderLogo" class="mobile-logo" [routerLink]="['/']"></mat-icon>

    <div class="buttons">
      <tc-button type="secondary" size="medium" [queryParams]="activatedRoute.snapshot.queryParams"
              [routerLink]="['/login']">
        <mat-icon [svgIcon]="svgIconsEnum.SidebarExit"></mat-icon>
        {{'MAIN_PAGE.SIGN_IN' | translate}}
      </tc-button>
      <tc-button type="primary" size="medium"
                 [routerLink]="['/register']">{{'MAIN_PAGE.SIGN_UP' | translate}}</tc-button>
    </div>
  </nav>
</div>
