import {Injectable} from '@angular/core';
import {BaseService} from '@/services/base.service';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PayrollService extends BaseService {
  constructor(http: HttpClient) {
    super(http);
  }

  public restoreEmployee = (employeeId: string): Observable<void> => {
    return this.executePut('employees/restore', null, { params: { employeeId } });
  }

  public getEmployees = (params: {pageSize: number, pageNumber: number, state: string, searchStr: string}): Observable<any> => {
    return this.executeGet('employees', { params });
  }

  public addEmployee = (profileId: string): Promise<void> => {
    return this.executePost('employees', null, { params: { profileId } });
  }

  public saveFrameworkContract(body?: {
    file: File
    employeeId: string,
    contractNumber: string
    fromDate: string
    toDate: string
  }): Promise<any> {
    const formData = new FormData();
    formData.append('file', body.file, body.file.name);
    formData.append('employeeId', `${body.employeeId}`);
    formData.append('contractNumber', `${body.contractNumber}`);
    formData.append('fromDate', `${body.fromDate}`);
    formData.append('toDate', `${body.toDate}`);

    return this.executePost('employees/frameworkContract', formData);
  }

  public deleteEmployee = (employeeId: string): Promise<void> => {
    return this.executeDelete('employees', { params: { employeeId } });
  }

  public createPayrollPayment = (body): Promise<any> => {
    return this.executePost('employees/createPayrollPayment', body);
  }

  public getInviteCode = (): Observable<{ code: string }> => {
    return this.executeGet('employees/inviteCode');
  }

  public deleteFrameworkContract = (frameworkContractId: string): Promise<any> => {
    return this.executeDelete('employees/frameworkContract', { params: { frameworkContractId } });
  }

  public bindEmployee = (code: string): Promise<any> => {
    return this.executePost('employee/bind', { code });
  }

  public getClients = (params: {pageSize: number, pageNumber: number, state: string, searchStr: string}): Observable<any> => {
    return this.executeGet('employee', { params });
  }

  public getPossibleEmployees = (params: { searchStr: string }): Observable<any> => {
    return this.executeGet('employees/possibleEmployees', { params });
  }

  public editPayrollPayment = (data): Promise<any> => {
    return this.executePost('v2/jobConversation/editPayrollPayment', data);
  }
}
