import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { SvgIconsEnum } from '@/types/svg-icons.enum';
import { CategoryService } from '@/services/category.service';
import { FormBuilder, FormControl, FormGroup, UntypedFormArray, Validators } from '@angular/forms';
import { lastValueFrom, Subject } from 'rxjs';
import { SkillV2Model } from '@/models/skill-v2.model';
import { CategoryV2Model } from '@/models/category-v2.model';
import { CountryService } from '@/services/country.service';
import { SkillService } from '@/services/skill.service';
import { Country } from '@/models/country';

import { debounceTime, startWith, takeUntil } from 'rxjs/operators';
import { ENTER } from '@angular/cdk/keycodes';
import {
  LocationDropdownComponent
} from '@/modules/tpt-forms/tc-components/location-dropdown/location-dropdown.component';
import { ProjectApiService } from '@/services/project.api.service';

@Component({
  selector: 'tc-search-filters',
  templateUrl: './talent-filters.component.html',
  styleUrls: ['./talent-filters.component.scss', '../../../filters-common.scss']
})
export class TalentFiltersComponent implements OnInit, OnDestroy {

  @Input() filter: FormGroup;
  @Input() type: 'TALENT' | 'PROJECT';

  @ViewChild('itemInput') public itemInput: ElementRef;
  @ViewChild(LocationDropdownComponent) public locationDropdown: LocationDropdownComponent;

  public separatorKeysCodes: number[] = [ENTER];

  public svgIconsEnum = SvgIconsEnum;

  public categories: CategoryV2Model[] = [];

  public countries: Country[];
  public countriesCount: number;
  public allSkills: any[] = [];
  public skillCtrl = new FormControl<string>('');
  public allSkillsArray: SkillV2Model[] = [];
  public budgetInfo: {
    minProjectAmount: number,
    maxProjectAmount: number,
  } = null;
  public budgetSliderConfig = null;
  public filterSliderForm: FormGroup;

  public filteredItems: SkillV2Model[] = [];

  public levelsFormControl = new FormGroup({
    beginner: new FormControl<boolean>(false),
    intermediate: new FormControl<boolean>(false),
    master: new FormControl<boolean>(false),
  });

  public filterExpanded = {
    area: true,
    skills: false,
    expert: false,
    location: false,
    budget: false,
  };

  private readonly destroy$ = new Subject<void>();

  constructor(
    private categoryService: CategoryService,
    private countryService: CountryService,
    private skillService: SkillService,
    private fb: FormBuilder,
    private projectService: ProjectApiService,

  ) { }

  async ngOnInit(): Promise<void> {
    await this.getCategories();
    await this.getCountries(true);
    await this.getAllSkills();

    if (this.type === 'PROJECT') {
      this.filterSliderForm = new FormGroup({
        min: new FormControl(1000, [Validators.min(1000)]),
        max: new FormControl(600000, [Validators.max(600000)]),
      });
      this.getBudgetInfo();
    }

    this.skillCtrl.valueChanges.pipe(startWith('')).subscribe((value) => {
      this.findSkills(value);
    });

    this.levelsFormControl.valueChanges.pipe(debounceTime(300), takeUntil(this.destroy$))
      .subscribe((levels) => {
        let levelsString = '';
        const levelsArray = [];
        Object.keys(levels).forEach(key => {
          if (levels[key]) {
            levelsArray.push(key.toUpperCase());
          }
        });
        levelsString = levelsArray.join(',');
        this.filter.controls.levels.setValue(levelsString);
      });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public getBudgetInfo(): void {
    this.projectService.getSettings()
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (res) => {
          const { minProjectAmount, maxProjectAmount } = res;

          this.budgetSliderConfig = {
            connect: [false, true, false],
            tooltips: [false, false],
            range: {
              min: minProjectAmount,
              max: Math.round(maxProjectAmount),
            },
            step: 10
          };
          this.filterSliderForm.setValue({
            min: minProjectAmount,
            max: Math.round(maxProjectAmount)
          });
          this.budgetInfo = res;
        }
      });
  }

  public budgetChange(val): void {
    this.filter.controls.budgetMin.patchValue(val[0]);
    this.filter.controls.budgetMax.patchValue(val[1]);
    this.filterSliderForm.setValue({
      min: val[0],
      max: val[1]
    });
  }

  public filtersNotEmpty = (): boolean => {
    const filtersValue = this.filter.value;

    const filterFields = ['countryCode', 'levels'];
    const arrayFilterFields = ['skills', 'categories'];
    const budgetFields = ['budgetMin', 'budgetMax'];

    return Object.keys(filtersValue).some(key => {
      if (arrayFilterFields.includes(key)) {
        return !!filtersValue[key].length;
      }

      if (filterFields.includes(key)) {
        return !!filtersValue[key];
      }

      if (budgetFields.includes(key)) {
        return !!filtersValue.budgetMin && filtersValue.budgetMin !== this.budgetInfo?.minProjectAmount ||
          !!filtersValue.budgetMax && filtersValue.budgetMax !== Math.round(this.budgetInfo?.maxProjectAmount);
      }

      return false;
    });
  }

  filterNotEmpty(type): boolean {
    const filtersValue = this.filter.value;

    if (['skills', 'categories'].includes(type)) {
      return !!filtersValue[type].length;
    }

    if (['countryCode', 'levels'].includes(type)) {
      return !!filtersValue[type];
    }

    if (['budget'].includes(type)) {
      return !!filtersValue.budgetMin && filtersValue.budgetMin !== this.budgetInfo?.minProjectAmount ||
        !!filtersValue.budgetMax && filtersValue.budgetMax !== Math.round(this.budgetInfo?.maxProjectAmount);
    }
  }

  public refreshFilters = (): void => {
    const filterInitValue = {
      categories: [],
      countryCode: '',
      levels: ''
    };

    Object.keys(filterInitValue).forEach(key => {
      this.filter.controls[key]?.setValue(filterInitValue[key]);
    });
    (this.filter.controls.skills as UntypedFormArray).clear();

    this.levelsFormControl.setValue({
      beginner: false,
      intermediate: false,
      master: false
    });
    this.categories.forEach(category => {
      category.isSelected = false;
      this.setAll(false, category);
    });
    if (this.type === 'PROJECT') {
      this.filterSliderForm.setValue({
        min: this.budgetInfo?.minProjectAmount || 1000,
        max: this.budgetInfo.maxProjectAmount || 600000
      });
    }
    this.locationDropdown?.searchControl?.reset('');
  }

  setAll(checked: boolean, category) {
    category.children.forEach(item => item.isSelected = checked);
  }

  async getUpdAllSkills(checked, category, subCategory): Promise<void> {

    if (checked && !subCategory) {
      const skillsId = category.id;
      const res = await lastValueFrom(this.skillService.getCategorySkillsV2(skillsId));
      res.forEach(item => {
        if (this.allSkillsArray.some(skill => skill.id === item.id)) {
          return;
        }
        this.allSkillsArray.push(item);
      });
    }

    if (!checked && !subCategory) {
      const skillId = category.id;
      const res = await lastValueFrom(this.skillService.getCategorySkillsV2(skillId));
      const filterArr = res.map(item => item.id);
      this.allSkillsArray = this.allSkillsArray.filter(item => !filterArr.includes(item.id));
      if (!this.allSkillsArray.length) {
        this.allSkillsArray = this.allSkills;
      }
    }

    if (checked && subCategory) {
      const res = await lastValueFrom(this.skillService.getCategorySkillsV2(category.id));
      res.forEach(skill => this.allSkillsArray.push(skill));
    }

    if (!checked && subCategory) {
      if (category.children.every(child => !child.isSelected)) {
        const res: SkillV2Model[] = await lastValueFrom(this.skillService.getCategorySkillsV2(category.id));
        const filterArr = res.map(item => item.id);
        this.allSkillsArray = this.allSkillsArray.filter(item => !filterArr.includes(item.id));
        if (!this.allSkillsArray.length) {
          this.allSkillsArray = this.allSkills;
        }
      }
    }

    this.updateCategoryFormControl();
    this.filteredItems = this.allSkillsArray;
  }

  public updateCategoryFormControl(): void {
    if (!this.categories) { return; }

    const categoriesArray = [];

    this.categories.forEach(item => {
      if (item.isSelected) {
        categoriesArray.push(item.id);
      }
    });

    const notAllSelected = this.categories.filter(item => !item.isSelected);
    notAllSelected.forEach(item => {
      item.children.forEach(child => {
        if (child.isSelected) {
          categoriesArray.push(child.id);
        }
      });
    });

    this.filter.controls.categories.patchValue(categoriesArray);
  }

  public async getCategories(): Promise<void> {
    this.categories = await lastValueFrom(this.categoryService.getCategoriesV2());
    this.categories.forEach(item => {
      item.isSelected = false;
      item.expanded = false;

      item.children.forEach(child => {
        child.isSelected = false;
      });
    });
  }

  public removeSkill(index: number): void {
    const skills = this.filter.get('skills') as UntypedFormArray;

    if (index >= 0) {
      skills.removeAt(index);
    }
  }

  public selectedSkill(event: any): void {
    const skills = this.filter.get('skills') as UntypedFormArray;

    const alreadyAdded = skills.value.some(item => item.id === event.option.value.id);
    if (alreadyAdded) {
      this.skillCtrl.setValue('');
      this.itemInput.nativeElement.blur();
      return;
    }

    skills.push(this.fb.control(this.skillCtrl.value));
    this.skillCtrl.setValue('');
  }

  public toggleSubcats(category): void {
    category.expanded = !category.expanded;

    if (category.expanded) {
      this.categories.forEach(item => {
        item.expanded = item.id === category.id;
      });
    }
  }

  public getTranslation(item): string {
    return item.nameRu;
  }

  public subCategoryChanged(event, subCat, cat): void {
    subCat.isSelected = !subCat.isSelected;
    cat.isSelected = this.getAllComplete(cat);
  }

  public someChecked(category): boolean {
    const countOfChecked = category.children.filter(item => item.isSelected).length;
    return countOfChecked > 0 && countOfChecked < category.children.length;
  }

  public async getCountries(setCount: boolean, name?: string): Promise<void> {
    if (!name) {
      name = '';
    }

    this.countries = await lastValueFrom(this.countryService.getCountriesV2(name));
    if (setCount) {
      this.countriesCount = this.countries.length;
    }
  }

  public async getAllSkills(): Promise<void> {
    this.allSkills = await lastValueFrom(this.skillService.getSkillsV2());
    this.allSkillsArray = this.allSkills;
    await this.findSkills('');
  }

  private async findSkills(val): Promise<void> {
    if (!val) {
      this.filteredItems = this.allSkillsArray;
      return;
    }

    if (typeof val === 'string') {
      this.filteredItems = await lastValueFrom(this.skillService.getCategorySkillsV2(null, val));
    }
  }

  private getAllComplete(category): boolean {
    return category.children.every(item => item.isSelected);
  }

}
