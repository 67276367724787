<div class="wrapper" *ngIf="loaded; else loadTemplate">
  <div class="header-row">
    Шаг {{step}} из 3
    <mat-progress-bar [value]="step/3*100" class="mt-4"></mat-progress-bar>
  </div>

  <div class="step" *ngIf="step === 1">
    <div *ngIf="confirmStatus === confirmStatusEnum.Init" class="step-status">
      <div class="fns-bind-row">
        <div class="fns-bind-info mb-16">
          Укажите ваш ИНН или номер телефона, который привязан к&nbsp;вашему аккаунту ФНС
        </div>
      </div>

        <mat-radio-group [formControl]="bindForm.get('type')">
          <mat-radio-button labelPosition="after"
                            color="primary"
                            value="inn"
                            class="mb-12">
            Привязать через ИНН
          </mat-radio-button>
          <mat-radio-button labelPosition="after"
                            color="primary"
                            value="phone"
                            class="mb-12">
            Привязать через номер телефона
          </mat-radio-button>
        </mat-radio-group>

        <div class="input-row">
          <tc-input [control]="bindForm.controls.innValue"
                    *ngIf="bindForm.controls.type.value === 'inn'"
                    [icon]="svgIconEnums.Digits" class="code-input full-width">
            <ng-container label>Введите Ваш ИНН<span class="red-asterisk">*</span></ng-container>
          </tc-input>

          <div class="phone-input-field" *ngIf="bindForm.controls.type.value === 'phone'">
            <ngx-mat-intl-tel-input
              class="phone-input"
              [class.error]="phoneError(bindForm.get('phoneValue'))"
              name="phone"
              [onlyCountries]="['ru']"
              [formControl]="bindForm.get('phoneValue')"></ngx-mat-intl-tel-input>
            <mat-error *ngIf="phoneError(bindForm.controls.phoneValue)">Проверьте номер телефона</mat-error>
          </div>
        </div>
        <div class="actions-row mt-40">
          <tc-button type="tertiary" size="x-large" class="full-width" (onClick)="closeDialog.emit()">Отмена</tc-button>
          <tc-button type="primary" size="x-large" class="full-width" (onClick)="bindAccount()">Следующий шаг
            <svg class="ml-8" width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M8.75 1.21875L14.25 6.46875C14.4062 6.625 14.5 6.8125 14.5 7.03125C14.5 7.21875 14.4062 7.40625 14.25 7.5625L8.75 12.8125C8.46875 13.0938 7.96875 13.0938 7.6875 12.7812C7.40625 12.5 7.40625 12 7.71875 11.7188L11.875 7.78125H1.25C0.8125 7.78125 0.5 7.4375 0.5 7.03125C0.5 6.59375 0.8125 6.28125 1.25 6.28125H11.875L7.71875 2.3125C7.40625 2.03125 7.40625 1.53125 7.6875 1.25C7.96875 0.9375 8.4375 0.9375 8.75 1.21875Z" fill="white"/>
            </svg>
          </tc-button>
        </div>
    </div>

    <div *ngIf="confirmStatus === confirmStatusEnum.AlreadyBound" class="step-status">
      <div class="fns-bind-row">
        <div class="fns-bind-info " *ngIf="bindingError === 'SERVER_ERRORS.SELF_EMPLOYED_EXISTS_WITH_INN'">
          Похоже, ИНН, который Вы указали уже привязан к другому профилию на платформе. Это вы?
        </div>
        <div class="fns-bind-info " *ngIf="bindingError === 'SERVER_ERRORS.SELF_EMPLOYED_EXISTS_WITH_PHONE'">
          Похоже, что номер, который вы указали, уже привязан к другому профилю на платформе. Это вы?
        </div>
        <div class="fns-bind-help">
          <div class="inn">{{bindingError === 'SERVER_ERRORS.SELF_EMPLOYED_EXISTS_WITH_INN' ? bindForm.controls?.innValue?.value : bindForm.controls?.phoneValue?.value}}</div>
          <div class="clickable-link change-inn" (click)="initBindState()">
            {{bindingError === 'SERVER_ERRORS.SELF_EMPLOYED_EXISTS_WITH_PHONE' ? 'Изменить номер' : 'Изменить ИНН'}}
          </div>
        </div>
      </div>

      <div class="bounded-user">
        <div class="user d-flex align-items-center" *ngIf="boundedUser">
          <tpt-avatar size="36" class="mr-12" [textSizeRatio]="2.5" [user]="boundedUser"></tpt-avatar>
          <div class="user">
            <div class="user-name">{{boundedUser?.firstName}} {{boundedUser?.lastName}}</div>
            <div class="caption">Зарегистрирован {{boundedUser?.registerDate | localDate: 'dd MMMM yyyy'}}</div>
          </div>

        </div>
      </div>
      <div class="actions-row">
        <tc-button type="primary" size="x-large" class="full-width" (onClick)="restoreAccess(); closeDialog.emit()">
          Это я
        </tc-button>
        <tc-button type="tertiary" size="x-large" class="full-width"
                   (onClick)="createChatWithSupport(); closeDialog.emit()">Нет, это не я</tc-button>
      </div>

    </div>
  </div>

  <div class="step" *ngIf="step === 2">
    <div *ngIf="confirmStatus === confirmStatusEnum.PendingConfirm" class="step-status">
      <div class="fns-bind-info mb-16">
        Подтвердите права в приложении «Мой налог» или на сайте ФНС
      </div>

      <div class="">
        <div class="info">
          <div class="circle">1</div>
          <span>Перейдите в раздел <span class="green"> «Прочее»</span> → <span class="green">«Партнеры»</span></span>
        </div>
        <div class="info">
          <div class="circle">2</div>
          <span>Среди партнеров выберите <span class="green"> «Talent Cloud»</span></span>
        </div>
        <div class="info">
          <div class="circle">3</div>
          <span>Подвердите список предоставленных прав<br/>кнопкой <span class="green">«Подключить»</span></span>
        </div>
      </div>

      <div class="actions-row mt-40">
        <tc-button type="tertiary" size="x-large" class="full-width" (onClick)="closeDialog.emit()">Отмена</tc-button>
        <tc-button type="primary" size="x-large" class="full-width" (onClick)="checkIfRightsAvailable()">Я предоставил права
          <svg class="ml-8" width="16" height="12" viewBox="0 0 16 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M14.6875 1.3125C15.0938 1.6875 15.0938 2.34375 14.6875 2.71875L6.6875 10.7188C6.3125 11.125 5.65625 11.125 5.28125 10.7188L1.28125 6.71875C0.875 6.34375 0.875 5.6875 1.28125 5.3125C1.65625 4.90625 2.3125 4.90625 2.6875 5.3125L5.96875 8.59375L13.2812 1.3125C13.6562 0.90625 14.3125 0.90625 14.6875 1.3125Z" fill="white"/>
          </svg>
        </tc-button>
      </div>
    </div>

    <div *ngIf="confirmStatus === confirmStatusEnum.BindingRejectedOnFns" class="step-status">
      <div class="fns-bind-info mb-8">
        Вы отклонили заявку на выдачу прав
      </div>
      <div class="regular-14-20">
        Хотите отправить запрос повторно?
      </div>

      <div class="actions-row">
        <tc-button type="tertiary" size="x-large" class="full-width" (onClick)="rejectBinding()">Отклонить</tc-button>
        <tc-button type="primary" size="x-large" class="full-width" (onClick)="initBindState()">Привязать повторно</tc-button>
      </div>
    </div>

    <div *ngIf="confirmStatus === confirmStatusEnum.BindingRejectedOnTpt" class="step-status">

      <div class="regular-14-20 mt-24">
        Платформа Talent Cloud предназначена для работы с самозанятыми. Без привязки ФНС у вас будет ограниченный доступ — вы не сможете отправлять заявки на проект.
      </div>

      <div class="actions-row wide-buttons">
        <tc-button type="tertiary" size="x-large" class="full-width"
                   (onClick)="closeDialog.emit()"
                   [routerLink]="routeHelper.projectSearch">Ознакомиться с платформой</tc-button>
        <tc-button type="primary" size="x-large" class="full-width" (onClick)="initBindState()">
          Привязать профиль самозанятого
        </tc-button>
      </div>
    </div>
  </div>

  <div *ngIf="step === 3" class="step">
    <div class="step-status">
      <div class="fns-bind-info mb-8">
        Вы успешно прошли регистрацию и привязали аккаунт в ФНС
      </div>

      <div class="mb-8 regular-14-20">
        Для полного доступа вам необходимо заполнить паспортные данные
        и банковские реквизиты в разделе «Профиль»
      </div>

      <div class="actions-row mt-40">
        <tc-button type="tertiary" size="x-large" class="full-width"
                   (onClick)="closeDialog.emit()"
                   [routerLink]="routeHelper.projectSearch">К поиску проектов</tc-button>
        <tc-button type="primary" size="x-large" class="full-width" (onClick)="closeDialog.emit()"
                   [routerLink]="routeHelper.settingsLegalData">
          Заполнить паспортные данные
        </tc-button>
      </div>
    </div>
  </div>
</div>

<ng-template #loadTemplate>
  <ngx-skeleton-loader
    count="1"
    class=""
    animation="progress"
    [theme]="{display: 'flex',
          height: '300px',
          background: '#eff1f6'
        }"
  ></ngx-skeleton-loader>
</ng-template>

<tpt-binding-error-dialog></tpt-binding-error-dialog>
<tpt-access-recovery-dialog (openSupportChat)="createChatWithSupport()"></tpt-access-recovery-dialog>
