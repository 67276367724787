<mat-card (click)="openCardDetails(freelancer)" class="freelancer-card" [class.visited]="visited">
  <div class="first-row">
    <div class="d-flex">
      <tpt-avatar [textSizeRatio]="3"
                  [user]="freelancer"
                  size="48"
                  class="mr-8"></tpt-avatar>
      <div>
        <div *ngIf="freelancer?.selfEmployedStatus">
          <app-self-employed-status [status]="freelancer?.selfEmployedStatus"
                                    [bankDetailsValidated]="freelancer.permissions?.includes('BANK_DETAILS_VALIDATED')"
                                    [legalInfoValidated]="freelancer.permissions?.includes('LEGAL_INFO_VALIDATED')"
                                    [limitExceeded]="freelancer.permissions?.includes('ANNUAL_PAYMENTS_LIMIT_EXCEEDED')"
          ></app-self-employed-status>
        </div>
        <div class="fw-600 text-black mt-4 no-wrap">{{freelancer.firstName}} {{freelancer.lastName}}</div>
      </div>
    </div>
    <div class="button-block desktop">
      <tc-button type="tertiary" size="medium" class="more-horiz-btn"
                 #tooltip="matTooltip"
                 (onClick)="$event.stopPropagation(); copyProfilePageLink(freelancer, tooltip)"
                 matTooltip="Ссылка скопирована в буфер обмена"
                 matTooltipClass="custom-tooltip"
                 [matTooltipPosition]="'above'"
                 [matTooltipDisabled]="true"
                 [matTooltipShowDelay]="0"
      >
        <mat-icon [svgIcon]="svgIconsEnum.CopyLink"></mat-icon>
      </tc-button>
      <ng-container *ngIf="isEmployer">
        <tc-button class="hire-button" type="primary" size="medium"
                    [disabled]="disableBtn(freelancer)"
                    (onClick)="hireFreelancer(freelancer)"
                    (click)="$event.stopPropagation()"
        >
          <svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M14.9727 1.26172L13.2227 12.6094C13.1953 12.8828 13.0312 13.1289 12.7852 13.2656C12.6484 13.3203 12.5117 13.375 12.3477 13.375C12.2383 13.375 12.1289 13.3477 12.0195 13.293L8.68359 11.8984L7.28906 13.9766C7.17969 14.168 6.98828 14.25 6.79688 14.25C6.49609 14.25 6.25 14.0039 6.25 13.7031V11.0781C6.25 10.8594 6.30469 10.668 6.41406 10.5312L12.375 2.875L4.33594 10.1211L1.51953 8.94531C1.21875 8.80859 1 8.53516 1 8.17969C0.972656 7.79688 1.13672 7.52344 1.4375 7.35938L13.6875 0.386719C13.9609 0.222656 14.3438 0.222656 14.6172 0.414062C14.8906 0.605469 15.0273 0.933594 14.9727 1.26172Z"
              fill="white"/>
          </svg>

          {{'SEARCH.HIRE' | translate}}</tc-button>
        <tc-button type="alternative" size="medium"
                   *ngIf="filterChats().length"
                   (onClick)="$event.stopPropagation(); openChatsDialog()"
        ><mat-icon [svgIcon]="svgIconsEnum.Chat"></mat-icon>
          Перейти в чат
        </tc-button>
      </ng-container>
    </div>
  </div>
  <div class="second-row">
    <div class="description">{{freelancer?.description}}</div>

    <div class="categories desktop">
      <mat-tab-group mat-align-tabs="start" #matTabGroup disableRipple>
        <mat-tab *ngFor="let spec of freelancer.specializations; let i = index">
          <ng-template mat-tab-label>
            <div (click)="$event.stopPropagation(); matTabGroup.selectedIndex = i" class="px-20 pb-12">
              {{spec.subCategory?.id ? getTranslation(spec.subCategory) :
              spec.parentCategory?.id ? getTranslation(spec.parentCategory) : ('CONTRACTS.NOT_SPECIFIED' | translate)}}
            </div>
          </ng-template>
          <div class="category-row">
            <div class="d-flex align-items-center" *ngIf="spec.parentCategory">
              <mat-icon [svgIcon]="svgIconsEnum.CategorySmall" class="text-gray-600 mr-8 w-14"></mat-icon>
              <div class="fs-14 fw-500 text-black">{{getTranslation(spec.parentCategory)}}</div>
            </div>

            <div class="d-flex align-items-center" *ngIf="spec?.expertLevel">
              <mat-icon [svgIcon]="svgIconsEnum.Expert" class="text-gray-600 mr-4"></mat-icon>
              <div class="fs-14 fw-500 text-black">{{('SEARCH.LEVEL.' + spec?.expertLevel | translate)}}</div>
            </div>

            <div class="d-flex align-items-center" *ngIf="spec.hourlyRate">
              <mat-icon [svgIcon]="svgIconsEnum.Ruble" class="text-gray-600 mr-8 w-14"></mat-icon>
              <div class="fs-14 fw-500 text-black">{{spec.hourlyRate + '/' + ('FINANCE.HOUR' | translate)}}</div>
            </div>

            <div class="d-flex align-items-center" *ngIf="freelancer.country">
              <mat-icon [svgIcon]="svgIconsEnum.Location" class="text-gray-600 mr-8 w-14"></mat-icon>
              <div class="fs-14 fw-500 text-black">{{getTranslation(freelancer?.country)}}</div>
            </div>
          </div>

          <div class="skills-wrap">
            <tpt-chip class="chip" type="gray" size="small"
                      *ngFor="let skill of specSkills(spec)"
                      [class.filtered]="getChipStyle(skill)">
              {{getTranslation(skill) || skill}}
            </tpt-chip>
          </div>
        </mat-tab>
      </mat-tab-group>
    </div>

    <div class="category-row mobile" *ngIf="mainSpec(freelancer.specializations) as spec">
      <div class="d-flex align-items-center" *ngIf="spec.parentCategory">
        <mat-icon [svgIcon]="svgIconsEnum.CategorySmall" class="text-gray-600 w-14 mr-8"></mat-icon>
        <div class="fs-14 fw-500 text-black">{{getTranslation(spec.parentCategory)}}</div>
      </div>

      <div class="d-flex align-items-center" *ngIf="spec?.expertLevel">
        <mat-icon [svgIcon]="svgIconsEnum.Expert" class="text-gray-600 mr-4"></mat-icon>
        <div class="fs-14 fw-500 text-black">{{('SEARCH.LEVEL.' + spec?.expertLevel | translate)}}</div>
      </div>

      <div class="d-flex align-items-center" *ngIf="spec.hourlyRate">
        <mat-icon [svgIcon]="svgIconsEnum.Ruble" class="text-gray-600 mr-8 w-14"></mat-icon>
        <div class="fs-14 fw-500 text-black">{{spec.hourlyRate + '/' + ('FINANCE.HOUR' | translate)}}</div>
      </div>

      <div class="d-flex align-items-center" *ngIf="freelancer.country">
        <mat-icon [svgIcon]="svgIconsEnum.Location" class="text-gray-600 mr-8 w-14"></mat-icon>
        <div class="fs-14 fw-500 text-black">{{getTranslation(freelancer?.country)}}</div>
      </div>
    </div>

    <div class="button-block mobile">
      <tc-button type="tertiary" size="medium" class="more-horiz-btn" [class.full-width]="!isEmployer"
                 #tooltip1="matTooltip"
                 (onClick)="$event.stopPropagation(); copyProfilePageLink(freelancer, tooltip1)"
                 matTooltip="Ссылка скопирована в буфер обмена"
                 matTooltipClass="custom-tooltip"
                 [matTooltipPosition]="'above'"
                 [matTooltipDisabled]="true"
                 [matTooltipShowDelay]="0"
      >
        <mat-icon [svgIcon]="svgIconsEnum.CopyLink"></mat-icon>
        <ng-container *ngIf="!isEmployer">{{'SETTINGS.COPY_LINK' | translate}}</ng-container>
      </tc-button>
      <tc-button class="hire-button" type="primary" size="medium"
                 *ngIf="isEmployer"
                 [class.grow]="!filterChats().length"
                 [disabled]="disableBtn(freelancer)"
                 (onClick)="hireFreelancer(freelancer)"
                 (click)="$event.stopPropagation()"
      >
        <svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M14.9727 1.26172L13.2227 12.6094C13.1953 12.8828 13.0312 13.1289 12.7852 13.2656C12.6484 13.3203 12.5117 13.375 12.3477 13.375C12.2383 13.375 12.1289 13.3477 12.0195 13.293L8.68359 11.8984L7.28906 13.9766C7.17969 14.168 6.98828 14.25 6.79688 14.25C6.49609 14.25 6.25 14.0039 6.25 13.7031V11.0781C6.25 10.8594 6.30469 10.668 6.41406 10.5312L12.375 2.875L4.33594 10.1211L1.51953 8.94531C1.21875 8.80859 1 8.53516 1 8.17969C0.972656 7.79688 1.13672 7.52344 1.4375 7.35938L13.6875 0.386719C13.9609 0.222656 14.3438 0.222656 14.6172 0.414062C14.8906 0.605469 15.0273 0.933594 14.9727 1.26172Z"
            fill="white"/>
        </svg>
        <ng-container *ngIf="!filterChats().length">{{'SEARCH.HIRE' | translate}}</ng-container>
      </tc-button>

      <tc-button type="alternative" size="medium" class="go-to-chat"
                 *ngIf="filterChats().length && isEmployer"
                 (onClick)="$event.stopPropagation(); openChatsDialog()">
        <mat-icon [svgIcon]="svgIconsEnum.Chat"></mat-icon>
        Перейти в чат
      </tc-button>
    </div>

  </div>
</mat-card>

<hire-freelancer-dialog (successfullyHired)="blurCard($event)"></hire-freelancer-dialog>
<tpt-chats-dialog (openHireDialogEvent)="hireFreelancer()"></tpt-chats-dialog>
