<tpt-dialog>
  <div class="step-1" *ngIf="step===1">
    <div class="title">Новая выплата</div>
    <div class="cards-wrap">
      <div class="card" [class.active]="contractType === 'FRAMEWORK'" (click)="setContractType('FRAMEWORK')">
        <svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect width="44" height="44" rx="4" fill="#EFF7FF"/>
          <path d="M18 14H23V18C23 18.5625 23.4375 19 24 19H28V28C28 29.125 27.0938 30 26 30H18C16.875 30 16 29.125 16 28V16C16 14.9062 16.875 14 18 14ZM24 14L28 18H24V14ZM25.5312 22.7812H25.5C25.8125 22.5 25.8125 22.0312 25.5 21.7188C25.2188 21.4375 24.75 21.4375 24.4688 21.7188L20.9688 25.2188L19.5 23.75C19.2188 23.4375 18.75 23.4375 18.4688 23.75C18.1562 24.0312 18.1562 24.5 18.4688 24.7812L20.4688 26.7812C20.75 27.0938 21.2188 27.0938 21.5312 26.7812L25.5312 22.7812Z" fill="#2697FF"/>
        </svg>
        <div>
          <div class="card-name">Создать заказ к договору</div>
          <div class="card-text">К текущему договору с исполнителем будет составлен заказ и акт.</div>
        </div>
      </div>
      <div class="card" [class.active]="contractType === 'REGULAR'" (click)="setContractType('REGULAR')">
        <svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect width="44" height="44" rx="4" fill="#EFF7FF"/>
          <path d="M23 16.5V21H27.5C28.0312 21 28.5 21.4688 28.5 22C28.5 22.5625 28.0312 23 27.5 23H23V27.5C23 28.0625 22.5312 28.5 22 28.5C21.4375 28.5 21 28.0625 21 27.5V23H16.5C15.9375 23 15.5 22.5625 15.5 22C15.5 21.4688 15.9375 21 16.5 21H21V16.5C21 15.9688 21.4375 15.5 22 15.5C22.5312 15.5 23 15.9688 23 16.5Z" fill="#2697FF"/>
        </svg>
        <div>
          <div class="card-name">Создать новый договор</div>
          <div class="card-text">Будет создан новый договор с исполнителем и акт.</div>
        </div>
      </div>
    </div>

    <div class="actions-row">
      <tc-button type="tertiary" class="cancel" (click)="close()"
                  size="x-large">{{'BUTTON.CANCEL' | translate}}</tc-button>
      <tc-button type="primary" class="full-width" [disabled]="!contractType"
                  (click)="next()" size="x-large">{{'BUTTON.NEXT' | translate}}
        <svg class="ml-8" width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M14.25 7.5625L8.75 12.8125C8.4375 13.0938 7.96875 13.0938 7.6875 12.7812C7.40625 12.4688 7.40625 12 7.71875 11.7188L11.875 7.75H1.25C0.8125 7.75 0.5 7.4375 0.5 7C0.5 6.59375 0.8125 6.25 1.25 6.25H11.875L7.71875 2.3125C7.40625 2.03125 7.40625 1.53125 7.6875 1.25C7.96875 0.9375 8.46875 0.9375 8.75 1.21875L14.25 6.46875C14.4062 6.625 14.5 6.8125 14.5 7C14.5 7.21875 14.4062 7.40625 14.25 7.5625Z" fill="white"/>
        </svg>
      </tc-button>
    </div>
  </div>

  <div *ngIf="step === 2" class="step-2">
    <div class="title">{{editState ? 'Редактировать выплату' : 'Новая выплата'}}</div>

    <div class="selected-user mb-20">
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path class="mr-8"
              d="M19 21C19 17.134 15.866 14 12 14C8.13401 14 5 17.134 5 21M12 11C9.79086 11 8 9.20914 8 7C8 4.79086 9.79086 3 12 3C14.2091 3 16 4.79086 16 7C16 9.20914 14.2091 11 12 11Z"
              stroke="#A5A8AE" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
      </svg>

      <div class="select-label">Введите имя исполнителя <span class="red-asterisk">*</span></div>

      <div class="name ml-12">
        {{employee?.freelancer?.profile?.firstName}} {{employee?.freelancer?.profile?.lastName}}
      </div>

      <span class="foreigner" *ngIf="employee?.freelancer?.citizenship && employee?.freelancer?.citizenship !== 'RUSSIA'">
        Иностранный гражданин</span>
    </div>

    <div class="mb-40" *ngIf="form">
      <div class="mb-8 caption">
        Наименование услуги в чеке, который вы получите от самозанятого после выплаты вознаграждения
      </div>
      <tc-input [control]="form.jobName">
        <ng-container label>Название услуги <span class="red-asterisk">*</span></ng-container>
      </tc-input>

      <div>
        <div *ngIf="form.hasStartDate.value" class="range-datepicker">
          <tc-date-range-picker [start]="form.startDate"
                                [end]="form.deadline"
                                [comparisonStart]="getStartMinDate()"
                                [comparisonEnd]="getDeadlineMaxDate()"
                                [icon]="svgIconsEnum.DatepickerCalendar">
            <ng-container label>Дата выполнения проекта <span class="red-asterisk">*</span></ng-container>
          </tc-date-range-picker>
        </div>
        <div *ngIf="!form.hasStartDate.value">
          <tc-datepicker [control]="form.deadline"
                         [icon]="svgIconsEnum.DatepickerCalendar"
                         [min]="getDeadlineMaxDate()">
            <ng-container label>Дата выполнения проекта <span class="red-asterisk">*</span></ng-container>
          </tc-datepicker>
        </div>

        <mat-checkbox [formControl]="form.hasStartDate"
                      color="primary"
                      labelPosition="after">Выбрать дату старта
        </mat-checkbox>
      </div>

      <div>
        <div class="tasks-wrapper">
          <div class="empty-tasks-list" *ngIf="!form.tasks.value.length">
            <svg width="46" height="46" viewBox="0 0 46 46" fill="none" xmlns="http://www.w3.org/2000/svg">
              <rect x="3" y="3" width="40" height="40" rx="20" fill="#F2F4F7"/>
              <path
                d="M18.2148 19.9336C18.1055 20.043 17.9414 20.125 17.75 20.125C17.5859 20.1523 17.3945 20.0703 17.2852 19.9336L16.1914 18.8398C15.918 18.5938 15.918 18.1836 16.1914 17.9375C16.4375 17.6641 16.8477 17.6641 17.0938 17.9375L17.7227 18.5391L19.2266 16.8438C19.4727 16.5977 19.8828 16.5703 20.1562 16.8164C20.4023 17.0625 20.4297 17.4727 20.1836 17.7461L18.2148 19.9336ZM18.2148 24.3086C18.1055 24.418 17.9414 24.5 17.75 24.5C17.5859 24.5273 17.3945 24.4453 17.2852 24.3086L16.1914 23.2148C15.918 22.9688 15.918 22.5586 16.1914 22.2852C16.4375 22.0391 16.8477 22.0391 17.0938 22.2852L17.7227 22.9141L19.2266 21.2188C19.4727 20.9727 19.8828 20.9453 20.1562 21.1914C20.4023 21.4102 20.4297 21.8477 20.1836 22.1211L18.2148 24.3086ZM16 27.125C16 26.4141 16.5742 25.8125 17.3125 25.8125C18.0234 25.8125 18.625 26.4141 18.625 27.125C18.625 27.8633 18.0234 28.4375 17.3125 28.4375C16.5742 28.4375 16 27.8633 16 27.125Z"
                fill="#0F1115"/>
              <path
                d="M21.25 26.25H29.125C29.5898 26.25 30 26.6602 30 27.125C30 27.6172 29.5898 28 29.125 28H21.25C20.7578 28 20.375 27.6172 20.375 27.125C20.375 26.6602 20.7578 26.25 21.25 26.25ZM23 21.875H29.125C29.5898 21.875 30 22.2852 30 22.75C30 23.2422 29.5898 23.625 29.125 23.625H23C22.5078 23.625 22.125 23.2422 22.125 22.75C22.125 22.2852 22.5078 21.875 23 21.875ZM23 19.25C22.5078 19.25 22.125 18.8672 22.125 18.375C22.125 17.9102 22.5078 17.5 23 17.5H29.125C29.5898 17.5 30 17.9102 30 18.375C30 18.8672 29.5898 19.25 29.125 19.25H23Z"
                fill="#707D99"/>
              <rect x="3" y="3" width="40" height="40" rx="20" stroke="#F9FAFB" stroke-width="6"/>
            </svg>

            <div class="mb-12">Список задач пуст</div>
          </div>

          <div *ngFor="let task of form?.tasks?.value; let taskIndex = index; let lst = last; let fst = first">
            <div class="task-wrapper" [ngStyle]="{'padding-top': fst ? '0' : '12px'}"
                 (click)="openTaskSection({task: task, edit: true, index: taskIndex})">
              <div class="">
                <div class="task-title">{{task?.taskTitle}}</div>
                <div class="regular-14-20">
                  {{task?.startDate ? (task?.startDate | localDate: 'dd.MM.yyyy') + '-' : ''}}
                  {{task?.deadline | localDate: 'dd.MM.yyyy'}}
                </div>
              </div>
              <button mat-icon-button (click)="$event.stopPropagation(); removeTask(taskIndex)">
                <mat-icon [svgIcon]="svgIconsEnum.Trash2"></mat-icon>
              </button>
            </div>
            <mat-divider *ngIf="!lst"></mat-divider>
          </div>

          <div class="add-new-task mt-12">
            <tc-button (onClick)="openTaskSection({edit: false})"
                        type="tertiary" size="medium" class="full-width">
              <svg class="mr-8" width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M7 0.9375V4.875H10.9375C11.4023 4.875 11.8125 5.28516 11.8125 5.75C11.8125 6.24219 11.4023 6.625 10.9375 6.625H7V10.5625C7 11.0547 6.58984 11.4375 6.125 11.4375C5.63281 11.4375 5.25 11.0547 5.25 10.5625V6.625H1.3125C0.820312 6.625 0.4375 6.24219 0.4375 5.75C0.4375 5.28516 0.820312 4.875 1.3125 4.875H5.25V0.9375C5.25 0.472656 5.63281 0.0625 6.125 0.0625C6.58984 0.0625 7 0.472656 7 0.9375Z" fill="#0F1115"/>
              </svg>
              Добавить задачу
            </tc-button>
          </div>
        </div>
      </div>

      <tc-input [control]="form.budget"
                [icon]="svgIconsEnum.PriceTag"
                type="text"
                [prefix]="form.budget.value ? '₽ ' : ''"
                mask="separator"
      >
        <ng-container label>Вознаграждение исполнителю <span class="red-asterisk">*</span></ng-container>
        <ng-container hint>
          <mat-hint [align]="'end'" *ngIf="!errorState(form.budget)">
            От 1000 ₽ до {{maxValue | number}} ₽
          </mat-hint>
        </ng-container>
      </tc-input>
    </div>

    <div class="row-wrapper">
      <div class="commission-row" *ngIf="currentJobCommission > 0">
        <div
          class="label" [innerHTML]="'CREATE_PROJECT.STEP4.COMMISSION_VAR' | translate : {commission: currentJobCommission}"></div>
        <div class="value">{{(commission || 0).toFixed(2) | number}} ₽</div>
      </div>
      <div class="total-row">
        <div>{{'CREATE_PROJECT.STEP4.TOTAL' | translate}}</div>
        <div>{{(+totalBudget || 0).toFixed(2) | number}} ₽</div>
      </div>
    </div>
    <div class="actions-row mt-32">
      <tc-button type="tertiary" size="x-large" (onClick)="close()">
        {{'BUTTON.CANCEL' | translate}}
      </tc-button>
      <tc-button *ngIf="!editState" type="primary" size="x-large" (onClick)="createPayment()" [disabled]="loading">
        <ng-container *ngIf="!loading; else spinner">
          Создать выплату
        </ng-container>
        <ng-template #spinner>
          <mat-spinner [diameter]="24" color="accent"></mat-spinner>
        </ng-template>
      </tc-button>

      <tc-button *ngIf="editState" type="primary" size="x-large" (onClick)="saveChanges()">Подтвердить изменения</tc-button>
    </div>
  </div>

  <div *ngIf="step === 3" class="step-3">
    <div class="back" (click)="cancelAddTask()">
      <mat-icon [svgIcon]="svgIconsEnum.ArrowBack" class="mr-8" ></mat-icon>
      Назад
    </div>
    <div class="d-flex align-items-center add-task">
      {{editTaskState ? 'Редактирование задачи' : 'Создание задачи'}}
    </div>

    <tc-input [control]="tasksForm.get('taskTitle')">
      <ng-container label>Название <span class="red-asterisk">*</span></ng-container>
    </tc-input>

    <tc-textarea [control]="tasksForm.get('description')">
      <ng-container label>Описание</ng-container>
    </tc-textarea>

    <div>
      <div *ngIf="tasksForm.get('hasStartDate').value">
        <tc-date-range-picker [start]="tasksForm.get('startDate')"
                              [end]="tasksForm.get('deadline')"
                              [icon]="svgIconsEnum.DatepickerCalendar">
          <ng-container label>Дата выполнения задачи <span class="red-asterisk">*</span></ng-container>
        </tc-date-range-picker>
      </div>
      <div *ngIf="!tasksForm.get('hasStartDate').value">
        <tc-datepicker [control]="tasksForm.get('deadline')"
                       [icon]="svgIconsEnum.DatepickerCalendar"
                       [min]="getDeadlineMaxDate()">
          <ng-container label>Дата выполнения задачи <span class="red-asterisk">*</span></ng-container>
        </tc-datepicker>
      </div>

      <mat-checkbox [formControl]="tasksForm.get('hasStartDate')"
                    (change)="datepickerChange($event)"
                    color="primary"
                    labelPosition="after">Выбрать дату старта
      </mat-checkbox>
    </div>

    <div class="deadline-info" *ngIf="getTipText()">
      <mat-icon class="info-icon" [svgIcon]="svgIconsEnum.InfoIcon"></mat-icon>
      <div>
        {{getTipText()}}
      </div>
    </div>

    <div class="actions-row mt-32">
      <tc-button type="tertiary" size="x-large" (onClick)="close()">
        {{'BUTTON.CANCEL' | translate}}
      </tc-button>
      <tc-button type="primary" size="x-large" (onClick)="saveTask()">
        {{editTaskState ? 'Сохранить' : 'Добавить'}}
      </tc-button>
    </div>
  </div>
</tpt-dialog>

<app-warning-dialog></app-warning-dialog>
