import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialogConfig } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import * as moment from 'moment-timezone';

import { DialogComponent } from '@/modules/dialog/components/dialog/dialog.component';
import { ContractsService } from '@/modules/contracts/services/contracts.service';
import { SvgIconsEnum } from '@/types/svg-icons.enum';
import { EmployerLegalStatusEnum } from '@/models/legalStatuses.enum';
import { Language } from '@/models/language';
import { ProfileModel } from '@/models/profile.model';
import { ProfilesApiService } from '@/services/profiles.api.service';
import { LanguageService } from '@/services/language.service';

@Component({
  selector: 'tpt-employer-info-dialog',
  templateUrl: './employer-info-dialog.component.html',
  styleUrls: ['./../common-info-dialog-styles.scss']
})
export class EmployerInfoDialogComponent implements OnInit {

  @ViewChild(DialogComponent)
  public dialog: DialogComponent;

  public svgIconsEnum = SvgIconsEnum;

  public employer: ProfileModel;

  public reviews$: Observable<any>;

  public isContractPage: boolean;

  public employerLegalStatusEnum = EmployerLegalStatusEnum;

  public languagesArray: Array<Language> = [];

  private config: MatDialogConfig = {
    width: '520px',
    height: '620px'
  };

  private className = 'tpt-employer-info-dialog';

  constructor(private translate: TranslateService,
              private contractsService: ContractsService,
              private languageService: LanguageService,
              private profileApiService: ProfilesApiService) {
  }

  ngOnInit() {
    this.languageService.getLanguagesV2().subscribe((langs: Language[]) => this.languagesArray = langs);
  }

  public open(employer, offset, isContractPage): void {
    if (offset) {
      this.config.panelClass = 'chat-user-info-align';
    }
    this.dialog.config = this.config;
    this.dialog.open(this.className);
    this.employer = employer;
    this.isContractPage = isContractPage;

    // this.getReviews(employer.id);
  }

  public close(): void {
    this.dialog.close();
  }

  public getTime(timeZone): string {
    if (timeZone === 'Z') {
      return;
    }
    return moment().tz(timeZone).format('HH:mm');
  }

  public getTranslation(item): string {
    if (this.translate.currentLang === 'ru') {
      return item.nameRu;
    }
    return item.name;
  }

  public getReviews(id): void {
    this.reviews$ = this.profileApiService.getUserReviews(id);
  }

  public getLangByCode(lang): string {
    const language = this.languagesArray?.find(item => item.code === lang.code);
    return this.getTranslation(language);
  }
}
