<tpt-dialog>
  <ng-container *ngIf="freelancer">
    <mat-icon [svgIcon]="svgIconsEnum.MoreHoriz" class="cross-icon"
              [matMenuTriggerFor]="menu">
    </mat-icon>

    <mat-menu
      class="tpt-more-menu"
      #menu="matMenu"
      xPosition="before"
    >
      <button
        mat-menu-item
        (click)="openProfilePage()"
      >
        {{'SETTINGS.USER_PROFILE' | translate}}
      </button>
    </mat-menu>

    <div class="header-row">
      <tpt-avatar size="56" [user]="freelancer"></tpt-avatar>

      <div>
        <div class="full-name mb-4">{{ freelancer.firstName }} {{ freelancer.lastName }}</div>

        <div class="legal-category-row">
          <div class="mr-12" *ngIf="mainSpec">
            {{getTranslation(mainSpec?.parentCategory) }}
          </div>

          <div class="legal-status" *ngIf="freelancer?.selfEmployedStatus">
            <ng-container *ngIf="freelancer?.selfEmployedStatus === 'ACTIVE' &&
              freelancer.permissions?.includes('BANK_DETAILS_VALIDATED') && freelancer.permissions?.includes('LEGAL_INFO_VALIDATED') && !freelancer.permissions?.includes('ANNUAL_PAYMENTS_LIMIT_EXCEEDED')">
              Самозанятый
              <mat-icon [svgIcon]="svgIconsEnum.CheckSuccess"
                        class="small-icon"></mat-icon>
            </ng-container>

            <ng-container *ngIf="['NEW', 'NOT_VERIFIED'].includes(freelancer?.selfEmployedStatus) ||
              (freelancer?.selfEmployedStatus === 'ACTIVE' && (!freelancer.permissions?.includes('BANK_DETAILS_VALIDATED') || !freelancer.permissions?.includes('LEGAL_INFO_VALIDATED') || freelancer.permissions?.includes('ANNUAL_PAYMENTS_LIMIT_EXCEEDED')))">
              Ограниченный доступ
              <mat-icon [svgIcon]=" svgIconsEnum.Deregistered"
                        class="small-icon"></mat-icon>
            </ng-container>

            <ng-container *ngIf="freelancer?.selfEmployedStatus === 'DETACHED'">
              Отвязан от платформы
              <mat-icon [svgIcon]=" svgIconsEnum.Deregistered"
                        class="small-icon"></mat-icon>
            </ng-container>

            <ng-container *ngIf="freelancer?.selfEmployedStatus === 'REMOVED_FROM_REGISTER'">
              Снят с учёта
              <mat-icon [svgIcon]=" svgIconsEnum.Deregistered"
                        class="small-icon"></mat-icon>
            </ng-container>
          </div>
        </div>

        <span class="freelancer-rate regular-14-20 mr-24">
          {{ mainSpec?.hourlyRate ? '₽' + mainSpec?.hourlyRate + '/' + ('FINANCE.HOUR' | translate) : '-' }}
        </span>

<!--        <span class="caption freelancer-rating">{{'SETTINGS.RATING' | translate}} {{ freelancer.rating | percent }}</span>-->
      </div>
    </div>

    <mat-tab-group>
      <mat-tab label="{{'CONTRACTS.ABOUT_FREELANCER' | translate}}">
        <div class="about-container">
          <div class="subhead-1 mb-4 mt-16">{{'CONTRACTS.ABOUT' | translate}}</div>
          <div class="description regular-14-20" *ngIf="freelancer?.description; else emptyTemplate">
            {{ freelancer.description }}
          </div>

          <div class="about-grid mt-16">
            <div>
              <div class="subhead-1 mb-4 section-title">{{'CONTRACTS.LINKS' | translate}}</div>
              <div class="section-content" *ngIf="freelancer?.links?.length; else emptyTemplate">
                <a *ngFor="let link of freelancer?.links" [href]="link" target="_blank"
                   class="contact-link">{{link}}</a>
              </div>
            </div>
            <div>
              <div class="subhead-1 mb-4">{{'SETTINGS.LOCATION' | translate}}</div>
              <div class="location" *ngIf="freelancer.country">
                <mat-icon [svgIcon]="svgIconsEnum.Location" class="location-icon"></mat-icon>
                {{ getTranslation(freelancer.country) }}
              </div>
              <div *ngIf="freelancer?.timeZone"
                class="local-time">{{'CONTRACTS.LOCAL_TIME' | translate}} {{ getTime(freelancer.timeZone)}}</div>
            </div>
          </div>

        </div>

      </mat-tab>
      <mat-tab label="{{'CONTRACTS.SKILLS_FREELANCER' | translate}}">
        <div class="about-container">
          <div class="subhead-1 mb-4 mt-16">{{'SETTINGS.SPECIALIZATION' | translate}}</div>
          <ng-container *ngIf="mainSpec || mainSpec?.expertLevel; else emptyTemplate">
            <div class="regular mb-8 specialisation">{{getTranslation(mainSpec?.parentCategory)}}</div>
            <div class="regular specialisation" *ngIf="mainSpec?.expertLevel">
              {{'CONTRACTS.EXPERT_LEVEL' | translate}} - {{ 'SEARCH.LEVEL.' + mainSpec?.expertLevel | translate}}
            </div>
          </ng-container>

          <div class="subhead-1 mb-4 mt-16">{{'CONTRACTS.SKILLS' | translate}}</div>
          <div class="freelancer-about mb-12">
            <div class="pseudo-chips" *ngIf="getAllSkills().length; else emptyTemplate">
              <tpt-chip class="chip mr-8 mb-8" type="outline" size="small"
                        *ngFor="let skill of getAllSkills()">
                <span>{{getTranslation(skill) || skill}}</span>
              </tpt-chip>
            </div>
          </div>

          <div>
            <div class="subhead-1 mb-4">{{'CONTRACTS.LANGUAGES' | translate}}</div>
            <ng-container *ngIf="freelancer?.languages?.length; else emptyTemplate">
              <div
                class="mb-8"
                *ngFor="let language of freelancer.languages"
              >
                <span class="key regular">{{getLangByCode(language)}}:</span>
                <span class="value regular" *ngIf="language.level">
                {{ 'CREATE_PROJECT.STEP3.' + language.level | translate }}</span>
              </div>
            </ng-container>
          </div>
        </div>
      </mat-tab>
<!--      <mat-tab label="{{'CONTRACTS.REVIEWS' | translate}}">-->
<!--        <div class="reviews">-->
<!--          <ng-container *ngIf="(reviews$ | async)?.length; else emptyReviews">-->
<!--            <div *ngFor="let review of reviews$ | async" class="card-wrapper">-->
<!--              <tpt-review-card [feedback]="review" (clickReview)="openProfilePage()"></tpt-review-card>-->
<!--            </div>-->
<!--          </ng-container>-->
<!--          <ng-template #emptyReviews>-->
<!--            <div class="no-reviews">-->
<!--              <img src="assets/images/empty-feedbacks.png" class="empty-feedbacks">-->

<!--              <div class="title">{{'CONTRACTS.NO_REVIEWS' | translate}}</div>-->
<!--              <div class="text">{{'CONTRACTS.NO_REVIEWS_FREEL' | translate}}</div>-->
<!--            </div>-->
<!--          </ng-template>-->
<!--        </div>-->
<!--      </mat-tab>-->
    </mat-tab-group>
  </ng-container>

  <ng-template #emptyTemplate>
    <span class="not-specified">{{'CONTRACTS.NOT_SPECIFIED' | translate}}</span>
  </ng-template>
</tpt-dialog>
