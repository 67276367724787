import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { isINN, isINNIndividual, isINNLegalEntity } from '@utkonos/entrepreneur';

export function innValidator(type: string): ValidatorFn {

  return (control: AbstractControl): ValidationErrors | null => {
    const value = control.value;
    let isInn;
    switch (type) {
      case 'IE':
        isInn = isINNIndividual(value);
        break;
      case 'LE':
        isInn = isINNLegalEntity(value);
        break;
      case 'INN':
        isInn = isINN(value);
        break;
      default: isInn = true;
    }

    return isInn ? null : {
      errorCode: 'wrongInn',
    };
  };
}
