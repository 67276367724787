import { ChangeDetectorRef, Component, EventEmitter, Input, NgZone, Output } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';

import { NotificationV2 } from '../../../models/notification';
import { NotificationType } from '../../../classes/enums';
import { RouteHelper } from '../../../helpers/route.helper';
import { ProfileService } from '../../../services/profile.service';
import { SvgIconsEnum } from '../../../types/svg-icons.enum';
import { NotificationService } from '../../../services/notification.service';
import { CurrencySymbol } from '../../../models/currency.enum';

@Component({
  selector: 'tpt-notification-content',
  templateUrl: './notification-content.component.html',
  styleUrls: ['./notification-content.component.scss'],
})

export class NotificationContentComponent {
  @Input() public notifications: NotificationV2[];

  @Output() public clear = new EventEmitter();

  @Output() public ack: EventEmitter<NotificationV2> = new EventEmitter<NotificationV2>();

  @Output() public delete: EventEmitter<NotificationV2> = new EventEmitter<NotificationV2>();

  @Output() public close: EventEmitter<void> = new EventEmitter<void>();

  @Output() public markAllAsReadEvent: EventEmitter<void> = new EventEmitter<void>();

  public svgIconsEnum = SvgIconsEnum;

  public redirectToFinance: string[] = [
    NotificationType.EMPLOYER_MILESTONE_INSUFFICIENT_FUNDS,
    NotificationType.EMPLOYER_PROJECT_INSUFFICIENT_FUNDS,
    NotificationType.FREELANCER_GOT_PAID,
    NotificationType.EMPLOYER_JOB_REFUND_DOCS_READY,
    NotificationType.EMPLOYER_JOB_REFUND_COMMISSION_PAID,
    NotificationType.EMPLOYER_JOB_INSUFFICIENT_FUNDS,
  ];

  public redirectToFinanceHistory: string[] = [
    NotificationType.EMPLOYER_RUBLE_BALANCE_REPLENISHED,
    NotificationType.EMPLOYER_REWARD_PAID,
    NotificationType.FREELANCER_REWARD_PAID_WITH_TAX,
    NotificationType.FREELANCER_REWARD_PAID,
    NotificationType.EMPLOYER_JOB_RECEIPT_READY,
    NotificationType.FREELANCER_JOB_RECEIPT_READY,
  ];

  public redirectToChatsArray: string[] = [
    NotificationType.EMPLOYER_EMPLOYER_ACCEPTED,
    NotificationType.EMPLOYER_FREELANCER_ACCEPTED,
    NotificationType.FREELANCER_FREELANCER_ACCEPTED,
    NotificationType.FREELANCER_EMPLOYER_ACCEPTED,
    NotificationType.EMPLOYER_EMPLOYER_ACCEPTED_CHANGES,
    NotificationType.EMPLOYER_FREELANCER_ACCEPTED_CHANGES,
    NotificationType.FREELANCER_EMPLOYER_ACCEPTED_CHANGES,
    NotificationType.FREELANCER_FREELANCER_ACCEPTED_CHANGES,
    NotificationType.EMPLOYER_RUBLE_NEED_PAY_AN_INVOICE,
    NotificationType.EMPLOYER_RUBLE_NEED_CREATE_AN_INVOICE,
    NotificationType.FREELANCER_JOB_RECEIPT_ERROR_TAXPAYER_UNREGISTERED,
    NotificationType.EMPLOYER_JOB_RECEIPT_ERROR_TAXPAYER_UNREGISTERED,
    NotificationType.FREELANCER_JOB_RECEIPT_ERROR_TAXPAYER_UNBOUND,
    NotificationType.EMPLOYER_JOB_RECEIPT_ERROR_TAXPAYER_UNBOUND,
    NotificationType.FREELANCER_JOB_PAYMENT_ERROR_TAXPAYER_UNBOUND,
    NotificationType.FREELANCER_JOB_PAYMENT_ERROR_TAXPAYER_UNREGISTERED,
    NotificationType.EMPLOYER_JOB_PAYMENT_ERROR_TAXPAYER_UNBOUND,
    NotificationType.EMPLOYER_JOB_PAYMENT_ERROR_TAXPAYER_UNREGISTERED,
    NotificationType.EMPLOYER_JOB_REFUND_TO_EMPLOYER,
    NotificationType.EMPLOYER_JOB_CONTRACT_PREPARING,
    NotificationType.FREELANCER_JOB_CONTRACT_PREPARING,
    NotificationType.FREELANCER_JOB_CONTRACT_READY,
    NotificationType.EMPLOYER_JOB_CONTRACT_READY,
    NotificationType.FREELANCER_JOB_CONTRACT_SIGNED_BY_EMPLOYER,
    NotificationType.EMPLOYER_JOB_CONTRACT_SIGNED_BY_EMPLOYER,
    NotificationType.EMPLOYER_JOB_CONTRACT_SIGNING_BY_EMPLOYER_EXPIRES,
    NotificationType.FREELANCER_JOB_CONTRACT_SIGNING_BY_FREELANCER_EXPIRES,
    NotificationType.FREELANCER_JOB_CONTRACT_SIGNED_BY_FREELANCER,
    NotificationType.EMPLOYER_JOB_CONTRACT_SIGNED_BY_FREELANCER,
    NotificationType.FREELANCER_JOB_CONTRACT_CHANGED_BY_FREELANCER,
    NotificationType.EMPLOYER_JOB_CONTRACT_CHANGED_BY_FREELANCER,
    NotificationType.FREELANCER_JOB_CONTRACT_CHANGED_BY_EMPLOYER,
    NotificationType.EMPLOYER_JOB_CONTRACT_CHANGED_BY_EMPLOYER,
    NotificationType.EMPLOYER_JOB_MAKE_ADVANCE_PAYMENT,
    NotificationType.FREELANCER_JOB_AWAIT_PREPAYMENT,
    NotificationType.FREELANCER_JOB_ADVANCE_PAYMENT_MADE,
    NotificationType.EMPLOYER_JOB_ADVANCE_PAYMENT_MADE,
    NotificationType.EMPLOYER_JOB_INVOICE_IS_BEING_PREPARED,
    NotificationType.EMPLOYER_JOB_PAYMENT_DEADLINE_EXPIRES_7_DAYS,
    NotificationType.EMPLOYER_JOB_PAYMENT_DEADLINE_EXPIRES_24_HOURS,
    NotificationType.EMPLOYER_JOB_INVOICE_SAVED,
    NotificationType.EMPLOYER_JOB_FINISHED_AUTOMATICALLY,
    NotificationType.FREELANCER_JOB_FINISHED_AUTOMATICALLY,
    NotificationType.EMPLOYER_JOB_FINISHED,
    NotificationType.FREELANCER_JOB_FINISHED,
    NotificationType.FREELANCER_JOB_FREELANCER_WCC_ONE_DAY_LEFT_TO_SIGN,
    NotificationType.EMPLOYER_JOB_FREELANCER_DID_NOT_SIGN_WCC,
    NotificationType.FREELANCER_JOB_FREELANCER_DID_NOT_SIGN_WCC,
    NotificationType.EMPLOYER_JOB_FREELANCER_SIGNED_WORK_COMPLETION_CERTIFICATE,
    NotificationType.FREELANCER_JOB_FREELANCER_SIGNED_WORK_COMPLETION_CERTIFICATE,
    NotificationType.EMPLOYER_JOB_ORDER_PREPARING,
    NotificationType.FREELANCER_JOB_ORDER_PREPARING,
    NotificationType.EMPLOYER_JOB_ORDER_READY,
    NotificationType.FREELANCER_JOB_ORDER_READY,
    NotificationType.EMPLOYER_JOB_ORDER_SIGNED_BY_EMPLOYER,
    NotificationType.FREELANCER_JOB_ORDER_SIGNED_BY_EMPLOYER,
    NotificationType.EMPLOYER_JOB_ORDER_SIGNED_BY_FREELANCER,
    NotificationType.FREELANCER_JOB_ORDER_SIGNED_BY_FREELANCER,
    NotificationType.EMPLOYER_JOB_ORDER_SIGNING_BY_EMPLOYER_EXPIRES,
    NotificationType.FREELANCER_JOB_ORDER_SIGNING_BY_FREELANCER_EXPIRES,
    NotificationType.EMPLOYER_JOB_RECEIPT_CANCELED,
    NotificationType.FREELANCER_JOB_RECEIPT_CANCELED,
    NotificationType.FREELANCER_JOB_DELETED,
    NotificationType.FREELANCER_JOB_ANOTHER_FREELANCER_HIRED,
    NotificationType.FREELANCER_JOB_WITHDRAWN,
    NotificationType.EMPLOYER_MVD_NEW_JOB,
    NotificationType.EMPLOYER_MVD_JOB_END_DATE,
    NotificationType.EMPLOYER_MVD_JOB_FINISHED_EARLIER,
    NotificationType.EMPLOYER_JOB_PAYROLL_CANCELED_AFTER_SIGN,
    NotificationType.FREELANCER_JOB_PAYROLL_CANCELED_AFTER_SIGN,
    NotificationType.EMPLOYER_JOB_PAYROLL_CANCELED,
    NotificationType.EMPLOYER_JOB_PAYROLL_CONTRACT_CHANGED_BY_EMPLOYER,
    NotificationType.EMPLOYER_JOB_PAYROLL_ORDER_CHANGED_BY_EMPLOYER,
    NotificationType.FREELANCER_JOB_PAYMENT_LIMIT_EXCEEDED,
    NotificationType.EMPLOYER_JOB_PAYMENT_LIMIT_EXCEEDED,
    NotificationType.EMPLOYER_JOB_CHARGEBACK,
    NotificationType.FREELANCER_JOB_CHARGEBACK,
  ];

  public redirectToProposalsArray: string[] = [
    NotificationType.FREELANCER_FREELANCER_INVITED,
    NotificationType.EMPLOYER_FREELANCER_PROPOSAL,
    NotificationType.EMPLOYER_EMPLOYER_CHANGE_PROJECT,
    NotificationType.FREELANCER_EMPLOYER_CHANGE_PROJECT,
    NotificationType.FREELANCER_FREELANCER_CHANGE_PROJECT,
    NotificationType.FREELANCER_EMPLOYER_DECLINED_CHANGES,
    NotificationType.FREELANCER_FREELANCER_DECLINED_CHANGES,
    NotificationType.EMPLOYER_FREELANCER_CHANGE_PROJECT,
    NotificationType.EMPLOYER_EMPLOYER_DECLINED_CHANGES,
    NotificationType.EMPLOYER_FREELANCER_DECLINED_CHANGES,
  ];

  public redirectToTaskTracker: string[] = [
    NotificationType.EMPLOYER_FREELANCER_OPEN_DISPUTE,
    NotificationType.FREELANCER_FREELANCER_OPEN_DISPUTE,
    NotificationType.EMPLOYER_EMPLOYER_OPEN_DISPUTE,
    NotificationType.FREELANCER_EMPLOYER_OPEN_DISPUTE,
  ];

  public redirectFinanceDetails: string[] = [
    NotificationType.CARD_ATTACHED,
    NotificationType.CARD_DETACHED,
    NotificationType.PAYONEER_DETACHED,
  ];

  public redirectToProjects: string[] = [
    NotificationType.FREELANCER_EMPLOYER_ACCEPTED_ALL_TASKS,
    NotificationType.EMPLOYER_EMPLOYER_ACCEPTED_ALL_TASKS
  ];

  public redirectToPassportPage: string[] = [
    NotificationType.FREELANCER_INVALID_PASSPORT_DATA_FNS,
  ];

  public redirectToSettingsPage: string[] = [
    NotificationType.FREELANCER_REQUIRED_FIELDS_ARE_NOT_FILLED_7_DAYS,
    NotificationType.EMPLOYER_REQUIRED_FIELDS_ARE_NOT_FILLED_7_DAYS,
  ];

  public redirectToMassPaymentPage: string[] = [
    NotificationType.FREELANCER_MASS_PAYMENT_LIMIT_EXCEEDED,
    NotificationType.EMPLOYER_MASS_PAYMENT_LIMIT_EXCEEDED,
    NotificationType.FREELANCER_MASS_PAYMENT_CHARGEBACK,
    NotificationType.EMPLOYER_MASS_PAYMENT_CHARGEBACK,
  ];

  public redirectToMassPaymentPageOrChat: string[] = [
    NotificationType.EMPLOYER_CHARGEBACK_RECEIPT_CANCELED,
    NotificationType.FREELANCER_CHARGEBACK_RECEIPT_CANCELED,
  ];

  public redirectToProjectPage: string[] = [
    NotificationType.EMPLOYER_FREELANCER_LEFT_COMMENT,
    NotificationType.EMPLOYER_FREELANCER_TASK_STATUS_REVIEW,
    NotificationType.EMPLOYER_FREELANCER_TASK_STATUS_TO_DO,
    NotificationType.EMPLOYER_FREELANCER_TASK_STATUS_IN_PROGRESS,
    NotificationType.EMPLOYER_FREELANCER_REWORK_TASK,
    NotificationType.EMPLOYER_FREELANCER_STARTED_TASK,
    NotificationType.EMPLOYER_FREELANCER_DECLINED_TASK,
    NotificationType.FREELANCER_EMPLOYER_LEFT_COMMENT,
    NotificationType.FREELANCER_EMPLOYER_ACCEPTED_TASK,
    NotificationType.FREELANCER_EMPLOYER_DECLINED_TASK,
    NotificationType.FREELANCER_EMPLOYER_CREATED_TASK,
    NotificationType.FREELANCER_EMPLOYER_EDITED_TASK,
    NotificationType.EMPLOYER_REVIEW_DEADLINE_IS_SOON,
    NotificationType.EMPLOYER_AUTO_ACCEPT,
    NotificationType.EMPLOYER_FREELANCER_STARTED_ARBITRAGE,
    NotificationType.FREELANCER_EMPLOYER_STARTED_ARBITRAGE,
    NotificationType.EMPLOYER_JOB_CONTRACT_SIGN_EXPIRED_BY_EMPLOYER,
    NotificationType.FREELANCER_JOB_CONTRACT_SIGN_EXPIRED_BY_EMPLOYER,
    NotificationType.EMPLOYER_JOB_CONTRACT_SIGN_EXPIRED_BY_FREELANCER,
    NotificationType.FREELANCER_JOB_CONTRACT_SIGN_EXPIRED_BY_FREELANCER,
    NotificationType.FREELANCER_JOB_ORDER_SIGN_EXPIRED_BY_FREELANCER,
    NotificationType.EMPLOYER_JOB_ORDER_SIGN_EXPIRED_BY_EMPLOYER,
    NotificationType.EMPLOYER_JOB_ORDER_SIGN_EXPIRED_BY_FREELANCER,
  ];

  public showFreelancerAvatar: string[] = [
    NotificationType.EMPLOYER_FREELANCER_ACCEPTED,
    NotificationType.FREELANCER_FREELANCER_ACCEPTED,
    NotificationType.EMPLOYER_FREELANCER_PROPOSAL,
    NotificationType.FREELANCER_FREELANCER_PROPOSAL,
    NotificationType.EMPLOYER_FREELANCER_OPEN_DISPUTE,
    NotificationType.FREELANCER_FREELANCER_OPEN_DISPUTE,
    NotificationType.EMPLOYER_FREELANCER_DECLINE_SELF,
    NotificationType.FREELANCER_FREELANCER_DECLINE_SELF,
    NotificationType.EMPLOYER_FREELANCER_LEFT_COMMENT,
    NotificationType.EMPLOYER_FREELANCER_TASK_STATUS_REVIEW,
    NotificationType.EMPLOYER_FREELANCER_TASK_STATUS_TO_DO,
    NotificationType.EMPLOYER_FREELANCER_TASK_STATUS_IN_PROGRESS,
    NotificationType.EMPLOYER_FREELANCER_REWORK_TASK,
    NotificationType.EMPLOYER_FREELANCER_STARTED_TASK,
    NotificationType.EMPLOYER_FREELANCER_DECLINED_TASK,
    NotificationType.EMPLOYER_FREELANCER_DECLINED,
    NotificationType.FREELANCER_FREELANCER_DECLINED,
    NotificationType.EMPLOYER_FREELANCER_CLOSED_DISPUTE,
    NotificationType.EMPLOYER_FREELANCER_CHANGE_PROJECT,
    NotificationType.EMPLOYER_FREELANCER_ACCEPTED_CHANGES,
    NotificationType.EMPLOYER_FREELANCER_DECLINED_CHANGES,
    NotificationType.FREELANCER_FREELANCER_CHANGE_PROJECT,
    NotificationType.FREELANCER_FREELANCER_ACCEPTED_CHANGES,
    NotificationType.FREELANCER_FREELANCER_DECLINED_CHANGES,
    NotificationType.EMPLOYER_FREELANCER_REVOKE_OWN_CHANGES,
    NotificationType.EMPLOYER_FREELANCER_STARTED_ARBITRAGE,
    NotificationType.EMPLOYER_REVIEW_ABOUT_FREELANCER_IS_ALLOWED,
    NotificationType.FREELANCER_JOB_CONTRACT_SIGNED_BY_FREELANCER,
    NotificationType.EMPLOYER_JOB_CONTRACT_SIGNED_BY_FREELANCER,
    NotificationType.EMPLOYER_JOB_FREELANCER_SIGNED_WORK_COMPLETION_CERTIFICATE,
    NotificationType.FREELANCER_JOB_FREELANCER_SIGNED_WORK_COMPLETION_CERTIFICATE,
    NotificationType.EMPLOYER_JOB_ORDER_SIGNED_BY_FREELANCER,
    NotificationType.FREELANCER_JOB_ORDER_SIGNED_BY_FREELANCER,
  ];

  public showEmployerAvatar: string[] = [
    NotificationType.EMPLOYER_EMPLOYER_ACCEPTED,
    NotificationType.FREELANCER_EMPLOYER_ACCEPTED,
    NotificationType.EMPLOYER_EMPLOYER_CHANGE_PROJECT,
    NotificationType.FREELANCER_EMPLOYER_CHANGE_PROJECT,
    NotificationType.FREELANCER_EMPLOYER_ACCEPTED_ALL_TASKS,
    NotificationType.EMPLOYER_EMPLOYER_ACCEPTED_ALL_TASKS,
    NotificationType.FREELANCER_FREELANCER_INVITED,
    NotificationType.EMPLOYER_FREELANCER_INVITED,
    NotificationType.EMPLOYER_EMPLOYER_DECLINED,
    NotificationType.FREELANCER_EMPLOYER_DECLINED,
    NotificationType.EMPLOYER_EMPLOYER_DECLINE_SELF,
    NotificationType.FREELANCER_EMPLOYER_DECLINE_SELF,
    NotificationType.FREELANCER_EMPLOYER_LEFT_COMMENT,
    NotificationType.FREELANCER_EMPLOYER_ACCEPTED_TASK,
    NotificationType.FREELANCER_EMPLOYER_DECLINED_TASK,
    NotificationType.FREELANCER_EMPLOYER_CREATED_TASK,
    NotificationType.FREELANCER_EMPLOYER_EDITED_TASK,
    NotificationType.FREELANCER_EMPLOYER_CLOSED_DISPUTE,
    NotificationType.FREELANCER_EMPLOYER_ACCEPTED_CHANGES,
    NotificationType.FREELANCER_EMPLOYER_DECLINED_CHANGES,
    NotificationType.EMPLOYER_EMPLOYER_ACCEPTED_CHANGES,
    NotificationType.EMPLOYER_EMPLOYER_DECLINED_CHANGES,
    NotificationType.FREELANCER_EMPLOYER_REVOKE_OWN_CHANGES,
    NotificationType.EMPLOYER_EMPLOYER_OPEN_DISPUTE,
    NotificationType.FREELANCER_EMPLOYER_OPEN_DISPUTE,
    NotificationType.FREELANCER_EMPLOYER_STARTED_ARBITRAGE,
    NotificationType.FREELANCER_REVIEW_ABOUT_EMPLOYER_IS_ALLOWED,
    NotificationType.FREELANCER_JOB_CONTRACT_SIGNED_BY_EMPLOYER,
    NotificationType.EMPLOYER_JOB_CONTRACT_SIGNED_BY_EMPLOYER,
    NotificationType.EMPLOYER_JOB_PAYROLL_CANCELED_AFTER_SIGN,
    NotificationType.FREELANCER_JOB_PAYROLL_CANCELED_AFTER_SIGN,
    NotificationType.EMPLOYER_JOB_PAYROLL_CANCELED,
    NotificationType.EMPLOYER_JOB_PAYROLL_CONTRACT_CHANGED_BY_EMPLOYER,
    NotificationType.EMPLOYER_JOB_PAYROLL_ORDER_CHANGED_BY_EMPLOYER,
    NotificationType.FREELANCER_JOB_DELETED,
    NotificationType.EMPLOYER_JOB_FINISHED,
    NotificationType.FREELANCER_JOB_FINISHED,
    NotificationType.FREELANCER_JOB_WITHDRAWN,
  ];

  public showErrorIcon: string[] = [
    NotificationType.EMPLOYER_MILESTONE_INSUFFICIENT_FUNDS,
    NotificationType.EMPLOYER_PROJECT_INSUFFICIENT_FUNDS,
    NotificationType.FREELANCER_JOB_RECEIPT_ERROR_TAXPAYER_UNREGISTERED,
    NotificationType.EMPLOYER_JOB_RECEIPT_ERROR_TAXPAYER_UNREGISTERED,
    NotificationType.FREELANCER_JOB_RECEIPT_ERROR_TAXPAYER_UNBOUND,
    NotificationType.EMPLOYER_JOB_RECEIPT_ERROR_TAXPAYER_UNBOUND,
    NotificationType.FREELANCER_JOB_PAYMENT_ERROR_TAXPAYER_UNBOUND,
    NotificationType.FREELANCER_JOB_PAYMENT_ERROR_TAXPAYER_UNREGISTERED,
    NotificationType.EMPLOYER_JOB_PAYMENT_ERROR_TAXPAYER_UNBOUND,
    NotificationType.EMPLOYER_JOB_PAYMENT_ERROR_TAXPAYER_UNREGISTERED,
    NotificationType.EMPLOYER_JOB_CONTRACT_SIGN_EXPIRED_BY_EMPLOYER,
    NotificationType.FREELANCER_JOB_CONTRACT_SIGN_EXPIRED_BY_EMPLOYER,
    NotificationType.EMPLOYER_JOB_CONTRACT_SIGN_EXPIRED_BY_FREELANCER,
    NotificationType.FREELANCER_JOB_CONTRACT_SIGN_EXPIRED_BY_FREELANCER,
    NotificationType.EMPLOYER_RECEIPT_ERROR_READY_LATE,
    NotificationType.FREELANCER_INVALID_PASSPORT_DATA_FNS,
    NotificationType.FREELANCER_RECEIPT_ERROR_READY_LATE,
    NotificationType.FREELANCER_TECHNICAL_ERROR_PAY_TAX_YOURSELF,
    NotificationType.FREELANCER_JOB_ORDER_SIGN_EXPIRED_BY_EMPLOYER,
    NotificationType.FREELANCER_JOB_ORDER_SIGN_EXPIRED_BY_FREELANCER,
    NotificationType.EMPLOYER_JOB_ORDER_SIGN_EXPIRED_BY_EMPLOYER,
    NotificationType.EMPLOYER_JOB_ORDER_SIGN_EXPIRED_BY_FREELANCER,
    NotificationType.EMPLOYER_JOB_RECEIPT_CANCELED,
    NotificationType.FREELANCER_JOB_RECEIPT_CANCELED,
    NotificationType.FREELANCER_TAX_PAYMENT_ERROR,
    NotificationType.FREELANCER_JOB_PAYMENT_LIMIT_EXCEEDED,
    NotificationType.FREELANCER_MASS_PAYMENT_LIMIT_EXCEEDED,
    NotificationType.EMPLOYER_JOB_PAYMENT_LIMIT_EXCEEDED,
    NotificationType.EMPLOYER_MASS_PAYMENT_LIMIT_EXCEEDED,
    NotificationType.EMPLOYER_CHARGEBACK_RECEIPT_CANCELED,
    NotificationType.FREELANCER_CHARGEBACK_RECEIPT_CANCELED,
  ];

  public showSuccessIcon: string[] = [
    NotificationType.FREELANCER_GOT_PAID,
    NotificationType.CARD_DETACHED,
    NotificationType.CARD_ATTACHED,
    NotificationType.PAYONEER_DETACHED,
    NotificationType.EMPLOYER_REGISTERED,
    NotificationType.FREELANCER_REGISTERED,
    NotificationType.EMPLOYER_RUBLE_BALANCE_REPLENISHED,
    NotificationType.EMPLOYER_REWARD_PAID,
    NotificationType.FREELANCER_REWARD_PAID_WITH_TAX,
    NotificationType.FREELANCER_REWARD_PAID,
    NotificationType.FREELANCER_JOB_ADVANCE_PAYMENT_MADE,
    NotificationType.EMPLOYER_JOB_ADVANCE_PAYMENT_MADE,
    NotificationType.EMPLOYER_JOB_RECEIPT_READY,
    NotificationType.FREELANCER_JOB_RECEIPT_READY,
    NotificationType.FREELANCER_FULL_ACCESS_GRANTED,
    NotificationType.EMPLOYER_FULL_ACCESS_GRANTED,
    NotificationType.FREELANCER_FUNDS_LEFT_AFTER_PAYMENT,
    NotificationType.FREELANCER_TAX_PAID_PARTLY,
    NotificationType.FREELANCER_TAX_PAID,
    NotificationType.FREELANCER_TAX_PAID_REST_RETURNED,
  ];

  public infoIcon: string[] = [
    NotificationType.FREELANCER_OTHER_FREELANCER_SELECTED,
    NotificationType.EMPLOYER_AUTO_ACCEPT,
    NotificationType.EMPLOYER_ARBITRAGE_DECISION_IN_FAVOR_OF_EMPLOYER,
    NotificationType.FREELANCER_ARBITRAGE_DECISION_IN_FAVOR_OF_EMPLOYER,
    NotificationType.EMPLOYER_ARBITRAGE_DECISION_IN_FAVOR_OF_FREELANCER,
    NotificationType.FREELANCER_ARBITRAGE_DECISION_IN_FAVOR_OF_FREELANCER,
    NotificationType.EMPLOYER_ARBITRAGE_DECISION_IN_PROPORTION,
    NotificationType.FREELANCER_ARBITRAGE_DECISION_IN_PROPORTION,
    NotificationType.EMPLOYER_RUBLE_NEED_PAY_AN_INVOICE,
    NotificationType.EMPLOYER_RUBLE_NEED_CREATE_AN_INVOICE,
    NotificationType.EMPLOYER_JOB_REFUND_TO_EMPLOYER,
    NotificationType.EMPLOYER_JOB_CONTRACT_PREPARING,
    NotificationType.FREELANCER_JOB_CONTRACT_PREPARING,
    NotificationType.FREELANCER_JOB_CONTRACT_READY,
    NotificationType.EMPLOYER_JOB_CONTRACT_READY,
    NotificationType.FREELANCER_JOB_CONTRACT_CHANGED_BY_FREELANCER,
    NotificationType.EMPLOYER_JOB_CONTRACT_CHANGED_BY_FREELANCER,
    NotificationType.FREELANCER_JOB_CONTRACT_CHANGED_BY_EMPLOYER,
    NotificationType.EMPLOYER_JOB_CONTRACT_CHANGED_BY_EMPLOYER,
    NotificationType.EMPLOYER_JOB_MAKE_ADVANCE_PAYMENT,
    NotificationType.FREELANCER_JOB_AWAIT_PREPAYMENT,
    NotificationType.EMPLOYER_JOB_INVOICE_IS_BEING_PREPARED,
    NotificationType.EMPLOYER_JOB_INVOICE_SAVED,
    NotificationType.EMPLOYER_JOB_REFUND_DOCS_READY,
    NotificationType.EMPLOYER_JOB_REFUND_COMMISSION_PAID,
    NotificationType.EMPLOYER_JOB_FINISHED_AUTOMATICALLY,
    NotificationType.FREELANCER_JOB_FINISHED_AUTOMATICALLY,
    NotificationType.EMPLOYER_JOB_FREELANCER_DID_NOT_SIGN_WCC,
    NotificationType.FREELANCER_JOB_FREELANCER_DID_NOT_SIGN_WCC,
    NotificationType.FREELANCER_TAX_PAID_RETURNED,
    NotificationType.EMPLOYER_JOB_ORDER_PREPARING,
    NotificationType.FREELANCER_JOB_ORDER_PREPARING,
    NotificationType.EMPLOYER_JOB_ORDER_READY,
    NotificationType.FREELANCER_JOB_ORDER_READY,
    NotificationType.EMPLOYER_JOB_ORDER_SIGNED_BY_EMPLOYER,
    NotificationType.FREELANCER_JOB_ORDER_SIGNED_BY_EMPLOYER,
    NotificationType.FREELANCER_JOB_ANOTHER_FREELANCER_HIRED,
    NotificationType.EMPLOYER_MVD_NEW_JOB,
    NotificationType.EMPLOYER_MVD_JOB_END_DATE,
    NotificationType.EMPLOYER_MVD_JOB_FINISHED_EARLIER,
    NotificationType.FREELANCER_ALL_TAX_PAID_BEFORE,
  ];

  public warningIcon: string[] = [
    NotificationType.EMPLOYER_REVIEW_DEADLINE_IS_SOON,
    NotificationType.EMPLOYER_JOB_CONTRACT_SIGNING_BY_EMPLOYER_EXPIRES,
    NotificationType.FREELANCER_JOB_CONTRACT_SIGNING_BY_FREELANCER_EXPIRES,
    NotificationType.EMPLOYER_JOB_PAYMENT_DEADLINE_EXPIRES_7_DAYS,
    NotificationType.EMPLOYER_JOB_PAYMENT_DEADLINE_EXPIRES_24_HOURS,
    NotificationType.EMPLOYER_JOB_PAYMENT_DEADLINE_EXPIRED,
    NotificationType.FREELANCER_JOB_PAYMENT_DEADLINE_EXPIRED,
    NotificationType.FREELANCER_REQUIRED_FIELDS_ARE_NOT_FILLED_7_DAYS,
    NotificationType.EMPLOYER_REQUIRED_FIELDS_ARE_NOT_FILLED_7_DAYS,
    NotificationType.FREELANCER_JOB_FREELANCER_WCC_ONE_DAY_LEFT_TO_SIGN,
    NotificationType.FREELANCER_REVOKE_RIGHT,
    NotificationType.FREELANCER_SELF_EMPLOYED_STATUS_LOST,
    NotificationType.FREELANCER_TAXPAYER_UNBOUND,
    NotificationType.EMPLOYER_JOB_ORDER_SIGNING_BY_EMPLOYER_EXPIRES,
    NotificationType.FREELANCER_JOB_ORDER_SIGNING_BY_FREELANCER_EXPIRES,
    NotificationType.EMPLOYER_JOB_INSUFFICIENT_FUNDS,
    NotificationType.FREELANCER_SELF_EMPLOYED_TAX_PAYMENT_REVERT,
    NotificationType.FREELANCER_SELF_EMPLOYED_REMOVED_FROM_REGISTER,
    NotificationType.FREELANCER_SELF_EMPLOYED_DETACHED,
    NotificationType.EMPLOYER_JOB_CHARGEBACK,
    NotificationType.EMPLOYER_MASS_PAYMENT_CHARGEBACK,
    NotificationType.FREELANCER_JOB_CHARGEBACK,
    NotificationType.FREELANCER_MASS_PAYMENT_CHARGEBACK,
  ];

  public showFnsIcon = [NotificationType.FNS];

  private subscription: Subscription;
  private result: string;

  constructor(private translateService: TranslateService,
              private cd: ChangeDetectorRef,
              private profile: ProfileService,
              private router: Router,
              private notificationService: NotificationService,
              public routeHelper: RouteHelper,
              private ngZone: NgZone) {
  }

  get showMarkAll(): boolean {
    return this.notifications.some(item => !item.viewed);
  }

  public translate(value: NotificationV2): string {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }

    const { code, data } = value;
    const type = data?.type;
    this.subscription = this.translateService.get(code, {
      emp: data?.employer && `${data.employer.firstName} ${data.employer.lastName}` || '',
      fr: data?.freelancer && `${data.freelancer.firstName} ${data.freelancer.lastName}` || '',
      project: type === 'project' ? data?.name || data.jobName :
        type === 'compose' ? this.getProjectNameFromComposeType(data) :
        data?.project?.name || data?.project?.projectName || data?.project?.jobName ||
        data?.projectName || data?.task?.project?.name || data?.task?.job?.name || data?.job?.name || data?.jobName || '',
      job: data?.jobName || '',
      task: data && data.name || '',
      deadline: data && typeof data.reviewDaysLeft === 'number' ? data.reviewDaysLeft : '',
      empSum: data?.employerRefund || '',
      frSum: data?.freelancerRefund || '',
      currency: CurrencySymbol[data?.job?.currency?.code] || CurrencySymbol[data?.task?.job?.currency?.code] || '',
      amount: type === 'taxPayment' ? `${data?.amount}₽` || '' : '',
      currMonth: data?.currMonth ? this.formatMonth(data.currMonth, false) : '',
      prevMonth: data?.prevMonth ? this.formatMonth(data.prevMonth, true) : '',
      company: data?.employerLegalInfo?.companyName || '',
      contract: data?.contractNumber || '',
    })
      .subscribe((result) => {
        this.result = result;
        this.ngZone.run(() => this.cd.markForCheck());
      });

    if (code === NotificationType.FNS) {
      this.result = data.message;
    }

    return this.result;
  }

  public formatMonth(date: number[], standalone = true): string {
    const standaloneMonths = ['', 'Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь', 'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь'];
    const formatMonths = ['', 'Января', 'Февраля', 'Марта', 'Апреля', 'Мая', 'Июня', 'Июля', 'Августа', 'Сентября', 'Октября', 'Ноября', 'Декабря'];
    const month = date[1];
    return standalone ? `${standaloneMonths[month]}` : `${formatMonths[month]}`;
  }

  public markAllAsRead(): void {
    this.notifications.forEach(item => item.viewed = true);
    this.markAllAsReadEvent.emit();
  }

  public redirectTo(notification: NotificationV2): void {
    const isEmployer = this.profile.currentProfile.isEmployer();
    const chatId = notification?.data?.chatId || (notification?.data?.id?.includes('chat_') ? notification?.data?.id : null);
    const projectId = notification?.data?.project?.projectId || notification?.data?.project?.id ||
      notification?.data?.task?.project?.id || notification?.data?.job?.projectId
      || notification?.data?.task?.job?.projectId || notification?.data?.id;
    const mpId = (notification?.data?.id?.includes('mp_') ? notification?.data?.id : null);

    if (!notification.viewed) {
      this.markOneAsRead(notification);
    }

    if (this.redirectToChatsArray.includes(notification.code) && chatId) {
      this.router.navigate(this.routeHelper.contractsChatsUrl(chatId));
      this.close.emit();
      return;
    }

    if (this.redirectToMassPaymentPageOrChat.includes(notification.code)) {
      // @TODO доделать логику в зависимости от типа выплаты. если разовая - редиректить на чат, если массовая - на страницу массовой выплаты
      return;
    }

    if (this.redirectToMassPaymentPage.includes(notification.code)) {
      this.router.navigate(this.routeHelper.massPayment(mpId));
      this.close.emit();
      return;
    }

    if (this.redirectToProposalsArray.includes(notification.code) && chatId) {
      this.router.navigate(this.routeHelper.contractsProposalsUrl(chatId));
      this.close.emit();
      return;
    }

    if (this.redirectToFinance.includes(notification.code)) {
      this.router.navigate(this.routeHelper.financesCommon);
      this.close.emit();
      return;
    }

    if (this.redirectToFinanceHistory.includes(notification.code)) {
      this.router.navigate(this.routeHelper.financesTransactions);
      this.close.emit();
      return;
    }

    if (this.redirectToTaskTracker.includes(notification.code)) {
      this.router.navigate(this.routeHelper.employerProjectPage(projectId));
      this.close.emit();
      return;
    }

    if (this.redirectToProjects.includes(notification.code)) {
      this.router.navigate(this.routeHelper.employerProjectsDashboard);
      this.close.emit();
      return;
    }

    if (this.redirectFinanceDetails.includes(notification.code)) {
      this.router.navigate(this.routeHelper.financesPaymentDetails);
      this.close.emit();
      return;
    }

    if (this.redirectToProjectPage.includes(notification.code)) {
      this.router.navigate(this.routeHelper.employerProjectPage(projectId));
      this.close.emit();
      return;
    }

    if (this.redirectToPassportPage.includes(notification.code)) {
      this.router.navigate(this.routeHelper.settingsLegalData);
      this.close.emit();
      return;
    }

    if (this.redirectToSettingsPage.includes(notification.code)) {
      this.router.navigate(this.routeHelper.settingsGeneral);
      this.close.emit();
      return;
    }
  }

  public isActiveRow(notification: NotificationV2): boolean {
    return this.redirectToChatsArray.includes(notification.code) ||
      this.redirectToProposalsArray.includes(notification.code) ||
      this.redirectToTaskTracker.includes(notification.code) ||
      this.redirectToFinance.includes(notification.code) ||
      this.redirectToFinanceHistory.includes(notification.code) ||
      this.redirectToProjects.includes(notification.code) ||
      this.redirectToProjectPage.includes(notification.code) ||
      this.redirectToPassportPage.includes(notification.code) ||
      this.redirectToSettingsPage.includes(notification.code) ||
      this.redirectFinanceDetails.includes(notification.code);
  }

  public get noNotifications(): boolean {
    return !this.notifications.length;
  }

  public deleteNotification(notification): void {
    this.notificationService.deleteNotification(notification.id).subscribe(
      () => {
        this.notifications = this.notifications.filter(item => item.id !== notification.id);

        this.delete.emit(notification);
        if (!notification.viewed) {
          this.ack.emit();
        }
      }
    );
  }

  public markOneAsRead(notification: NotificationV2): void {
    notification.viewed = true;
    this.notificationService.markOneAsRead(notification.id).subscribe(() => {
      this.ack.emit();
    });
  }

  public getSender(notification: NotificationV2): string {
    if (notification.code === 'FNS') {
      return 'ФНС';
    }

    switch (true) {
      case this.showFreelancerAvatar.includes(notification?.code): {
        const currentId = this.profile.currentProfile.id;
        const freelancer = notification.data?.freelancer;
        return freelancer.id === currentId ? 'Вы' : `${freelancer?.firstName} ${freelancer.lastName}` || '';
      }
      case this.showEmployerAvatar.includes(notification?.code): {
        const currentId = this.profile.currentProfile.id;
        const employer = notification.data?.employer;
        return employer.id === currentId ? 'Вы' : `${employer?.firstName} ${employer.lastName}` || '';
      }
      case [...this.showErrorIcon, ...this.showSuccessIcon, ...this.infoIcon, ...this.warningIcon].includes(notification?.code): {
        return 'Talent Cloud';
      }

    }
    return '';
  }

  private getProjectNameFromComposeType(data) {
    return data.data?.find(item => item?.type === 'project')?.name;
  }
}
