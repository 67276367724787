<div class="search-page-root">
  <aside cdkScrollable class="aside-container">
    <tc-search-filters [filter]="filter" [type]="'TALENT'"></tc-search-filters>
  </aside>
  <div class="search-page-layout" (scroll)="onScroll($event)">
    <div #top></div>
    <freelancer-search-page (searchEvent)="updateUsers($event)"
                            (openFilterDialog)="openFilterDialog()"
                            [freelancers]="freelancers"
                            [totalElements]="totalElements"
                            [showSkeleton]="showSkeleton"
                            [skillsFilter]="filter.get('skills')"
    ></freelancer-search-page>

    <div class="searching-wrap" *ngIf="loading">
      <mat-icon [svgIcon]="svgIconsEnum.HeaderLogo"></mat-icon>
      <div class="searching">{{'SEARCH.LOADING_MORE' | translate}}</div>
    </div>
  </div>
</div>

<tc-filter-search-dialog></tc-filter-search-dialog>
