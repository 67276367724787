import { Injectable } from '@angular/core';
import { BaseService } from '@/services/base.service';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { MassPaymentModel } from '@/models/mass-payments/mass-payment.model';
import { RecipientModel } from '@/models/mass-payments/recipient.model';
import { FreelancerMassPaymentModel } from '@/models/mass-payments/freelancer-mass-payment.model';

@Injectable({
  providedIn: 'root'
}) export class MassPaymentsService extends BaseService {
  constructor(http: HttpClient) {
    super(http);
  }

  public createFromFile = (name: string, file: File): Promise<any> => {
    const formData = new FormData();
    formData.append('file', file, file.name);
    formData.append('name', name);

    return this.executePost('massPayments/createFromFile', formData);
  }

  public confirmPayment = (id: string): Observable<MassPaymentModel> => {
    return this.executePut('massPayments/confirm', null, { params: { id } });
  }

  public getRecipients = (id: string): Observable<RecipientModel[]> => {
    return this.executeGet('massPayments/recipients', { params: { id } });
  }

  public getMassPayments = (search: string = ''): Observable<MassPaymentModel[]> => {
    const params = { params: { search } };
    return this.executeGet('massPayments/find', search ? params : {});
  }

  public getMassPayment = (id: string): Observable<MassPaymentModel> => {
    return this.executeGet('massPayments', { params: { id } });
  }

  public getPayments = (search?: string): Observable<FreelancerMassPaymentModel[]> => {
    const params = { params: { search } };
    return this.executeGet('massPayments/freelancer', search ? params : {});
  }

  public deletePayment = (id: string): Promise<void> => {
    return this.executeDelete('massPayments', { params: { id } });
  }

  public deleteMPR = (id: string[]): Promise<void> => {
    return this.executeDelete('massPayments/recipients', { params: { id } });
  }

  public cancelExecutedMPR = (id: string[]): Promise<void> => {
    return this.executeDelete('massPayments/cancelPayments', { params: { id } });
  }

  public exportTable = (id: string): Observable<any> => {
    return this.executeGet('massPayments/exportMassPaymentInfo', { params: { id }, responseType: 'blob' });
  }

  public exportRecipientsTemplate = (): Observable<any> => {
    return this.executeGet('massPayments/exportRecipientsTemplate', { responseType: 'blob' });
  }

  public singWcc = (id: string[], massPaymentId: string): Promise<any> => {
    return this.executePost('massPayments/signWorkCompletionCertificate', null, { params: { id, massPaymentId } });
  }

  public singWccFreelancer = (id: string): Promise<any> => {
    return this.executePost('massPayments/freelancer/signWorkCompletionCertificate', null, { params: { id } });
  }

  public pay = (id: string): Promise<any> => {
    return this.executePost('massPayments/pay', null, { params: { id } });
  }

  public getMassPaymentsActivesCount = (): Observable<number> => {
    return this.executeGet('massPayments/massPaymentsActivesCount');
  }
}
