import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { SvgIconsEnum } from '@/types/svg-icons.enum';
import { RouteHelper } from '@/helpers/route.helper';
import { ProfileService } from '@/services/profile.service';
import { ProjectApiService } from '@/services/project.api.service';
import { Router } from '@angular/router';
import { ContractsService } from '@/modules/contracts/services/contracts.service';
import { takeUntil } from 'rxjs/operators';
import { Subject, lastValueFrom } from 'rxjs';
import { MassPaymentsService } from '@/services/mass-payments.service';

@Component({
  selector: 'app-mobile-sidebar',
  templateUrl: './mobile-sidebar.component.html',
  styleUrls: ['./mobile-sidebar.component.scss']
})
export class MobileSidebarComponent implements OnInit, OnDestroy {

  public svgIconsEnum = SvgIconsEnum;
  public unreadCount: number;
  public unreadPaymentsCount: number;

  public get isEmployer(): boolean {
    if (!this.profileService.currentProfile) { return; }
    return this.profileService.currentProfile.isEmployer();
  }

  get projectsRoute() {
    if (!this.projectService.currentProject) {
      return this.routeHelper.employerProjectsDashboard;
    }

    return this.routeHelper.employerProjectPage(this.projectService.currentProject)
  }

  get searchIsActive(): boolean {
    return this.router.url.includes('/search');
  }

  public show = false;
  private readonly destroy$ = new Subject<void>();

  constructor(
    public routeHelper: RouteHelper,
    private router: Router,
    private projectService: ProjectApiService,
    public profileService: ProfileService,
    private contractsService: ContractsService,
    private massPaymentsService: MassPaymentsService,
  ) { }

  ngOnInit(): void {
    if (this.profileService.currentProfile) {
      this.getUnreadMessages();

      if (this.profileService.currentProfile.isEmployer()){
        this.getActivePaymentsCount();
      }
    }
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  toggleSidebar() {
    this.show = !this.show;
  }

  public getUnreadMessages(): void {
    lastValueFrom(this.contractsService.getUnreadCount()).then(count => {
      this.unreadCount = count;
    });
  }

  public getActivePaymentsCount(): void {
    lastValueFrom(this.massPaymentsService.getMassPaymentsActivesCount()).then(count => {
      this.unreadPaymentsCount = count;
    });
  }
}
