import { MatTooltip } from '@angular/material/tooltip';

export const showTooltip = (tooltip: MatTooltip): void => {
  tooltip.disabled = false;
  tooltip.toggle();
  setTimeout(() => {
    tooltip.toggle();
    tooltip.disabled = true;
  }, 2000);
};
