import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { DialogComponent } from '@/modules/dialog/components/dialog/dialog.component';
import { MatDialogConfig } from '@angular/material/dialog';
import { FormGroup } from '@angular/forms';
import { SvgIconsEnum } from '@/types/svg-icons.enum';

@Component({
  selector: 'tc-filter-search-dialog',
  templateUrl: './filter-search-dialog.component.html',
  styleUrls: ['./filter-search-dialog.component.scss']
})
export class FilterSearchDialogComponent implements OnInit {

  @ViewChild(DialogComponent)
  public dialog: DialogComponent;

  @Input() type: 'TALENT' | 'PROJECT';

  public filter: FormGroup;

  public svgIconsEnum = SvgIconsEnum;

  private config: MatDialogConfig = {
    width: '400px',
    maxWidth: 'calc(100vw - 48px)',
    panelClass: 'tc-filter-search-dialog'
  };

  private className = 'tc-filter-search-dialog';

  constructor() { }

  ngOnInit(): void {
  }

  public open(filter, type: 'TALENT' | 'PROJECT'): void {
    this.type = type;
    this.filter = filter;
    this.dialog.config = this.config;
    this.dialog.open(this.className);
  }

  public close(): void {
    this.dialog.close();
  }
}
