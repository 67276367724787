import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { plainToClass } from 'class-transformer';
import { Observable, of, share } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { Language } from '../models/language';
import { BaseService } from './base.service';
import { cloneDeep } from 'lodash';

@Injectable({
  providedIn: 'root',
})
export class LanguageService extends BaseService {
  languages: Language[];

  constructor(http: HttpClient) {
    super(http);
  }

  public getLanguagesV2(): Observable<Language[]> {
    if (this.languages) {
      return of(cloneDeep(this.languages));
    }
    return this.executeGet<Language[]>('v2/languages')
      .pipe(map((res) => {
        this.languages = res;
        return cloneDeep(res);
      }), share());
  }

}
