import {
  Component,
  ElementRef,
  HostListener,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { FormArray, FormControl, FormGroup, } from '@angular/forms';
import { Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { omitBy } from 'lodash';

import { SearchService } from 'src/app/services/search.service';
import { ProjectApiService } from '@/services/project.api.service';
import { SvgIconsEnum } from '@/types/svg-icons.enum';
import { LoadingBarService } from '@/services/loading-bar.service';
import { TalentFiltersComponent } from '@/modules/search/components/parts/talent-filters/talent-filters.component';
import {
  FilterSearchDialogComponent
} from '@/modules/search/components/dialogs/filter-search-dialog/filter-search-dialog.component';

@Component({
  selector: 'project-search',
  templateUrl: './project-search.component.html',
  styleUrls: ['./../../filters-common.scss'],
})
export class ProjectSearchComponent implements OnInit, OnDestroy {
  @ViewChild('top')
  public top: ElementRef;

  @ViewChild(TalentFiltersComponent)
  public talentFiltersComponent: TalentFiltersComponent;

  @ViewChild(FilterSearchDialogComponent) filterSearchDialog: FilterSearchDialogComponent;

  public projects = [];

  public svgIconsEnum = SvgIconsEnum;

  public filteredItems = [];

  public filter: FormGroup;

  public showSkeleton = false;

  public loading: boolean;

  public totalElements: number;

  private pageNumber = 0;

  private params;

  private currentPosition = 0;

  private totalPages: number;

  private readonly destroy$ = new Subject<void>();

  constructor(
    private searchService: SearchService,
    private projectService: ProjectApiService,
    private loadingBarService: LoadingBarService
  ) {
  }

  @HostListener('scroll', ['$event']) onScroll(event) {
    const containerHeight = event?.target?.offsetHeight;
    const scrollHeight = event?.target?.scrollHeight;
    const scrollTop = event?.target?.scrollTop;

    if (this.currentPosition > scrollTop) {
      return;
    }
    this.currentPosition = scrollTop;

    const a = scrollHeight - scrollTop;
    const b = containerHeight * 0.25 + containerHeight;

    if (a < b && !this.loading) {
      if (this.pageNumber >= this.totalPages - 1) {
        return;
      }
      this.loading = true;
      this.pageNumber = this.pageNumber + 1;
      this.getListOfProjects(this.params);
    }
  }

  ngOnInit(): void {
    this.initForm();
    this.getFilteredProjects();
  }

  getFilteredProjects(): void {
    this.loadingBarService.start();
    this.searchService.projects$.pipe(takeUntil(this.destroy$)).subscribe(data => {
      this.refreshVariables();

      if (!data) {
        this.params = {};
        this.getListOfProjects({});
        return;
      }

      const mappedData = data.value;
      mappedData.skills = mappedData.skills.map(item => item.id);

      const model = omitBy(mappedData,
        (field: string | number) => !Boolean(field));

      this.params = model;
      this.getListOfProjects(model);
    });
  }

  initForm(): void {
    this.filter = new FormGroup<any>({
      categories: new FormControl<any[]>([]),
      skills: new FormArray<any>([]),
      levels: new FormControl<string>(''),
      budgetMin: new FormControl<number>(null),
      budgetMax: new FormControl<number>(null),
      countryCode: new FormControl<string>(''),
      languageLevels: new FormControl<any[]>([]),
      sortBy: new FormControl<string>('PUBLISH_DATE_DESC'),
      projectOwnerNameFtsQuery: new FormControl<string>('')
    });
    this.searchService.filtersProjectsNext(this.filter);
    this.filter.valueChanges.pipe(takeUntil(this.destroy$),
      debounceTime(500)
    ).subscribe(() => {
      this.searchService.filtersProjectsNext(this.filter);
    });
  }

  getListOfProjects(filter) {
    const defaultFilter = {pageNumber: this.pageNumber, pageSize: 15};
    this.projectService.getListOfProjects({...defaultFilter, ...filter})
      .subscribe((res) => {
        this.projects = [...this.projects, ...res.content];
        this.loading = false;
        this.totalPages = res.totalPages;
        this.totalElements = res.totalElements;
        this.markJobAsVisited();
        this.showSkeleton = false;
        this.loadingBarService.complete();
    });
  }

  markJobAsVisited(): void {
    const visitedJobCards = JSON.parse(localStorage.getItem('visitedJobCards') || '{}');
    const visitedJobIds = Object.keys(visitedJobCards);
    this.projects.forEach(job => {
      if (visitedJobIds.includes(`${job.id}`)) {
        job.visited = true;
      }
    });
  }

  updateSortOrder(event) {
    this.filter.controls.sortBy.patchValue(event);
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public updateProjects(val): void {
    this.filter.controls.projectOwnerNameFtsQuery.patchValue(val);
  }

  private refreshVariables(): void {
    this.pageNumber = 0;
    this.currentPosition = 0;
    this.showSkeleton = true;
    this.projects = [];
    this.top?.nativeElement.scrollIntoView({behavior: 'smooth'});
  }
}
