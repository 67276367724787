<div class="mobile-sidebar-root" *ngIf="show && profileService.currentProfile">
  <div class="logo-and-menu">
    <div class="menu-header">
      <button mat-icon-button class="menu-button" (click)="toggleSidebar()">
        <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M9.10156 1.87891L6.23047 4.75L9.10156 7.64844C9.45703 7.97656 9.45703 8.55078 9.10156 8.87891C8.77344 9.23438 8.19922 9.23438 7.87109 8.87891L5 6.00781L2.10156 8.87891C1.77344 9.23438 1.19922 9.23438 0.871094 8.87891C0.515625 8.55078 0.515625 7.97656 0.871094 7.64844L3.74219 4.75L0.871094 1.87891C0.515625 1.55078 0.515625 0.976562 0.871094 0.648438C1.19922 0.292969 1.77344 0.292969 2.10156 0.648438L5 3.51953L7.87109 0.648438C8.19922 0.292969 8.77344 0.292969 9.10156 0.648438C9.45703 0.976562 9.45703 1.55078 9.10156 1.87891Z" fill="#0F1115"/>
        </svg>
      </button>

      <div class="header-actions">
        <app-header-menu color="dark"></app-header-menu>
      </div>
    </div>

    <mat-icon
      class="logo"
      [svgIcon]="svgIconsEnum.FooterLogo"
      [routerLink]="'/landing'"
    ></mat-icon>

    <div class="divider mx-32"></div>
    <div class="menu">
      <div class="top-menu">
        <a [routerLink]="routeHelper.finances" (click)="show = false">
          <div
            class="menu-item"
            routerLinkActive="active-link"
          >
            <mat-icon
              [svgIcon]="svgIconsEnum.SidebarFinances"
            ></mat-icon>
            <span class="item-text">
            <span class="tab-name">{{'SIDEBAR.FINANCE' | translate}}</span>
          </span>
          </div>
        </a>
        <a [routerLink]="routeHelper.executorsPage" *ngIf="isEmployer" (click)="show = false">
          <div
            class="menu-item"
            routerLinkActive="active-link"
          >
            <mat-icon
              [svgIcon]="svgIconsEnum.SidebarTeam"
            ></mat-icon>
            <span class="item-text">
                <span class="tab-name wrap">Мои исполнители</span>
              </span>
          </div>
        </a>
        <a [routerLink]="routeHelper.massPayments" *ngIf="isEmployer" (click)="show = false">
          <div
            class="menu-item"
            routerLinkActive="active-link"
          >
            <mat-icon [svgIcon]="svgIconsEnum.SidebarPayments"></mat-icon>
            <span class="item-text">
            <span class="tab-name wrap">Выплаты</span>
            <div *ngIf="unreadPaymentsCount" class="unread-messages">{{unreadPaymentsCount > 99 ? '99+' : unreadPaymentsCount}}</div>
          </span>
          </div>
        </a>

        <a [routerLink]="routeHelper.clientsPage" *ngIf="!isEmployer" (click)="show = false">
          <div
            class="menu-item"
            routerLinkActive="active-link"
          >
            <mat-icon [svgIcon]="svgIconsEnum.SidebarTeam"></mat-icon>
            <span class="item-text">
              <span class="tab-name">Мои заказчики</span>
            </span>
          </div>
        </a>

        <a [routerLink]="routeHelper.payments" *ngIf="!isEmployer" (click)="show = false">
          <div
            class="menu-item"
            routerLinkActive="active-link"
          >
            <mat-icon [svgIcon]="svgIconsEnum.SidebarPayments"></mat-icon>
            <span class="item-text">
              <span class="tab-name">Выплаты</span>
            </span>
          </div>
        </a>
        <a [routerLink]="routeHelper.contracts" (click)="show = false">
          <div
            class="menu-item message"
            routerLinkActive="active-link"
          >
            <div class="d-flex align-items-center">
              <mat-icon [svgIcon]="svgIconsEnum.SidebarMessages"
              ></mat-icon>
              <span class="item-text">
              <span class="tab-name">{{'SIDEBAR.MESSAGES' | translate}}</span>
              <div *ngIf="unreadCount" class="unread-messages">{{unreadCount > 99 ? '99+' : unreadCount}}</div>
            </span>
            </div>
          </div>
        </a>
      </div>

      <div class="bottom-menu">
        <div class="divider mb-24 mx-16"></div>

        <a [routerLink]="isEmployer ? routeHelper.freelancerSearch : routeHelper.projectSearch"
           (click)="show = false">
          <div
            class="menu-item"
            [class.active-link]="searchIsActive"
          >
            <mat-icon
              [svgIcon]="svgIconsEnum.SidebarSearch"
              routerLinkActive="active-link"
            ></mat-icon>
            <span class="item-text">
            <span class="tab-name">{{isEmployer ? 'Поиск исполнителей' : 'Поиск проектов'}}</span>
          </span>
          </div>
        </a>
        <a [routerLink]="projectsRoute" (click)="show = false">
          <div
            class="menu-item"
            routerLinkActive="active-link"
          >
            <mat-icon
              [svgIcon]="svgIconsEnum.SidebarProjects"
            ></mat-icon>
            <span class="item-text">
            <span class="tab-name">Мои проекты</span>
          </span>
          </div>
        </a>
        <a [routerLink]="routeHelper.settings" (click)="show = false">
          <div
            class="menu-item"
            routerLinkActive="active-link"
          >
            <mat-icon
              [svgIcon]="svgIconsEnum.SidebarSettings"
            ></mat-icon>
            <span class="item-text">
              <span class="tab-name">{{'SIDEBAR.SETTINGS' | translate}}</span>
            </span>
          </div>
        </a>
      </div>
    </div>
  </div>
</div>
