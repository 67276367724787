import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl, UntypedFormArray, UntypedFormControl } from '@angular/forms';
import { Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';

import { IBaseFilters } from '@/classes/iBaseFilters';
import { FreelancerSearchSorting } from '@/classes/enums';
import { ProfileModel } from '@/models/profile.model';
import { SvgIconsEnum } from '@/types/svg-icons.enum';

@Component({
  selector: 'freelancer-search-page',
  templateUrl: './freelancer-search-page.component.html',
  styleUrls: ['./freelancer-search-page.component.scss'],
})
export class FreelancerSearchPageComponent implements OnInit, OnDestroy {

  @Output() searchEvent = new EventEmitter();
  @Output() openFilterDialog = new EventEmitter<void>();

  @Input() freelancers: ProfileModel[];
  @Input() totalElements: number;
  @Input() skillsFilter: UntypedFormArray;
  @Input() showSkeleton: boolean;

  public svgIconsEnum = SvgIconsEnum;

  public sortList = [
    {key: 'RATE_ASC', label: 'Ставке (по возрастанию)'},
    {key: 'RATE_DESC', label: 'Ставке (по убыванию)'},
  ];

  public filters: Partial<IBaseFilters> = {
    sort: FreelancerSearchSorting.RATE_ASC,
    skills: [],
    expertLevel: null,
    location: null,
    searchText: '',
    pageIndex: 0,
    pageSize: 20,
    hourlyRate: [0, 50000],
  };

  public searchControl: FormControl;

  private destroy = new Subject<void>();

  ngOnInit(): void {
    this.searchControl = new FormControl<string>('');

    this.searchControl.valueChanges.pipe(debounceTime(800), takeUntil(this.destroy)).subscribe((val) => {
      this.searchUsers();
    });
  }

  ngOnDestroy() {
    this.destroy.next();
    this.destroy.complete();
  }

  public searchUsers(): void {
    const searchValue = this.searchControl.value.trim();
    this.searchEvent.emit(searchValue);
  }

  public openFiltersDialog() {
    this.openFilterDialog.emit();
  }

}
