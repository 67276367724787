import { BaseService } from './base.service';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { fromPromise } from 'rxjs/internal/observable/innerFrom';

@Injectable({
  providedIn: 'root'
})
export class FnsApiService extends BaseService {

  constructor(
    http: HttpClient,
  ) {
    super(http);
  }

  public sendVerificationCode(phone: string): Observable<any> {
    return fromPromise(this.executePost('v2/users/phoneVerification/send', { phone }));
  }

  public resendVerificationCode(): Observable<any> {
    return fromPromise(this.executePost('v2/users/phoneVerification/resend', null));
  }

  public verifyPhoneNumber(code: string): Observable<any> {
    return fromPromise(this.executePost('v2/users/phoneVerification/verify', { code }));
  }

  public getBindStatus(): Observable<any> {
    return this.executeGet('v2/users/legal/selfEmployed/bindStatus');
  }

  public bindWithPhone(phone: string): Observable<any> {
    return this.executePut('v2/users/legal/selfEmployed/bindWithPhone', null, { params: { phone } });
  }

  public bindWithInn(inn: string): Observable<any> {
    return this.executePut('v2/users/legal/selfEmployed/bindWithInn', null, { params: { inn } });
  }

  public unbindPartner(): Observable<any> {
    return this.executePut('v2/users/legal/selfEmployed/unbindPartner', null);
  }

}
