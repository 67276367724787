import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UntypedFormControl, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

import { AlertService } from '@/services/alert.service';
import { AuthService } from '@/services/auth.service';
import { ProfileService } from '@/services/profile.service';
import { SvgIconsEnum } from '@/types/svg-icons.enum';
import { LoginForm } from '@/forms/login.form';
import { SpinnerService } from '@/modules/tpt-ui/services/spinner.service';
import {
  AuthErrorDialogComponent
} from '@/modules/common-dialogs/components/auth-error-dialog/auth-error-dialog.component';
import { RouteHelper } from '@/helpers/route.helper';
import { ProfilesApiService } from '@/services/profiles.api.service';

const tptAuthUsername = 'tpt_client';
const tptAuthPassword = 'gZ+J97Tz';

@Component({
  templateUrl: './login.component.html',
  styleUrls: [ './login.component.scss' ],
})
export class LoginComponent implements OnInit, OnDestroy {

  public form: LoginForm;

  public loading = false;

  public svgIconsEnum = SvgIconsEnum;

  public googleSignUpStepTwo = false;

  public roleControl = new UntypedFormControl('', Validators.required);

  public showPass = false;

  @ViewChild(AuthErrorDialogComponent, {static: false}) authErrorDialog: AuthErrorDialogComponent;

  private readonly destroy$ = new Subject<void>();

  constructor(
    private auth: AuthService,
    private profileService: ProfileService,
    private alertService: AlertService,
    private route: ActivatedRoute,
    private router: Router,
    private routeHelper: RouteHelper,
    private profileApiService: ProfilesApiService,
    private spinnerService: SpinnerService,
    public translate: TranslateService,
  ) { }

  ngOnInit(): void {
    this.form = LoginForm.createForm();
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public login(): void {
    if (!this.form.validate()) {
      return;
    }

    this.loading = true;
    this.spinnerService.startSpinner();
    const data = this.form.getFormData();
    const { username, password } = data;
    const { inviteCode } = this.route.snapshot.queryParams;

    this.auth.loginV2(username?.trim(), password?.trim(),
      {authUsername: tptAuthUsername, authPassword: tptAuthPassword})
      .subscribe({
        next: async (response) => {
          this.auth.updateToken(response);
          localStorage.setItem('login_flow', 'talent_cloud');

          await this.profileService.refreshCurrentProfile();
          const userInvited = localStorage.getItem('invitedUser') === this.profileService.currentProfile.email;
          const url = this.profileService.currentProfile.isEmployer() ? this.routeHelper.employerProjectsDashboard :
            (inviteCode || userInvited) ? this.routeHelper.clientsPage :
              this.profileService.currentProfile.selfEmployedStatus === 'ACTIVE' ? this.routeHelper.projectSearch :
                this.routeHelper.fnsAttachPage;
          this.router.navigate([url], inviteCode ? { queryParams: this.route.snapshot.queryParams } : {});
          this.spinnerService.stopSpinner();
          this.loading = false;
        },
        error: (error: { error: string, error_description: string }) => {
          const errors = ['PASSWORD_ATTEMPTS_LIMIT_EXCEEDED', 'BAD_CREDENTIALS'];

          let errorText;
          switch (true) {
            case ['BAD_CREDENTIALS'].includes(error?.error_description):
              errorText = 'SERVER_ERRORS.AUTHENTICATION_FAILED';
              break;
            case ['User is disabled', 'Пользователь отключен'].includes(error?.error_description) :
              errorText = 'SERVER_ERRORS.ACCOUNT_DISABLED';
              break;
            case ['User is locked', 'Пользователь заблокирован', 'PASSWORD_ATTEMPTS_LIMIT_EXCEEDED'].includes(error?.error_description) :
              errorText = 'SERVER_ERRORS.ACCOUNT_LOCKED';
              break;
            default:
              errorText = 'SERVER_ERRORS.LOGIN_ERROR';
          }

          if (error && errors.includes(error.error_description)) {
            this.authErrorDialog.open('AUTH_ERROR', errorText, data.username);
          } else {
            this.authErrorDialog.open('AUTH_ERROR', 'SERVER_ERRORS.LOGIN_ERROR', data.username);
          }
          this.loading = false;
          this.spinnerService.stopSpinner();
        }
      });
  }
}
