import { Component, ElementRef, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { DialogComponent } from '@/modules/dialog/components/dialog/dialog.component';
import { MatDialogConfig } from '@angular/material/dialog';
import { SvgIconsEnum } from '@/types/svg-icons.enum';
import { SimpleProjectResponseModel } from '@/modules/employer/models/simple-project-response.model';
import { ValidationErrorService } from '@/modules/tpt-forms/services/validation-error.service';
import { ProfilesApiService } from '@/services/profiles.api.service';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatCheckboxChange } from '@angular/material/checkbox';
import * as moment from 'moment';
import { lastValueFrom, Subscription } from 'rxjs';
import { SimplePaymentForm } from '@/modules/employer/forms/simple-payment.form';
import { EmployeeModel } from '@/models/payroll/employee.model';
import { PayrollService } from '@/services/payroll.service';
import { Moment } from 'moment';
import { Router } from '@angular/router';
import { RouteHelper } from '@/helpers/route.helper';
import { Constants } from '@/classes/constants';
import { ProfileModel } from '@/models/profile.model';
import { SpinnerService } from '@/modules/tpt-ui/services/spinner.service';
import { WarningDialogComponent } from '@/modules/common-dialogs/components/warning-dialog/warning-dialog.component';

@Component({
  selector: 'app-new-payment-dialog',
  templateUrl: './new-payment-dialog.component.html',
  styleUrls: ['./new-payment-dialog.component.scss']
})
export class NewPaymentDialogComponent {

  get commission() {
    const commission = (+this.form.budget.value || 0) * (this.currentJobCommission / 100);
    return commission < this.employerCommissionInfo?.fiatMin ? this.employerCommissionInfo?.fiatMin : commission;
  }

  get totalBudget() {
    return (+this.form.budget.value || 0) + this.commission;
  }

  @ViewChild(WarningDialogComponent) warningDialogComponent: WarningDialogComponent;
  @ViewChild(DialogComponent, { static: false })
  public dialog: DialogComponent;
  @ViewChild('startDate') startDate: ElementRef;
  @ViewChild('endDate') endDate: ElementRef;

  @Output() projectEdited: EventEmitter<any> = new EventEmitter();

  public step = 1;

  public svgIconsEnum = SvgIconsEnum;

  public contractType = null;

  public employee: EmployeeModel;

  public user: ProfileModel;

  public form: SimplePaymentForm;

  public tasksForm: UntypedFormGroup;

  public selectedTaskIndex: number;

  public employerCommissionInfo = {
    valuePercent: 10,
    fiatMin: 36,
  };

  public currentJobCommission: number;

  public editTaskState = false;

  public loading = false;

  public editState = false;

  public project: SimpleProjectResponseModel;

  public maxValue = Constants.PLATFORM_PROJECT_BUDGET_LIMIT;

  private hasStartDate$: Subscription;
  private startDate$: Subscription;
  private deadline$: Subscription;

  private config: MatDialogConfig = {
    maxWidth: 'calc(100vw - 32px)',
    maxHeight: 'calc(var(--app-height) - 32px)',
  };

  private className = 'app-add-executor-dialog';

  constructor(private validationErrorService: ValidationErrorService,
              private payrollService: PayrollService,
              private router: Router,
              private routeHelper: RouteHelper,
              private spinner: SpinnerService,
              private profileApiService: ProfilesApiService) { }

 public async open(data: {item: EmployeeModel, user: ProfileModel, isEdit: boolean, showStepOne: boolean, project?: SimpleProjectResponseModel}): Promise<void> {
   this.employerCommissionInfo = await lastValueFrom(this.profileApiService.commissionInfo());
   this.currentJobCommission = this.employerCommissionInfo.valuePercent;
   this.initTaskForm();

   this.step = data.showStepOne ? 1 : 2;
   this.editState = data?.isEdit;
   this.employee = data.item;
   this.user = data.user;
   this.form = SimplePaymentForm.createForm(this.editState ? data.project : new SimpleProjectResponseModel());
   this.subscribe();
   this.getLimit();

   this.contractType = null;
   this.dialog.config = this.config;
   this.dialog.open(this.className);
  }

  public getLimit(): void {
    lastValueFrom(this.profileApiService.getSelfEmployedMaxPayment(this.user.id)).then((res => {
      const amount = res.amount;
      if (amount >= 1000 && amount < Constants.PLATFORM_PROJECT_BUDGET_LIMIT) {
        this.form.budget.clearValidators();
        this.form.budget.setValidators([
          Validators.required,
          Validators.min(1000),
          Validators.max(amount)]);
        this.maxValue = amount;
      }
    })).catch(e => {});
  }

  public close(): void {
    this.dialog.close();
    this.deadline$.unsubscribe();
    this.hasStartDate$.unsubscribe();
    this.startDate$.unsubscribe();
  }

  public next(): void {
    this.step = 2;
  }

  public openTaskSection(event: {task?, edit?: boolean, index?: number}): void {
    if (event.edit) {
      this.tasksForm = SimplePaymentForm.createTask(event.task);
      this.selectedTaskIndex = event.index;
      this.editTaskState = true;
    }
    this.step = 3;
  }

  public removeTask(taskIndex): void {
    this.form.tasks.removeAt(taskIndex);
  }

  public getDeadlineMaxDate(): Moment {
    const tasks = this.form.tasks.value || [];
    if (!tasks.length) {
      return null;
    }

    const tasksDates = tasks.filter(task => task.deadline).map(task => moment(task.deadline));
    return moment.max(tasksDates);
  }

  public getStartMinDate(): Moment {
    const tasks = this.form.tasks.value || [];
    if (!tasks.length) {
      return null;
    }

    const tasksDates = tasks.filter(task => task.startDate).map(task => moment(task.startDate));
    const tasksDeadlineDates = tasks.filter(task => task.deadline).map(task => moment(task.deadline));
    return moment.min([...tasksDates, ...tasksDeadlineDates]);
  }

  public saveTask(): void {
    if (this.editTaskState) {
      this.editTask();
    } else {
      this.addTask();
    }
  }

  public editTask(): void {
    this.tasksForm.markAllAsTouched();
    let startDate = this.tasksForm.controls.startDate?.value;
    let deadline = this.tasksForm.controls.deadline?.value;

    startDate = typeof startDate === 'string' ? moment(startDate) : startDate;
    deadline = typeof deadline === 'string' ? moment(deadline) : deadline;

    if (this.tasksForm.invalid || startDate > deadline) {
      return;
    }

    const task = this.tasksForm.value;
    Object.keys(task).forEach((key: string) => {
      this.form.tasks?.controls[this.selectedTaskIndex]?.get(key)?.setValue(task[key]);
    });
    this.updateProjectDeadline();
    this.step = 2;
    this.editTaskState = false;
    this.tasksForm.reset();
  }

  public addTask(): void {
    this.tasksForm.markAllAsTouched();
    let startDate = this.tasksForm.controls.startDate?.value;
    let deadline = this.tasksForm.controls.deadline?.value;

    startDate = typeof startDate === 'string' ? moment(startDate) : startDate;
    deadline = typeof deadline === 'string' ? moment(deadline) : deadline;

    if (this.tasksForm.invalid || startDate > deadline) {
      return;
    }

    const task = this.tasksForm.value;
    this.form.tasks.push(SimplePaymentForm.createTask(task));
    this.updateProjectDeadline();
    this.step = 2;
    this.tasksForm.reset();
  }

  public cancelAddTask(): void {
    this.tasksForm.reset();
    this.editTaskState = false;
    this.step = 2;
  }

  public error(ctrl): string {
    return this.validationErrorService.getValidationErrorMessage(ctrl);
  }

  public errorState(ctrl): boolean {
    return ctrl.invalid && (ctrl.dirty || ctrl.touched);
  }

  public getTipText(): string {
    const projectStartDate = this.form.startDate.value;
    const projectDeadline = this.form.deadline.value;
    const taskStartDate = this.tasksForm.get('startDate').value;
    const taskDeadline = this.tasksForm.get('deadline').value;

    if (projectStartDate && taskStartDate && projectDeadline && taskDeadline &&
      (taskStartDate < projectStartDate) && (taskDeadline > projectDeadline)) {
      return 'Даты выполнения проекта будут изменены на даты выполнения задачи';
    }

    if (projectStartDate && taskStartDate && (taskStartDate < projectStartDate)) {
      return 'Дата начала проекта будет изменена на дату начала выполнения задачи';
    }

    if (projectDeadline && taskDeadline && (taskDeadline > projectDeadline)) {
      return 'Дата окончания проекта будет изменена на дату окончания выполнения задачи';
    }

    if (projectStartDate && projectDeadline && taskDeadline && (taskDeadline < projectStartDate)) {
      return 'Дата начала проекта будет изменена на дату окончания выполнения задачи ';
    }

    return '';
  }

  public datepickerChange(event: MatCheckboxChange): void {
    if (event.checked === false) {
      this.tasksForm.controls.startDate.reset();
    }
  }

  public updateProjectDeadline(): void {
    const projectStartDate = this.form.startDate.value;
    const projectDeadline = this.form.deadline.value;
    const taskStartDate = this.tasksForm.get('startDate').value;
    const taskDeadline = this.tasksForm.get('deadline').value;

    if (projectStartDate && taskStartDate && projectDeadline && taskDeadline &&
      (taskStartDate < projectStartDate) && (taskDeadline > projectDeadline)) {
      this.form.startDate.setValue(taskStartDate);
      this.form.deadline.setValue(taskDeadline);
      return;
    }

    if (projectStartDate && taskStartDate && (taskStartDate < projectStartDate)) {
      this.form.startDate.setValue(taskStartDate);
      return;
    }

    if (projectDeadline && taskDeadline && (taskDeadline > projectDeadline)) {
      this.form.deadline.setValue(taskDeadline);
      return;
    }

    if (projectStartDate && projectDeadline && taskDeadline && (taskDeadline < projectStartDate)) {
      this.form.startDate.setValue(taskDeadline);
      return;
    }
  }

  public createPayment(): void {
    this.form.validate();
    const employeeId = this.employee?.id;

    if (this.form.invalid || !employeeId) {
      return;
    }

    const body = this.form.getFormData();

    body.startDate = body.startDate ? moment(body.startDate).format('YYYY-MM-DD') : null;
    body.deadline = body.deadline ? moment(body.deadline).format('YYYY-MM-DD') : null;

    body.tasks.forEach((task) => {
      task.startDate = task.startDate ? moment(task.startDate).format('YYYY-MM-DD') : null;
      task.deadline = task.deadline ? moment(task.deadline).format('YYYY-MM-DD') : null;
    });

    this.loading = true;
    this.payrollService.createPayrollPayment({...body, employeeId}).then((res) => {
      this.loading = false;
      this.close();
      this.router.navigate(this.routeHelper.contractsChatsUrl(res.id));
    })
      .catch((error) => {
        if (error.errorCode.message === 'SERVER_ERRORS.PAYMENT_AMOUNT_MORE_THAN_LIMIT') {
          this.warningDialogComponent.open('Вы не можете создать выплату для этого исполнителя',
            'Сумма выплаты превысит лимит дохода самозанятого в этом календарном году');
        }
      })
      .finally(() => {
        this.loading = false;
      });

  }

  public setContractType(type: 'FRAMEWORK' | 'REGULAR'): void {
    this.contractType = type;
    const frameworkId = this.employee?.contract?.id || null;
    this.form.frameworkContractId.setValue(type === 'FRAMEWORK' ? frameworkId : null);
  }

  public saveChanges(): void {
    this.form.validate();
    if (this.form.invalid) {
      return;
    }
    const body = this.form.getFormData();
    body.startDate = body.startDate ? moment(body.startDate).format('YYYY-MM-DD') : null;
    body.deadline = body.deadline ? moment(body.deadline).format('YYYY-MM-DD') : null;

    this.spinner.startSpinner();
    this.payrollService.editPayrollPayment(body).then(() => {
      this.projectEdited.emit();
      this.spinner.stopSpinner();
      this.close();
    }).catch(() => {
      this.spinner.stopSpinner();
    });
  }

  private subscribe(): void {
    this.hasStartDate$ = this.form.hasStartDate.valueChanges
      .subscribe((val) => {
      this.form.startDate.clearValidators();
      this.form.startDate.setValidators(val === true ? [Validators.required] : []);
      this.form.startDate.updateValueAndValidity({ onlySelf: true });

      if (val === false) {
        this.form.startDate.reset();
      }
    });

    this.startDate$ = this.form.startDate.valueChanges.subscribe((val) => {
      if (val && this.getStartMinDate() && val > this.getStartMinDate()) {
        this.form.startDate.setValue(this.getStartMinDate());
      }
    });

    this.deadline$ = this.form.deadline.valueChanges.subscribe((val) => {
      if (val && this.getDeadlineMaxDate() && val < this.getDeadlineMaxDate()) {
        this.form.deadline.setValue(this.getDeadlineMaxDate());
      }
    });
  }

  private initTaskForm(): void {
    this.tasksForm = new UntypedFormGroup({
      id: new UntypedFormControl(null),
      taskTitle: new UntypedFormControl('', [Validators.required, Validators.minLength(5), Validators.maxLength(64)]),
      description: new UntypedFormControl('', [Validators.maxLength(782)]),
      deadline: new UntypedFormControl('', [Validators.required]),
      startDate: new UntypedFormControl(''),
      hasStartDate: new UntypedFormControl(false),
    });
  }

}
