import { Component, Input} from '@angular/core';
import { CoSvgIconsEnum, SvgIconsEnum } from '@/types/svg-icons.enum';
import { ProfileService } from '@/services/profile.service';
import { Role } from '@/classes/enums';
import { AuthService } from '@/services/auth.service';
import { Router } from '@angular/router';
import { RouteHelper } from '@/helpers/route.helper';
import { CoRouteHelper } from '@/modules/creative-objects/helpers/co-router-helper';
import { ChatApiService } from '@/services/chat.api.service';

@Component({
  selector: 'app-header-menu',
  templateUrl: './header-menu.component.html',
  styleUrls: ['./header-menu.component.scss']
})
export class HeaderMenuComponent {

  svgIconsEnum = SvgIconsEnum;
  coSvgIconsEnum = CoSvgIconsEnum;
  profileMenuOpened = false;
  serviceMenuOpened = false;
  currentFlow = 'TC';
  @Input() color: 'dark' | 'light' = 'light';

  constructor(public profileService: ProfileService,
              private authService: AuthService,
              private router: Router,
              private routeHelper: RouteHelper,
              private coRouteHelper: CoRouteHelper,
              private chatService: ChatApiService,
  ) { }

  menuSwitch(state, menu) {
    switch (menu) {
      case 'PROFILE': this.profileMenuOpened = state;
                      break;
      case 'SERVICE': this.serviceMenuOpened = state;

    }
  }

  public goToCo(): void {
    const roles = this.profileService.currentProfile.roles;

    const client = 'co_client';

    this.authService.switchFlow(client)
      .then(res => this.authService.switchAuth(res.code, client))
      .then(async (response) => {
        this.authService.updateToken(response);
        await this.profileService.refreshCurrentProfile();
      });

    if (roles.length === 1 && !roles?.includes(Role.CREATIVE_OBJECTS_USER)) {
      this.router.navigate(this.coRouteHelper.acceptPage);
      return;
    }

    this.router.navigate(this.routeHelper.creativeObjects);
  }

  public logout(): void {
    this.profileService.logout();
  }

  public createChatWithSupport(): void {
    this.chatService.createSupportChat().subscribe((chat) => {
      this.router.navigate(this.routeHelper.contractsChatsUrl(chat.id));
    }, () => {});
  }

}
