<div class="fs-20 fw-700 text-black" *ngIf="title">
  {{title}}
</div>

<div *ngIf="message" class="alert fs-14 fw-500 text-gray-600"
     [ngClass]="{'alert-success': type === 'success', 'alert-danger': type === 'error' }">
  {{message | translate:params}}
</div>
<br>
<tc-button *ngIf="type != 'custom'" type="tertiary" size="large" class="full-width"
        (onClick)="dialogRef.close(false)">{{'BUTTON.CLOSE' | translate}}</tc-button>
<div *ngIf="type == 'custom'" class="alert__buttons">
  <button *ngFor="let button of buttons"
          mat-flat-button (click)="dialogRef.close(button.result && !!reason ? reason : button.result)"
          [color]="button.color" class="alert__button" ngClass="{{button.cssClass}}">{{button.title | translate}}
  </button>
</div>
