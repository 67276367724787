import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { HttpClient, HttpParams as NativeHttpParams } from '@angular/common/http';
import { BaseService } from './base.service';

@Injectable({
    providedIn: 'root',
  })
export class SearchService extends BaseService {

  static transformParams(params): string {
    const languageLevels = params.languageLevels;
    let languageLevelsString = '';

    delete params.languageLevels;
    const checkParamsList = ['levels', 'categories', 'skills'];
    checkParamsList.forEach(item => {
      if (params[item] && !params[item].length) {
        delete params[item];
      }
    });

    if (!params.countryCode || params.countryCode === 'Irrelevant') {
      delete params.countryCode;
    }

    if (params.rateMax === 50000) {
      delete params.rateMax;
    }

    if (params.rateMin === 100) {
      delete params.rateMin;
    }

    let newParams = new NativeHttpParams();
    Object.keys(params).forEach(param => {
      newParams = newParams.append(param, params[param]);
    });

    const queryString = newParams.toString();

    if (languageLevels && languageLevels.length) {
      for (let i = 0; i < languageLevels.length; i++) {
        languageLevels[i].index = i;
      }
      languageLevelsString = languageLevels.map(l => {
        return `&languageLevels%5B${l.index}%5D.code=${l.code}&languageLevels%5B${l.index}%5D.level=${l.level}`;
      }).join('');
    }

    return `${queryString}${languageLevelsString}`;
  }

  public projectsSubject = new BehaviorSubject(null);
  projects$ = this.projectsSubject.asObservable();

  public freelancersSubject = new BehaviorSubject(null);
  freelancers$ = this.freelancersSubject.asObservable();

  constructor(http: HttpClient) {
    super(http);
  }

  filtersProjectsNext(data: any) {
    this.projectsSubject.next(data);
  }

  filtersFreelancersNext(data: any) {
    this.freelancersSubject.next(data);
  }

  public sendProposal(id: string): Promise<any> {
    return this.executePost<any>(`v2/jobConversation/proposal`, {id});
  }

  public sendInvite(freelancerId: string, projectId: string): Promise<any> {
    return this.executePost<any>(`v2/jobConversation/invite`, { freelancerId, projectId });
  }

  public getListOfFreelancers(params: any): Observable<any> {
    const queryString = SearchService.transformParams(params);
    return this.executeGet(`v2/users?${queryString}`);
  }

  public setFrameworkContract(body: { chatId: string, frameworkContractId: string | null }): Observable<any> {
    return this.executePut('v2/jobConversation/setFrameworkContract', body);
  }

}
