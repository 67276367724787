<tpt-dialog>
  <div class="text-black title fw-700 ">{{'SEARCH.HIRE' | translate}}</div>

  <ng-container *ngIf="vacancies.length; else emptyProjects">
    <project-dropdown [control]="currentProjectForm"
                 [options]="vacancies"
                 [nameField]="'projectName'"
                 [valueField]="'projectId'"
                 [setFullObjectAsValue]="true"
                 (goToChat)="goToChat($event)"
                 [freelancer]="freelancer"
    >
      <ng-container label>Проект</ng-container>
    </project-dropdown>

    <ng-container *ngIf="currentProjectForm.value && detailsOpened && fullProjectInfo">
      <project-details-card [project]="fullProjectInfo"></project-details-card>
    </ng-container>

    <tc-button type="tertiary" size="medium" class="mt-24 full-width show-details"
               (click)="detailsOpened = !detailsOpened"
               *ngIf="!!fullProjectInfo && currentProjectForm.value">
      {{(!detailsOpened ? 'SEARCH.VIEW_DETAILS' : 'SEARCH.HIDE_CONTRACT_DETAILS') | translate}}
      <mat-icon [svgIcon]="svgIconsEnum.ExpandMore" [class.expand]="detailsOpened"></mat-icon>
    </tc-button>

    <div class="actions-row">
      <tc-button type="tertiary" size="x-large" (onClick)="close()">
        {{'BUTTON.CANCEL' | translate}}
      </tc-button>
      <tc-button class="full-width" size="x-large" type="primary" [disabled]="!currentProjectForm.value" (onClick)="hireFreelancer()">
        {{'BUTTON.HIRE' | translate}}
      </tc-button>
    </div>
  </ng-container>

  <ng-template #emptyProjects>
    <div class="fs-14 fw-500 text-gray-600 lh-20 mb-32">
      У вас нет проектов, на которые вы можете пригласить исполнителей
    </div>
    <div class="actions-row">
      <tc-button type="tertiary" size="x-large" (onClick)="close()">
        {{'BUTTON.CANCEL' | translate}}
      </tc-button>
      <tc-button size="x-large" type="primary" (onClick)="close()" [routerLink]="routeHelper.newProjectEditor">
        {{'CREATE_PROJECT.STEP1.CREATE_A_PROJECT' | translate}}
      </tc-button>
    </div>
  </ng-template>
</tpt-dialog>

<job-invite-error-dialog [isInvite]="true" (closeEvent)="close()"></job-invite-error-dialog>
<app-select-contract-dialog (selectContract)="selectContractType($event)"></app-select-contract-dialog>
<app-warning-dialog></app-warning-dialog>
