import { AfterViewInit, Component, OnDestroy, ViewContainerRef } from '@angular/core';
import { Router } from '@angular/router';

import { PortalService } from '@/services/portal.service';
import { ProfileService } from '@/services/profile.service';
import { RouteHelper } from '@/helpers/route.helper';

@Component({
  selector: 'search-page',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})
export class SearchComponent implements AfterViewInit, OnDestroy {

  tabs = [
    { label: 'Исполнители', path: this.routeHelper.freelancerSearch, show: true },
    { label: 'Проекты', path: this.routeHelper.projectSearch, show: true },
  ]

  constructor(
    private vcr: ViewContainerRef,
    private portalService: PortalService,
    private profile: ProfileService,
    private router: Router,
    private routeHelper: RouteHelper
  ) {
  }

  public ngAfterViewInit(): void {
    if (this.router.url === '/search') {
      this.router.navigate(this.isEmployer ? this.routeHelper.freelancerSearch : this.routeHelper.projectSearch);
    }
  }

  get isEmployer(): boolean {
    return this.profile.currentProfile.isEmployer();
  }

  ngOnDestroy(): void {
    this.portalService.detachHeaderPortal();
  }

}
