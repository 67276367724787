import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { Role } from './classes/enums';
import { ConfirmComponent } from './components/confirm/confirm.component';
import { ForgotPasswordComponent } from './components/forgot-password/forgot-password.component';
import { LoginComponent } from './components/login/login.component';
import { RegisterComponent } from './components/register/register.component';
import { RoleGuard } from './guards/role.guard';
import { RouteHelper } from './helpers/route.helper';
import { MainPageGuard } from './guards/main-page.guard';
import { ConfirmSuccessComponent } from './components/confirm-success/confirm-success.component';
import { RedirectGuard } from './guards/redirect.guard';
import { AcceptTcComponent } from '@/components/accept-tc/accept-tc.component';
import { AuthGuard } from '@/guards/auth.guard';
import { Constants } from '@/classes/constants';
import { ClientsPageComponent } from '@/modules/clients/clients-page/clients-page.component';

const routes: Routes = [
  { path: 'forgotPassword', component: ForgotPasswordComponent },
  {
    path: RouteHelper.SETTINGS_PATH,
    loadChildren: () => import('./modules/edit-profile/edit-profile.module').then((m) => m.EditProfileModule),
    canActivate: [RoleGuard],
    data: { expectedRoles: [Role.FREELANCER, Role.EMPLOYER], service: Constants.TC },
  },
  { path: 'login', component: LoginComponent, canActivate: [MainPageGuard] },
  { path: 'register', component: RegisterComponent, canActivate: [MainPageGuard] },
  { path: 'confirm', component: ConfirmComponent },
  { path: 'confirm-success', component: ConfirmSuccessComponent },
  { path: 'confirm/connect', component: ConfirmComponent },
  {
    path: 'creative-objects',
    loadChildren: () => import('./modules/creative-objects/creative-objects.module')
      .then((m) => m.CreativeObjectsModule)
  },
  // {
  //   path: RouteHelper.PAYYOURTEAM,
  //   loadChildren: () => import('./modules/pay-your-team/pay-your-team.module').then(m => m.PayYourTeamModule)
  // },
  {
    path: RouteHelper.PROJECTS_DASHBOARD_PATH,
    loadChildren: () => import('./modules/employer/jobs.module').then((m) => m.JobsModule),
    canActivate: [RoleGuard],
    data: { expectedRoles: [Role.EMPLOYER, Role.FREELANCER], service: Constants.TC },
  },
  {
    path: RouteHelper.CONTRACTS_PATH,
    loadChildren: () => import('./modules/contracts/contracts.module').then((m) => m.ContractsModule),
    canActivate: [RoleGuard],
    data: { expectedRoles: [Role.EMPLOYER, Role.FREELANCER], service: Constants.TC },
  },
  {
    path: 'finance',
    loadChildren: () => import('./modules/finance/finance.module').then((m) => m.FinanceModule),
    canActivate: [RoleGuard],
    data: { expectedRoles: [Role.FREELANCER, Role.EMPLOYER], service: Constants.TC },
  },
  {
    path: 'search',
    loadChildren: () =>
    import('./modules/search/search.module').then((m) => m.SearchModule),
    canActivate: [RoleGuard],
    data: { expectedRoles: [Role.EMPLOYER, Role.FREELANCER], service: Constants.TC },
  },
  {
    path: 'profiles',
    loadChildren: () =>
      import('./modules/user/user.module').then((m) => m.UserModule)
  },
  {
    path: 'projects',
    loadChildren: () =>
      import('./modules/projects/projects.module').then((m) => m.ProjectsModule)
  },
  {
    path: RouteHelper.EXECUTORS,
    loadChildren: () =>
      import('./modules/executors/executors.module').then((m) => m.ExecutorsModule),
    canActivate: [RoleGuard],
    data: { expectedRoles: [Role.EMPLOYER], service: Constants.TC },
  },
  {
    path: RouteHelper.PAYMENTS,
    loadChildren: () =>
      import('./modules/payments/payments.module').then((m) => m.PaymentsModule),
    canActivate: [RoleGuard],
    data: { expectedRoles: [Role.FREELANCER], service: Constants.TC },
  },
  {
    path: RouteHelper.CLIENTS,
    loadChildren: () =>
      import('./modules/clients/clients.module').then((m) => m.ClientsModule),
    canActivate: [RoleGuard],
    data: { expectedRoles: [Role.FREELANCER], service: Constants.TC },
  },
  {
    path: RouteHelper.MASS_PAYMENTS,
    loadChildren: () =>
      import('./modules/mass-payments/mass-payments.module').then((m) => m.MassPaymentsModule),
    canActivate: [RoleGuard],
    data: { expectedRoles: [Role.EMPLOYER, Role.FREELANCER], service: Constants.TC },
  },
  {
    path: RouteHelper.FRAMEWORK_PATH,
    loadChildren: () =>
      import('./modules/framework-contracts/framework-contracts.module').then((m) => m.FrameworkContractsModule),
    canActivate: [RoleGuard],
    data: { expectedRoles: [Role.EMPLOYER, Role.FREELANCER], service: Constants.TC },
  },
  { path: 'accept', component: AcceptTcComponent, canActivate: [AuthGuard] },
  { path: 'contact-us', loadChildren: () => import('./modules/book-demo/book-demo.module').then(m => m.BookDemoModule) },
  // { path: 'home-page', loadChildren: () => import('./components/home-page/home-page.module').then(m => m.HomePageModule), canActivate: [MainPageGuard] },
  { path: '', component: RedirectGuard, canActivate: [RedirectGuard], data: { externalUrl: '/landing' } },
  // { path: '',
  //   loadChildren: () => import('./components/home-page/home-page.module').then(m => m.HomePageModule),
  //   canActivate: [MainPageGuard]},
  { path: '**', redirectTo: '' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
