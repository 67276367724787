import { Component, ViewChild } from '@angular/core';
import { DialogComponent } from '../../dialog/components/dialog/dialog.component';
import { MatDialogConfig } from '@angular/material/dialog';
import { FileUpload } from '@/models/fileUpload';

@Component({
  selector: 'emp-attached-files-dialog',
  templateUrl: './attached-files-dialog.component.html',
  styleUrls: [ './attached-files-dialog.component.scss' ],
})
export class AttachedFilesDialogComponent {

  @ViewChild(DialogComponent)
  public dialog: DialogComponent;

  public files: Array<FileUpload> = [];

  private readonly className = 'emp-attached-files-dialog';

  private readonly config: MatDialogConfig = {
    width: 'calc(100vw - 32px)',
    maxWidth: '900px',
    minHeight: '480px',
    maxHeight: '700px',
    minWidth: '320px',
  };

  public open(files: Array<FileUpload>): void {
    this.files = files;
    this.dialog.config = this.config;
    this.dialog.open(this.className);
  }

  public close(): void {
    this.dialog.close();
  }

}
