import { Component, EventEmitter, Output, OnInit, ViewChild, Input } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';

import { SvgIconsEnum } from '@/types/svg-icons.enum';
import { RouteHelper } from '@/helpers/route.helper';
import { digitLengthValidator } from '@/validators/exact-length-digit-validator';
import { FnsApiService } from '@/services/fns.api.service';
import { SpinnerService } from '@/modules/tpt-ui/services/spinner.service';
import { BindStatusModel } from '@/models/fns/fns.model';
import { ProfileService } from '@/services/profile.service';
import { ProfilesApiService } from '@/services/profiles.api.service';
import { ChatApiService } from '@/services/chat.api.service';
import { innValidator } from '@/validators/inn-validator';
import {
  BindingErrorDialogComponent
} from '@/modules/common-dialogs/components/binding-error-dialog/binding-error-dialog.component';
import {
  AccessRecoveryDialogComponent
} from '@/modules/common-dialogs/components/access-recovery-dialog/access-recovery-dialog.component';
import { lastValueFrom } from 'rxjs';

export enum ConfirmStatus {
  Init = 'INIT',
  PendingConfirm = 'PENDING_CONFIRM',
  BindingRejectedOnFns = 'BINDING_REJECTED_FNS',
  BindingRejectedOnTpt = 'BINDING_REJECTED_TPT',
  BindingConfirmed = 'BINDING_CONFIRMED',
  AlreadyBound = 'ALREADY_BOUND'
}

@Component({
  selector: 'tpt-binding-stepper',
  templateUrl: './binding-stepper.component.html',
  styleUrls: ['./binding-stepper.component.scss']
})
export class BindingStepperComponent implements OnInit {

  @ViewChild(BindingErrorDialogComponent) bindingErrorDialogComponent: BindingErrorDialogComponent;

  @ViewChild(AccessRecoveryDialogComponent) accessRecoveryDialogComponent: AccessRecoveryDialogComponent;

  @Output() closeDialog = new EventEmitter<void>();
  @Output() updateUser = new EventEmitter<void>();
  @Input() bindStatus;

  public confirmStatusEnum = ConfirmStatus;

  public confirmStatus = null;

  public boundedUser = null;
  public bindingError: string;

  public step = 1;

  public minLeft;

  public timerOn: boolean;

  public svgIconEnums = SvgIconsEnum;

  public bindResult: string;

  public loaded = false;

  public inn: string;

  public codeFormControl = new FormControl(null, [Validators.required]);

  public bindForm = new FormGroup({
    type: new FormControl('inn', [Validators.required]),
    innValue: new FormControl('', [Validators.required, digitLengthValidator(12),
      innValidator('INN'), Validators.maxLength(12)]),
    phoneValue: new FormControl(''),
  });

  constructor(public routeHelper: RouteHelper,
              private fnsService: FnsApiService,
              private router: Router,
              private spinner: SpinnerService,
              private chatService: ChatApiService,
              private profile: ProfileService,
              private profileApiService: ProfilesApiService) { }

  async ngOnInit(): Promise<void> {
    const legal: any = await lastValueFrom(this.profileApiService.getUserLegal());
    this.inn = legal.inn;

    if (this.inn) {
      this.bindForm.controls.innValue.setValue(this.inn);
    }

    this.handleBindStatus(this.bindStatus);
    this.loaded = true;

    this.bindForm.controls.type.valueChanges.subscribe(type => {
      if (type === 'phone') {
        this.bindForm.controls.phoneValue.setValidators([Validators.required]);
        this.bindForm.controls.innValue.setValidators(null);
      }

      if (type === 'inn') {
        this.bindForm.controls.innValue.setValidators([Validators.required,
          digitLengthValidator(12),
          innValidator('INN'),
          Validators.maxLength(12)
        ]);
        this.bindForm.controls.phoneValue.setValidators(null);
      }
      this.bindForm.controls.phoneValue.updateValueAndValidity();
      this.bindForm.controls.innValue.updateValueAndValidity();
    });
  }

  public bindAccount(): void {
    const form: {type?: string, phoneValue?: string, innValue?: string} = this.bindForm.value;

    this.bindForm.markAllAsTouched();

    if (this.bindForm.invalid) {
      return;
    }

    this.spinner.startSpinner();

    if (form.type === 'inn') {
      this.fnsService.bindWithInn(form.innValue).subscribe({ next: this.handleSuccessBind, error: this.handleErrorBind });
    } else if (form.type === 'phone') {
      form.phoneValue = form.phoneValue.replace('+', '');
      this.fnsService.bindWithPhone(form.phoneValue).subscribe({ next: this.handleSuccessBind, error: this.handleErrorBind });
    }
  }

  handleSuccessBind = (): void => {
    this.spinner.stopSpinner();
    this.confirmStatus = this.confirmStatusEnum.PendingConfirm;
    this.step = 2;
  }

  handleErrorBind = (err): void => {
    this.spinner.stopSpinner();
    const message = err?.errorCode?.message;

    if (['SERVER_ERRORS.SELF_EMPLOYED_EXISTS_WITH_INN', 'SERVER_ERRORS.SELF_EMPLOYED_EXISTS_WITH_PHONE'].includes(message)) {
      this.boundedUser = err.details?.existsProfile;
      this.bindingError = message;

      this.confirmStatus = this.confirmStatusEnum.AlreadyBound;
      return;
    }

    this.bindingErrorDialogComponent.open(message);
  }

  public checkIfRightsAvailable(): void {
    this.spinner.startSpinner();
    this.checkBindStatus();
  }

  public rejectBinding() {
    this.confirmStatus = this.confirmStatusEnum.BindingRejectedOnTpt;
  }

  public initBindState() {
    this.confirmStatus = this.confirmStatusEnum.Init;
    this.step = 1;
  }

  public phoneError(ctrl: AbstractControl): boolean {
    return (ctrl.dirty || ctrl.touched) && ctrl?.invalid;
  }

  public createChatWithSupport(): void {
    this.chatService.createSupportChat().subscribe((chat) => {
      this.router.navigate(this.routeHelper.contractsChatsUrl(chat.id));
    }, () => {});
  }

  public restoreAccess = (): void => {
    const inn = this.bindForm.value.innValue;
    const phone = this.bindForm.value.phoneValue;
    const profileId = this.boundedUser?.profileId;

    const body: {profileId, inn?, phone?} = {
      profileId
    };

    if (this.bindingError === 'SERVER_ERRORS.SELF_EMPLOYED_EXISTS_WITH_INN') {
      body.inn = inn;
    } else if (this.bindingError === 'SERVER_ERRORS.SELF_EMPLOYED_EXISTS_WITH_PHONE') {
      body.phone = phone;
    }

    if ((phone || inn) && profileId) {
      this.profileApiService.forgotPasswordOtherAccount(body).subscribe(res => {
        this.accessRecoveryDialogComponent.open(res.email);
      });
    }
  }

  public handleBindStatus(bindStatus: BindStatusModel): void {
    if (!bindStatus) {
      this.confirmStatus = this.confirmStatusEnum.Init;
    }
    const result = bindStatus?.result;

    if (result === 'IN_PROGRESS') {
      this.confirmStatus = this.confirmStatusEnum.PendingConfirm;
      this.step = 2;
    }

    if (result === 'FAILED') {
      this.confirmStatus = this.confirmStatusEnum.BindingRejectedOnFns;
      this.step = 2;
    }

    if (result === 'COMPLETED') {
      this.confirmStatus = this.confirmStatusEnum.BindingConfirmed;
      this.step = 3;
      this.updateUser.emit();
    }

    if (typeof bindStatus === 'object' && bindStatus !== null && bindStatus.hasOwnProperty('unregistrationTime')) {
      this.confirmStatus = this.confirmStatusEnum.Init;
    }
  }

  private checkBindStatus(): void {
    this.fnsService.getBindStatus().subscribe({
      next: (res: BindStatusModel) => {
        this.handleBindStatus(res);
        this.loaded = true;
        this.spinner.stopSpinner();
      },
      error: () => {
        this.loaded = true;
        this.confirmStatus = this.confirmStatusEnum.Init;
        this.spinner.stopSpinner();
      }
    });
  }
}
