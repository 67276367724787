<div class="notification-content">
  <div class="notification-content__title headline">
    {{'NOTIFICATIONS' | translate}}

    <mat-icon [svgIcon]="svgIconsEnum.Cross" (click)="close.emit()" class="close-icon"></mat-icon>
    <div class="mark-all caption" (click)="markAllAsRead()"
         *ngIf="showMarkAll">
      <svg width="12" height="11" viewBox="0 0 12 11" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M8.89844 1.39844L5.14844 5.14844C4.91406 5.38281 4.5625 5.38281 4.35156 5.14844L2.47656 3.27344C2.24219 3.0625 2.24219 2.71094 2.47656 2.47656C2.6875 2.26562 3.03906 2.26562 3.27344 2.47656L4.75 3.97656L8.10156 0.625C8.3125 0.390625 8.66406 0.390625 8.89844 0.625C9.10938 0.835938 9.10938 1.1875 8.89844 1.39844ZM11.3359 4.21094L5.125 10.3984C4.91406 10.6328 4.5625 10.6328 4.35156 10.3984L1.16406 7.21094C0.929688 7 0.929688 6.64844 1.16406 6.41406C1.375 6.20312 1.72656 6.20312 1.96094 6.41406L4.75 9.22656L10.5391 3.4375C10.75 3.20312 11.1016 3.20312 11.3359 3.4375C11.5469 3.64844 11.5469 4 11.3359 4.21094Z" fill="#458D70"/>
      </svg>

      {{ 'NOTIFICATION_CONTENT_COMPONENT.MARK_ALL_AS_READ' | translate }}</div>
  </div>
  <div *ngIf="notifications">
    <div class="notification-content__container">

      <div class="notification-content__wrapper">
        <div *ngFor="let notification of notifications"
             class="notification-content__notification"
             [ngClass]="{'active': isActiveRow(notification) || !notification.viewed}"
             (click)="redirectTo(notification)"
        >
          <div class="notification-image">
            <ng-container *ngIf="showFreelancerAvatar.includes(notification?.code)">
              <ngx-avatars name="{{notification.data.freelancer.firstName}} {{notification.data.freelancer.lastName}}"
                          size="32"
              ></ngx-avatars>
            </ng-container>
            <ng-container *ngIf="showEmployerAvatar.includes(notification?.code)">
              <ngx-avatars name="{{notification.data.employer.firstName}} {{notification.data.employer.lastName}}"
                          size="32"
              ></ngx-avatars>
            </ng-container>
            <ng-container *ngIf="showErrorIcon.includes(notification?.code)">
              <mat-icon [svgIcon]="svgIconsEnum.InfoCircle" class="error"></mat-icon>
            </ng-container>
            <ng-container *ngIf="showSuccessIcon.includes(notification?.code)">
              <mat-icon [svgIcon]="svgIconsEnum.InfoCircle" class="success"></mat-icon>
            </ng-container>
            <ng-container *ngIf="infoIcon.includes(notification?.code)">
              <mat-icon [svgIcon]="svgIconsEnum.InfoCircle" class="info"></mat-icon>
            </ng-container>
            <ng-container *ngIf="warningIcon.includes(notification?.code)">
              <mat-icon [svgIcon]="svgIconsEnum.InfoCircle" class="warning"></mat-icon>
            </ng-container>
            <ng-container *ngIf="showFnsIcon.includes(notification?.code)">
              <img src="../../../../assets/images/fns-notification.png" class="fns-notification">
            </ng-container>
            <div class="vertical-line"></div>
          </div>
          <div class="notification-content__content">
            <div class="actions">
              <div class="delete" (click)="$event.stopPropagation(); deleteNotification(notification)">
                <mat-icon [svgIcon]="svgIconsEnum.Cross"></mat-icon>
              </div>
              <div class="view-point" *ngIf="!notification?.viewed"></div>
            </div>
            <div class="notification-content__sign">
              <div class="notification-content__sender">{{getSender(notification)}}</div>

              <div *ngIf="notification?.date" class="notification-content__date">
                {{notification?.date | timeAgo}}
              </div>
            </div>
            <div class="notification-content__notification-text">
              <span [innerHTML]="translate(notification) | safeHtml"></span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="!notifications || noNotifications"
       class="notification-content__empty">
    {{ 'NOTIFICATION_CONTENT_COMPONENT.EMPTY' | translate }}
  </div>
</div>
