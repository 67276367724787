import { lastValueFrom, Observable, throwError } from 'rxjs';
import { environment } from '../../environments/environment';

import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { AlertService } from './alert.service';
import { LogoutService } from './logout.service';
import { catchError, take } from 'rxjs/operators';

export abstract class BaseService {

  protected baseUrl = `${window.location.protocol}//${environment.MAIN_HOST}/api/`;

  protected constructor(protected http: HttpClient, protected alertService?: AlertService) { }

  public executeGet<T>(url: string, options?: {}): Observable<T> {
    return this.http.get<T>(this.baseUrl + url, options).pipe(
      catchError(this.errorHandlerObs)
    );
  }

  public executePost<T>(url: string, data: any, options?: {}): Promise<T> {
    return lastValueFrom(this.http.post<T>(this.baseUrl + url, data, options)
      .pipe(take(1))).catch(this.errorHandler);
  }

  public executePut<T>(url: string, data: any, options?: {}): Observable<T> {
    return this.http.put<T>(this.baseUrl + url, data, options).pipe(
      catchError(this.errorHandler)
    );
  }

  public executeDelete<T>(url: string, options?: {}): Promise<T> {
    return lastValueFrom(this.http.delete<T>(this.baseUrl + url, options)
      .pipe(take(1))).catch(this.errorHandler);
  }

  protected regenerateURLForAdmin() {
    this.baseUrl += 'admin/';
  }

  protected errorHandler(error: HttpErrorResponse): Promise<any> {
    if (Number(error.status) === 401) {
      // LogoutService.logout();
    }
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      return Promise.reject(error.error);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      // console.error(`Backend returned code ${error.status}, ` +
      //   `body was: ${error.error}`);
      return Promise.reject(error.error);
    }
  }

  protected errorHandlerObs(error: HttpErrorResponse): Observable<any> {
    // костыль чтоб не выкидывало при просмотре страницы проекта
    if (Number(error.status) === 401 && !error?.error?.details?.hasOwnProperty('visibility')) {
      // LogoutService.logout();
    }
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      // console.error(`Backend returned code ${error.status}, ` +
      //   `body was: ${error.error}`);
    }
    return throwError(error.error);
  }

}
