import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import {FileUpload} from '@/models/fileUpload';

@Injectable({
  providedIn: 'root'
})
export class ResourcesService extends BaseService {

  private baseTempUrl = `${window.location.protocol}//${environment.MAIN_HOST}`;

  constructor(
    http: HttpClient,
  ) {
    super(http);
  }

  public getFileByUUID(fileId: string): Observable<File> {
    return this.executeGet<BlobPart[]>('storage', {
      params: {fileId},
      responseType: 'arraybuffer',
      observe: 'response'
    }).pipe(
      map(this.mapBufferToBlob),
    );
  }

  public createObjectURL(file: File): string {
    return URL.createObjectURL(file);
  }

  public getUrl(fileId): string {
    return `${this.baseUrl}storage?fileId=${fileId}`;
  }

  public getTempUrl(link): string {
    return `${this.baseTempUrl}${link}`;
  }

  public getFile(fileId: string): void {
    if (!fileId) {
      return;
    }

    this.getFileByUUID(fileId).subscribe(this.handleFile);
  }

  private mapBufferToBlob = (response: any): File => {
    const bytes = new Uint8Array(response.body);
    const contentType = response.headers.get('content-type');
    const contentDisposition = response.headers.get('content-disposition').split('\"');
    const name = contentDisposition.length > 1 ? contentDisposition[1] : 'file';
    return new File([bytes], name, {
      type: contentType ? contentType : 'octet/stream',
      lastModified: response.headers.get('last-modified')
    });
  }

  private handleFile = (file: File): void => {
    const objectURL = this.createObjectURL(file);
    const link = document.createElement('a');
    link.download = decodeURIComponent(file.name);
    link.href = objectURL;
    link.click();
    URL.revokeObjectURL(objectURL);
  }
}
