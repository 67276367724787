import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { combineLatest } from 'rxjs';
import { ForgotPasswordStates } from '@/classes/enums';
import { AlertService } from '@/services/alert.service';
import { AuthHelperService } from '@/services/auth-helper.service';
import { LogService } from '@/services/log.service';
import { SvgIconsEnum } from '@/types/svg-icons.enum';
import { RestorePasswordEmailForm } from '@/forms/restore-password-email.form';
import { ChangePasswordForm } from '@/forms/change-password.form';
import { SpinnerService } from '@/modules/tpt-ui/services/spinner.service';
import { SnackbarNotificationsService } from '@/services/snackbar-notifications.service';
import { Constants } from '@/classes/constants';
import { ServiceEnum } from '@/models/services.enum';
import {
  EmailNotFoundDialogComponent
} from '@/modules/common-dialogs/components/email-not-found-dialog/email-not-found-dialog.component';

@Component({
  templateUrl: './forgot-password.component.html',
  styleUrls: [ './forgot-password.component.scss' ],
})
export class ForgotPasswordComponent implements OnInit {

  @ViewChild(EmailNotFoundDialogComponent) emailNotFoundDialog: EmailNotFoundDialogComponent;

  public get cardTitle(): string {
    if (this.state === ForgotPasswordStates.EMAIL_INPUT || this.state === ForgotPasswordStates.EMAIL_SENT) {
      return 'FORGOT_COMPONENT.RESET_PASSWORD';
    }

    if (
      (this.state === ForgotPasswordStates.PASSWORD_INPUT && this.hasToken) ||
      (this.state === ForgotPasswordStates.PASSWORD_CHANGED)
      ) {
      return 'LOGIN_COMPONENT.CHANGE_PASSWORD';
    }
  }

  public get isChangePasswordButtonDisabled(): boolean {
    return this.loading;
  }
  public model: any = {};
  public loading = false;
  public returnUrl: string;

  public token: string = null;
  public hasToken = false;
  public showPass = false;
  public showPassConfirm = false;

  public state: ForgotPasswordStates = ForgotPasswordStates.INIT;
  public ForgotPasswordStates = ForgotPasswordStates;

  public changePasswordForm: ChangePasswordForm;

  public svgIconsEnum = SvgIconsEnum;

  public restorePasswordEmailForm: RestorePasswordEmailForm;
  public showTooltip = false;
  public minPasswordLength = Constants.PASSWORD_MIN_LENGTH;

  constructor(
    private route: ActivatedRoute,
    private authHelperService: AuthHelperService,
    private alertService: AlertService,
    private logService: LogService,
    private spinnerService: SpinnerService,
    private snack: SnackbarNotificationsService,
  ) { }

  public ngOnInit(): void {
    this.restorePasswordEmailForm = RestorePasswordEmailForm.createForm();
    this.changePasswordForm = ChangePasswordForm.createForm();
    this.returnUrl = this.route.snapshot.queryParams.returnUrl || '/';

    this.state = ForgotPasswordStates.EMAIL_INPUT;

    combineLatest([
      this.route.queryParams,
      this.route.parent.params,
    ]).subscribe((result) => {
      if (result) {
        if (result[0].token && result[0].token.toString().length > 0) {
          this.token = result[0].token;
          this.hasToken = true;
          this.state = ForgotPasswordStates.PASSWORD_INPUT;
        }
      }
    });
  }

  public reset(): void {
    if (!this.restorePasswordEmailForm.validate()) {
      return;
    }

    this.spinnerService.startSpinner();
    const model = this.restorePasswordEmailForm.getFormData();
    this.authHelperService.forgotPassword(model.username, ServiceEnum.TALENT_CLOUD)
      .then((data) => {
        this.model.username = '';
        this.spinnerService.stopSpinner();
        this.state = ForgotPasswordStates.EMAIL_SENT;
      })
      .catch((error) => {
        this.spinnerService.stopSpinner();
        if (error.message === 'SERVER_ERRORS.NO_USER_WITH_EMAIL') {
          this.emailNotFoundDialog.open();
        } else {
          this.alertService.error(error);
        }
      });
  }

  public change(): void {
    if (!this.changePasswordForm.validate()) {
      return;
    }

    this.spinnerService.startSpinner();
    const data = this.changePasswordForm.getFormData();
    this.authHelperService.changePassword(this.token, data.changePasswordNew, data.changePasswordConfirm)
      .then((data) => {
        this.spinnerService.stopSpinner();
        this.state = ForgotPasswordStates.PASSWORD_CHANGED;
      })
      .catch((error) => {
        this.spinnerService.stopSpinner();
        this.logService.error(error);

        if (error?.errorCode?.message === 'SERVER_ERRORS.NEW_PASSWORD_EQUALS_OLD_ONE') {
          this.alertService.error('SETTINGS.NEW_PASSWORD_EQUALS_OLD_ONE');
        } else {
          this.snack.showSmthWentWrong();
        }
      });
  }

}
