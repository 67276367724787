<div class="page-root" *ngIf="project">
  <tc-job-info-card *ngIf="project"
                    [project]="project">
  </tc-job-info-card>
  <tc-job-tasks-list [project]="project" [showFiles]="true"></tc-job-tasks-list>

</div>
<div class="fixed-footer" *ngIf="project">
  <div class="commission" *ngIf="isEmployer">
    <div class="text-gray-600 fs-14 fw-500">Комиссия ({{project?.commission}}% от вознаграждения)</div>
    <div class="text-gray-600 fs-14 fw-600">{{project?.commissionValue | number}} ₽</div>
  </div>

  <div class="footer-row">
    <div class="budget-row">
      <div class="fs-12 fw-600 text-gray-600">Стоимость</div>
      <div class="fs-24 text-black fw-700 budget">{{project?.budget | number}} ₽</div>
    </div>

    <div class="actions">
      <button mat-icon-button class="link-button">
        <mat-icon [svgIcon]="svgIconsEnum.CopyLink"></mat-icon>
      </button>
      <tc-button type="alternative" size="medium" class="mobile-order"
                 [routerLink]="routeHelper.contractsChatsUrl(project.jobConversations?.[0]?.chatId)">
        <mat-icon [svgIcon]="svgIconsEnum.Chat"></mat-icon>
        Перейти в чат
      </tc-button>
      <tc-button type="secondary" size="medium"
                 [routerLink]="routeHelper.employerProjectTaskTracker(project.id)">
        <mat-icon [svgIcon]="svgIconsEnum.Tree"></mat-icon>
        Таск-трекер
      </tc-button>
    </div>
  </div>

</div>

