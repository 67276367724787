<div class="login-root">
  <div class="login-column">
    <div class="login">
        <h2 class="login__title">{{ 'LOGIN_COMPONENT.LOGIN' | translate }}</h2>
        <form>
          <tc-input  [control]="form.username" [icon]="svgIconsEnum.EnvelopeOutline">
            <ng-container label>Email</ng-container>
          </tc-input>

          <tc-input [control]="form.password" [type]="showPass ? 'text' : 'password'"
                    class="password-input"
          >
            <ng-container label>{{ 'LOGIN_COMPONENT.PASSWORD' | translate }}</ng-container>
            <ng-container icon>
              <mat-icon class="input-icon"
                        (click)="showPass = !showPass"
                        [svgIcon]="showPass ? svgIconsEnum.EyeOpen : svgIconsEnum.EyeClose"></mat-icon>
            </ng-container>
          </tc-input>
          <div class="login__login-hint">
            <span routerLink="/forgotPassword">{{ 'LOGIN_COMPONENT.FORGOT_PASSWORD' | translate }}</span>
          </div>

          <tc-button
            [disabled]="loading"
            type="primary"
            size="x-large"
            class="full-width mt-32"
            (onClick)="login()"
          >
            {{ 'LOGIN_COMPONENT.LOGIN' | translate }}
          </tc-button>
        </form>

      <tc-button
        type="tertiary"
        size="x-large"
        class="full-width mt-12"
        [routerLink]="['/register']"
      >
        {{'LOGIN_COMPONENT.NO_ACC' | translate}}
      </tc-button>
    </div>
  </div>
  <app-auth-pages-footer></app-auth-pages-footer>
</div>

<tpt-auth-error-dialog></tpt-auth-error-dialog>
