import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { FileUpload } from '../../../models/fileUpload';
import { SvgIconsEnum } from '../../../types/svg-icons.enum';
import { ResourcesService } from '../../../services/resources.service';

@Component({
  selector: 'emp-attached-file-item',
  template: `
    <div class="attached-file">
      <div class="d-flex align-items-center overflow-hidden w-100">
        <div class="mr-16 file-icon">
          <mat-icon [svgIcon]="svgIconsEnum.File"></mat-icon>
        </div>
        <div class="overflow-hidden">
          <div class="fw-600 lh-16 text-black mb-4 overflow-hidden overflow-ellipsis">{{ file.name }}</div>
          <div class="fs-14 lh-20 text-gray-600 fw-500">{{ getFileSize(file.size) }} - загружено {{file?.date | localDate:'dd.MM'}}</div>
        </div>
      </div>
      <tc-button type="primary" (onClick)="getFile(file.id)" class="download-btn">
        <mat-icon [svgIcon]="svgIconsEnum.Download"></mat-icon>
        Скачать
      </tc-button>
    </div>
  `,
  styleUrls: [ './attached-file-item.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AttachedFileItemComponent {

  @Input()
  public file: FileUpload & {date: string};

  public svgIconsEnum = SvgIconsEnum;

  constructor(
    public resourcesService: ResourcesService,
  ) { }

  public getFile(fileId: string): void {
    this.resourcesService.getFileByUUID(fileId).subscribe(this.handleFile);
  }

  public getFileSize(size: number): string {
    const sizes = ['КБ', 'МБ'];
    const index = Math.floor(Math.log(size) / Math.log(1024 * 1024));
    return Math.round(size / Math.pow(1024, index + 1)) + ' ' + sizes[index];
  }

  public handleFile = (file: File): void => {
    const objectURL = this.resourcesService.createObjectURL(file);
    const link = document.createElement('a');
    link.download = decodeURIComponent(file.name);
    link.href = objectURL;
    link.click();
    URL.revokeObjectURL(objectURL);
  }

}
