import { Component, EventEmitter, Output } from '@angular/core';
import { TptControlDirective } from '@/modules/tpt-forms/directives/tpt-control.directive';
import { SvgIconsEnum } from '@/types/svg-icons.enum';

@Component({
  selector: 'search-input',
  templateUrl: './search-input.component.html',
  styleUrls: ['./search-input.component.scss']
})
export class SearchInputComponent extends TptControlDirective {

  @Output()
  public keyup = new EventEmitter();

  @Output()
  public onBlur = new EventEmitter();

  @Output()
  public onFocus = new EventEmitter();

  public svgIconsEnum = SvgIconsEnum;



}
